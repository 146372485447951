<template>
  <div class="component-container">
    <div class="container">
      <div class="vip-view">
        <p class="text-dark pb-3">Liste de vos matchs</p>
        <div class="matches-list">
            <router-link
              v-for="(match, index) in matchs"
              :key="index"
              :to="{ name: 'messages', params: { id: match.id, name: match.name } }"
              class="match-item"
            >
              <img :src="match.avatar" alt="User Avatar" class="match-avatar" />
              <div class="match-content">
                <p class="text-dark">{{ match.name }}</p>
              </div>
            </router-link>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { accountService } from '@/_services';

export default {
  name: 'AllMatchesView',
  setup() {
    const matchs = ref([]);
    const baseURL = process.env.VUE_APP_API_BASE_URL.replace('/api', '');

    const fetchMatches = async () => {
      try {
        const auth_token = accountService.getToken();

        if (!auth_token) {
          console.error('Token non trouvé.');
          return;
        }

        const response = await accountService.getMatches();

        if (Array.isArray(response.data)) {
          matchs.value = response.data.map(match => {
            const profileImage = match.images.length ? `${baseURL}${match.images.find(image => image.is_profile)?.file || match.images[0].file}` : 'default-avatar-url';
            return {
              id: match.user.id,
              name: match.user.full_name || match.user.username,
              avatar: profileImage
            };
          });
        } else {
          console.error('La réponse ne contient pas un tableau de matchs attendu.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des matchs :', error);
      }
    };

    onMounted(() => {
      fetchMatches();
    });

    return {
      matchs
    };
  }
};
</script>

<style scoped>
/* Styles généraux (ordinateurs) */
.component-container {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Utiliser toute la hauteur de l'écran */
}

.container {
  width: 54%; /* Largeur pour les écrans d'ordinateur */
  height: 90vh;
  box-shadow: 0 30px 70px 0px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.vip-view {
  padding: 50px 30px;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Permet le défilement vertical */
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.vip-view::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for other browsers */
.vip-view {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.text-dark {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.matches-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 colonnes pour les écrans d'ordinateur */
  gap: 20px; /* Espace entre les éléments */
  padding-top: 10px;
  padding-bottom: 10px;
}

.match-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.match-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.match-content p {
  margin: 5px 0 0 0;
  font-size: 14px;
}

/* Styles spécifiques pour les smartphones */
@media (max-width: 768px) {
  .component-container {
    height: 100vh; /* Utiliser toute la hauteur de l'écran sur les smartphones */
  }

  .container {
    width: 100%; /* Utiliser toute la largeur de l'écran sur les smartphones */
    height: 100vh; /* Utiliser toute la hauteur de l'écran sur les smartphones */
    position: relative;
    left: 0;
    transform: none;
    padding: 60px 0px 0px 0px;
  }

  .vip-view {
    padding: 20px; /* Réduire le padding pour les smartphones */
  }

  .matches-list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Colonnes adaptatives pour les smartphones */
    gap: 10px; /* Réduire l'espace entre les éléments */
  }

  .match-avatar {
    width: 50px; /* Taille de l'avatar réduite pour les smartphones */
    height: 50px;
  }

  .text-dark {
    font-size: 1.2em; /* Taille du texte ajustée pour les smartphones */
  }
}
</style>

