import { createRouter, createWebHistory } from 'vue-router'

import * as Public from '@/views/public'
import * as Auth from '@/views/Auth'
import * as Dating from '@/views/Dating'

import NotFound from '@/views/NotFound'

import ChatLogin from '@/components/ChatLogin.vue'
import ChatSignUp from '@/components/ChatSignUp.vue'
import Profile from '@/components/Profile.vue'
import ChatSideBar from '@/components/ChatSideBar.vue'

const router = createRouter(
    {
        history: createWebHistory(),
        routes: [
            {
                path: '/',
                name: 'public',
                component: Public.PublicLayout,
                children: [
                    { path: '/', name: 'home', component: Public.HomeView },
                    { path: '/about', name: 'about', component: Public.AboutView },
                    { path: '/contact', name: 'contact', component: Public.ContactView },
                    /* Le user dashboard utilise le layout public */
                    { path: '/dashboard', name:'dashboard', component: Public.DashboardView },
                    { path: '/update/profil', name: 'update_profil', component: Public.UpdateProfilView },
                ]
            },

            {
                path: '/auth',
                name: 'auth',
                component: Auth.AuthLayout,
                children: [
                    { path: '/login', name: 'login', component: Auth.LoginView },
                    { path: '/register', name: 'register', component: Auth.RegisterView }
                ]
            },

            {
                path: '/dating',
                name: 'dating',
                component: Dating.DatingLayout,
                children: [
                    { path: '/proximity', name:'proximity', component: Dating.ProximityView },
                    { path: '/likes', name:'likes', component: Dating.LikesView },
                    { path: '/vip', name:'vip', component: Dating.VipView },
                    { path: '/chat', name:'chat', component: Dating.ChatView },
                    { path: 'messages/:id/:name', name: 'messages', component: Dating.MessagesView, props: true },
                    { path: '/swipe', name: 'swipe', component: Dating.SwipeView },
                    { path: '/allMatches', name: 'allMatches', component: Dating.AllMatchesView },
                ]
            },

            {
                path: '/:pathMatch(.*)*', component: NotFound,
            },
            
            {
                path: '/chat_login', name: 'chat_login', component: ChatLogin,
            },

            {
                path: '/chat_register', name: 'chat_register', component: ChatSignUp,
            },

            {
                path: '/chat_side_bar', name: 'chat_side_bar', component: ChatSideBar,
            },

            {
                path: '/profile', name: 'profile', component: Profile,
            }
        ]
    }
   
)

export default router;