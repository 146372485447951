<template>
    <!-- ================> About section start here <================== -->
	<div class="about about--style2 padding-top pt-xl-0">
		<div class="container">
			<div class="section__wrapper wow fadeInUp" data-wow-duration="1.5s">
				<div class="row g-0 justify-content-center row-cols-lg-2 row-cols-1">
					<div class="col">
						<div class="about__left">
							<div class="about__top">
								<div class="about__content">
									<h3>Bienvenue sur Hodalo</h3>
									<p>Vous nous trouvez enfin et vous êtes déjà amoureux. Plus de 4 000 000 de personnes dans le monde ont déjà partagé les mêmes expériences et utilisent notre système. Il est devenu plus facile de nous rejoindre aujourd'hui !</p>
								</div>
							</div>
							<div class="about__bottom">
								<div class="about__bottom--head">
									<h5>Derniers membres inscrits</h5>
									<div class="about__bottom--navi">
										<div class="ragi-prev"><i class="fa-solid fa-chevron-left"></i></div>
										<div class="ragi-next active"><i class="fa-solid fa-chevron-right"></i></div>
									</div>
								</div>
								<div class="about__bottom--body">
									<div class="ragi__slider overflow-hidden">
										<div class="swiper-wrapper">
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/01.jpg" alt="dating thumb"></a>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/02.jpg" alt="dating thumb"></a>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/03.jpg" alt="dating thumb"></a>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/04.jpg" alt="dating thumb"></a>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/05.jpg" alt="dating thumb"></a>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/01.jpg" alt="dating thumb"></a>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/02.jpg" alt="dating thumb"></a>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/03.jpg" alt="dating thumb"></a>
												</div>
											</div>
											<div class="swiper-slide">
												<div class="ragi__thumb">
													<a href="member-single.html"><img src="../../assets/images/ragi/04.jpg" alt="dating thumb"></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="about__right">
							<div class="about__title">
								<h3>Trouvez votre véritable amour</h3>
							</div>
							<form action="#">
								<div class="banner__list">
									<div class="row">
										<div class="col-6">
											<label>je suis un</label>
											<div class="banner__inputlist">
												<select>
													<option>Sélectionnez le sexe</option>
													<option value="male" selected>Homme</option>
													<option value="female">Femme</option>
													<option value="others">Autres</option>
												</select>
											</div>
										</div>
										<div class="col-6">
											<label>À la recherche de</label>
											<div class="banner__inputlist">
												<select>
													<option>Sélectionnez le sexe</option>
													<option value="male">Homme</option>
													<option value="female" selected>Femme</option>
													<option value="others">Autres</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div class="banner__list">
									<div class="row">
										<div class="col-lg-6 col-12">
											<label>Age</label>
											<div class="row g-3">
												<div class="col-6">
													<div class="banner__inputlist">
														<select>
															<option value="18" selected>18</option>
															<option value="19">19</option>
															<option value="20">20</option>
															<option value="21">21</option>
															<option value="22">22</option>
															<option value="23">23</option>
															<option value="24">24</option>
															<option value="25">25</option>
															<option value="26">26</option>
															<option value="27">27</option>
															<option value="28">28</option>
															<option value="29">29</option>
															<option value="30">30</option>
															<option value="31">31</option>
															<option value="32">32</option>
															<option value="33">33</option>
															<option value="34">34</option>
															<option value="35">35</option>
															<option value="36">36</option>
															<option value="37">37</option>
															<option value="38">38</option>
															<option value="39">39</option>
															<option value="40">40</option>
														</select>
													</div>
												</div>
												<div class="col-6">
													<div class="banner__inputlist">
														<select>
															<option value="18">18</option>
															<option value="19">19</option>
															<option value="20">20</option>
															<option value="21">21</option>
															<option value="22">22</option>
															<option value="23">23</option>
															<option value="24">24</option>
															<option value="25" selected>25</option>
															<option value="26">26</option>
															<option value="27">27</option>
															<option value="28">28</option>
															<option value="29">29</option>
															<option value="30">30</option>
															<option value="31">31</option>
															<option value="32">32</option>
															<option value="33">33</option>
															<option value="34">34</option>
															<option value="35">35</option>
															<option value="36">36</option>
															<option value="37">37</option>
															<option value="38">38</option>
															<option value="39">39</option>
															<option value="40">40</option>
														</select>
													</div>
												</div>
											</div>
										</div>
										<div class="col-lg-6 col-12">
											<label>Pays</label>
											<div class="banner__inputlist">
												<select id="country" name="country">
													<option value="Afganistan">Afghanistan</option>
													<option value="Albania">Albania</option>
													<option value="Algeria">Algeria</option>
													<option value="American Samoa">American Samoa</option>
													<option value="Andorra">Andorra</option>
													<option value="Angola">Angola</option>
													<option value="Anguilla">Anguilla</option>
													<option value="Antigua & Barbuda">Antigua & Barbuda</option>
													<option value="Argentina">Argentina</option>
													<option value="Armenia">Armenia</option>
													<option value="Aruba">Aruba</option>
													<option value="Australia">Australia</option>
													<option value="Austria">Austria</option>
													<option value="Azerbaijan">Azerbaijan</option>
													<option value="Bahamas">Bahamas</option>
													<option value="Bahrain">Bahrain</option>
													<option value="Bangladesh" selected>Bangladesh</option>
													<option value="Barbados">Barbados</option>
													<option value="Belarus">Belarus</option>
													<option value="Belgium">Belgium</option>
													<option value="Belize">Belize</option>
													<option value="Benin">Benin</option>
													<option value="Bermuda">Bermuda</option>
													<option value="Bhutan">Bhutan</option>
													<option value="Bolivia">Bolivia</option>
													<option value="Bonaire">Bonaire</option>
													<option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
													<option value="Botswana">Botswana</option>
													<option value="Brazil">Brazil</option>
													<option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
													<option value="Brunei">Brunei</option>
													<option value="Bulgaria">Bulgaria</option>
													<option value="Burkina Faso">Burkina Faso</option>
													<option value="Burundi">Burundi</option>
													<option value="Cambodia">Cambodia</option>
													<option value="Cameroon">Cameroon</option>
													<option value="Canada">Canada</option>
													<option value="Canary Islands">Canary Islands</option>
													<option value="Cape Verde">Cape Verde</option>
													<option value="Cayman Islands">Cayman Islands</option>
													<option value="Central African Republic">Central African Republic</option>
													<option value="Chad">Chad</option>
													<option value="Channel Islands">Channel Islands</option>
													<option value="Chile">Chile</option>
													<option value="China">China</option>
													<option value="Christmas Island">Christmas Island</option>
													<option value="Cocos Island">Cocos Island</option>
													<option value="Colombia">Colombia</option>
													<option value="Comoros">Comoros</option>
													<option value="Congo">Congo</option>
													<option value="Cook Islands">Cook Islands</option>
													<option value="Costa Rica">Costa Rica</option>
													<option value="Cote DIvoire">Cote DIvoire</option>
													<option value="Croatia">Croatia</option>
													<option value="Cuba">Cuba</option>
													<option value="Curaco">Curacao</option>
													<option value="Cyprus">Cyprus</option>
													<option value="Czech Republic">Czech Republic</option>
													<option value="Denmark">Denmark</option>
													<option value="Djibouti">Djibouti</option>
													<option value="Dominica">Dominica</option>
													<option value="Dominican Republic">Dominican Republic</option>
													<option value="East Timor">East Timor</option>
													<option value="Ecuador">Ecuador</option>
													<option value="Egypt">Egypt</option>
													<option value="El Salvador">El Salvador</option>
													<option value="Equatorial Guinea">Equatorial Guinea</option>
													<option value="Eritrea">Eritrea</option>
													<option value="Estonia">Estonia</option>
													<option value="Ethiopia">Ethiopia</option>
													<option value="Falkland Islands">Falkland Islands</option>
													<option value="Faroe Islands">Faroe Islands</option>
													<option value="Fiji">Fiji</option>
													<option value="Finland">Finland</option>
													<option value="France">France</option>
													<option value="French Guiana">French Guiana</option>
													<option value="French Polynesia">French Polynesia</option>
													<option value="French Southern Ter">French Southern Ter</option>
													<option value="Gabon">Gabon</option>
													<option value="Gambia">Gambia</option>
													<option value="Georgia">Georgia</option>
													<option value="Germany">Germany</option>
													<option value="Ghana">Ghana</option>
													<option value="Gibraltar">Gibraltar</option>
													<option value="Great Britain">Great Britain</option>
													<option value="Greece">Greece</option>
													<option value="Greenland">Greenland</option>
													<option value="Grenada">Grenada</option>
													<option value="Guadeloupe">Guadeloupe</option>
													<option value="Guam">Guam</option>
													<option value="Guatemala">Guatemala</option>
													<option value="Guinea">Guinea</option>
													<option value="Guyana">Guyana</option>
													<option value="Haiti">Haiti</option>
													<option value="Hawaii">Hawaii</option>
													<option value="Honduras">Honduras</option>
													<option value="Hong Kong">Hong Kong</option>
													<option value="Hungary">Hungary</option>
													<option value="Iceland">Iceland</option>
													<option value="Indonesia">Indonesia</option>
													<option value="India">India</option>
													<option value="Iran">Iran</option>
													<option value="Iraq">Iraq</option>
													<option value="Ireland">Ireland</option>
													<option value="Isle of Man">Isle of Man</option>
													<option value="Israel">Israel</option>
													<option value="Italy">Italy</option>
													<option value="Jamaica">Jamaica</option>
													<option value="Japan">Japan</option>
													<option value="Jordan">Jordan</option>
													<option value="Kazakhstan">Kazakhstan</option>
													<option value="Kenya">Kenya</option>
													<option value="Kiribati">Kiribati</option>
													<option value="Korea North">Korea North</option>
													<option value="Korea Sout">Korea South</option>
													<option value="Kuwait">Kuwait</option>
													<option value="Kyrgyzstan">Kyrgyzstan</option>
													<option value="Laos">Laos</option>
													<option value="Latvia">Latvia</option>
													<option value="Lebanon">Lebanon</option>
													<option value="Lesotho">Lesotho</option>
													<option value="Liberia">Liberia</option>
													<option value="Libya">Libya</option>
													<option value="Liechtenstein">Liechtenstein</option>
													<option value="Lithuania">Lithuania</option>
													<option value="Luxembourg">Luxembourg</option>
													<option value="Macau">Macau</option>
													<option value="Macedonia">Macedonia</option>
													<option value="Madagascar">Madagascar</option>
													<option value="Malaysia">Malaysia</option>
													<option value="Malawi">Malawi</option>
													<option value="Maldives">Maldives</option>
													<option value="Mali">Mali</option>
													<option value="Malta">Malta</option>
													<option value="Marshall Islands">Marshall Islands</option>
													<option value="Martinique">Martinique</option>
													<option value="Mauritania">Mauritania</option>
													<option value="Mauritius">Mauritius</option>
													<option value="Mayotte">Mayotte</option>
													<option value="Mexico">Mexico</option>
													<option value="Midway Islands">Midway Islands</option>
													<option value="Moldova">Moldova</option>
													<option value="Monaco">Monaco</option>
													<option value="Mongolia">Mongolia</option>
													<option value="Montserrat">Montserrat</option>
													<option value="Morocco">Morocco</option>
													<option value="Mozambique">Mozambique</option>
													<option value="Myanmar">Myanmar</option>
													<option value="Nambia">Nambia</option>
													<option value="Nauru">Nauru</option>
													<option value="Nepal">Nepal</option>
													<option value="Netherland Antilles">Netherland Antilles</option>
													<option value="Netherlands">Netherlands (Holland, Europe)</option>
													<option value="Nevis">Nevis</option>
													<option value="New Caledonia">New Caledonia</option>
													<option value="New Zealand">New Zealand</option>
													<option value="Nicaragua">Nicaragua</option>
													<option value="Niger">Niger</option>
													<option value="Nigeria">Nigeria</option>
													<option value="Niue">Niue</option>
													<option value="Norfolk Island">Norfolk Island</option>
													<option value="Norway">Norway</option>
													<option value="Oman">Oman</option>
													<option value="Pakistan">Pakistan</option>
													<option value="Palau Island">Palau Island</option>
													<option value="Palestine">Palestine</option>
													<option value="Panama">Panama</option>
													<option value="Papua New Guinea">Papua New Guinea</option>
													<option value="Paraguay">Paraguay</option>
													<option value="Peru">Peru</option>
													<option value="Phillipines">Philippines</option>
													<option value="Pitcairn Island">Pitcairn Island</option>
													<option value="Poland">Poland</option>
													<option value="Portugal">Portugal</option>
													<option value="Puerto Rico">Puerto Rico</option>
													<option value="Qatar">Qatar</option>
													<option value="Republic of Montenegro">Republic of Montenegro</option>
													<option value="Republic of Serbia">Republic of Serbia</option>
													<option value="Reunion">Reunion</option>
													<option value="Romania">Romania</option>
													<option value="Russia">Russia</option>
													<option value="Rwanda">Rwanda</option>
													<option value="St Barthelemy">St Barthelemy</option>
													<option value="St Eustatius">St Eustatius</option>
													<option value="St Helena">St Helena</option>
													<option value="St Kitts-Nevis">St Kitts-Nevis</option>
													<option value="St Lucia">St Lucia</option>
													<option value="St Maarten">St Maarten</option>
													<option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
													<option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
													<option value="Saipan">Saipan</option>
													<option value="Samoa">Samoa</option>
													<option value="Samoa American">Samoa American</option>
													<option value="San Marino">San Marino</option>
													<option value="Sao Tome & Principe">Sao Tome & Principe</option>
													<option value="Saudi Arabia">Saudi Arabia</option>
													<option value="Senegal">Senegal</option>
													<option value="Seychelles">Seychelles</option>
													<option value="Sierra Leone">Sierra Leone</option>
													<option value="Singapore">Singapore</option>
													<option value="Slovakia">Slovakia</option>
													<option value="Slovenia">Slovenia</option>
													<option value="Solomon Islands">Solomon Islands</option>
													<option value="Somalia">Somalia</option>
													<option value="South Africa">South Africa</option>
													<option value="Spain">Spain</option>
													<option value="Sri Lanka">Sri Lanka</option>
													<option value="Sudan">Sudan</option>
													<option value="Suriname">Suriname</option>
													<option value="Swaziland">Swaziland</option>
													<option value="Sweden">Sweden</option>
													<option value="Switzerland">Switzerland</option>
													<option value="Syria">Syria</option>
													<option value="Tahiti">Tahiti</option>
													<option value="Taiwan">Taiwan</option>
													<option value="Tajikistan">Tajikistan</option>
													<option value="Tanzania">Tanzania</option>
													<option value="Thailand">Thailand</option>
													<option value="Togo">Togo</option>
													<option value="Tokelau">Tokelau</option>
													<option value="Tonga">Tonga</option>
													<option value="Trinidad & Tobago">Trinidad & Tobago</option>
													<option value="Tunisia">Tunisia</option>
													<option value="Turkey">Turkey</option>
													<option value="Turkmenistan">Turkmenistan</option>
													<option value="Turks & Caicos Is">Turks & Caicos Is</option>
													<option value="Tuvalu">Tuvalu</option>
													<option value="Uganda">Uganda</option>
													<option value="United Kingdom">United Kingdom</option>
													<option value="Ukraine">Ukraine</option>
													<option value="United Arab Erimates">United Arab Emirates</option>
													<option value="United States of America">United States of America</option>
													<option value="Uraguay">Uruguay</option>
													<option value="Uzbekistan">Uzbekistan</option>
													<option value="Vanuatu">Vanuatu</option>
													<option value="Vatican City State">Vatican City State</option>
													<option value="Venezuela">Venezuela</option>
													<option value="Vietnam">Vietnam</option>
													<option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
													<option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
													<option value="Wake Island">Wake Island</option>
													<option value="Wallis & Futana Is">Wallis & Futana Is</option>
													<option value="Yemen">Yemen</option>
													<option value="Zaire">Zaire</option>
													<option value="Zambia">Zambia</option>
													<option value="Zimbabwe">Zimbabwe</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<button type="submit" class="default-btn reverse d-block"><span>Trouvez votre partenaire</span></button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="about padding-top padding-bottom">
		<div class="container">
			<div class="section__header style-2 text-center wow fadeInUp" data-wow-duration="1.5s">
				<h2>Tout commence par un rendez-vous</h2>
				<p>Apprenez d’eux et essayez de figurer sur ce forum. Cela augmentera à coup sûr votre visibilité et augmentera vos chances de retrouver l’être aimé.</p>
			</div>
			<div class="section__wrapper">
				<div class="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
					<div class="col wow fadeInUp" data-wow-duration="1.5s">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="../../assets/images/about/icon/01.png" alt="dating thumb">
								</div>
								<div class="about__content">
									<h3><span class="counter" data-to="990960" data-speed="1500"></span></h3>
									<p>Membres au total</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col wow fadeInUp" data-wow-duration="1.6s">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="../../assets/images/about/icon/02.png" alt="dating thumb">
								</div>
								<div class="about__content">
									<h3><span class="counter" data-to="628590" data-speed="1500"></span></h3>
									<p>Membres en ligne</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col wow fadeInUp" data-wow-duration="1.7s">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="../../assets/images/about/icon/03.png" alt="dating thumb">
								</div>
								<div class="about__content">
									<h3><span class="counter" data-to="314587" data-speed="1500"></span></h3>
									<p>Femmes en ligne</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col wow fadeInUp" data-wow-duration="1.8s">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="../../assets/images/about/icon/04.png" alt="dating thumb">
								</div>
								<div class="about__content">
									<h3><span class="counter" data-to="102369" data-speed="1500"></span></h3>
									<p>Hommes en ligne</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <!-- ================> About section end here <================== -->
</template>