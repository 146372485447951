<template>
  <div id="body">
    <div class="header">
      <div id="logo">
        <router-link :to="{ name: 'home' }" class="backto-home"><img src="../../assets/images/logo/logo.png" alt="logo"></router-link>
      </div>
      <div id="menu">
        <router-link :to="{ name: 'dashboard' }" class="backto-home text-dark"><i class="fas fa-chevron-left"></i> Dashboard</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header {
  width: 54%;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* Styles pour les écrans de smartphones */
@media (max-width: 767px) {
  .header {
    width: 100%;
    left: 0;
    transform: none;
  }

  #logo {
    font-size: 20px;
    font-weight: bold;
    position: relative;
    margin-left: 10px;
  }

  #menu {
    font-size: 18px;
    font-weight: bold;
    position: relative;
    margin-right: 10px;
  }
}

#logo img {
  height: 40px;
}
</style>
