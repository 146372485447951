<template>
    <!-- ================> Banner section start here <================== -->
	<!-- <div class="banner banner--style2 padding-top bg_img" style="background-image: url(@/assets/images/banner/bg-2.jpg);"> -->
	<div class="banner banner--style2 padding-top">
		<img src="@/assets/images/banner/bg-2.jpg" class="bg_img" alt="Background Image">
		<div class="container">
			<div class="banner__wrapper">
				<div class="row g-0 justify-content-center">
					<div class="col-lg-4 col-12">
						<div class="banner__content wow fadeInLeft" data-wow-duration="1.5s">
							<div class="banner__title">
								<h2>Nouveau lieu de rencontres inoubliables.</h2>
								<p>Rejoignez notre famille internationale dès aujourd'hui ! Veuillez nous appeler pour plus d'informations.</p>
								<a href="membership.html" class="default-btn style-2"><span>Obtenez un abonnement</span></a>
							</div>
						</div>
					</div>
					<div class="col-lg-8 col-12">
						<div class="banner__thumb wow fadeInUp" data-wow-duration="1.5s">
							<img src="../../assets/images/banner/02.png" alt="banner">
							<div class="banner__thumb--shape">
								<div class="shapeimg shapeimg__one">
									<img src="../../assets/images/banner/shape/home2/01.png" alt="dating thumb">
								</div>
								<div class="shapeimg shapeimg__two">
									<img src="../../assets/images/banner/shape/home2/02.png" alt="dating thumb">
								</div>
								<div class="shapeimg shapeimg__three">
									<img src="../../assets/images/banner/shape/home2/03.png" alt="dating thumb">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <!-- ================> Banner section end here <================== -->
</template>

<style>
	.banner {
    	position: relative; /* Assurez-vous que le conteneur a une position relative */
	}

	.banner .bg_img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1; /* Positionner l'image en arrière-plan */
		object-fit: cover; /* Assurez-vous que l'image couvre tout le conteneur */
		opacity: 0.5; /* Réglez l'opacité de l'image au besoin */
	}
</style>