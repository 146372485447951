<template>
    <!-- Transportation Section Start Here -->
	<section class="transportation padding-top padding-bottom">
		<div class="container">
			<div class="section__wrapper">
				<div class="row">
					<div class="col-lg-4 col-12">
						<div class="left">
							<div class="section__header style-2 mb-lg-0 wow fadeInUp" data-wow-duration="1.5s">
								<h2>Rencontrez des célibataires dans votre région</h2>
								<p>Si vous souhaitez rencontrer des célibataires locaux pour des rencontres, de la camaraderie, de l'amitié ou même plus, vous êtes au bon endroit.</p>
								<ul>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/01.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">United kingdom</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/02.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">Germany</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/03.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">United states</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/04.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">Brazil</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/05.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">Falkland islands</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/06.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">Portugal</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/07.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">Australia</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/08.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">India</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/09.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">South Africa</a></div>
									</li>
									<li>
										<div class="thumb"> <img src="../../assets/images/transport/10.jpg" alt="lab-trensport"></div>
										<div class="content"><a href="members.html">Bangladesh</a></div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-8 col-12">
						<div class="right wow fadeInUp" data-wow-duration="1.5s">
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">United kingdom</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">United states</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">Falkland islands</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">Australia</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">South Africa</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">Germany</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">Brazil</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">Portugal</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">India</a></div>
							</div>
							<div class="lab-line">
								<span></span>
								<div class="lab-tooltip"><a href="members.html">Bangladesh</a></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- Transportation Section Ending Here -->
</template>