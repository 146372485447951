<template>
    <!-- ================> About section start here <================== -->
	<!-- <div class="about padding-top padding-bottom bg_img" style="background-image: url(@/assets/images/bg-img/02.jpg);"> -->
	<div class="about padding-top padding-bottom">
		<img src="@/assets/images/bg-img/02.jpg" class="bg_img" alt="Background Image">
		<div class="container">
			<div class="section__header style-2 text-center wow fadeInUp" data-wow-duration="1.5s">
				<h2>Pourquoi choisir Hodalo</h2>
				<p>Notre plateforme de rencontres est comme une bouffée d’air frais. Un design épuré et tendance avec des fonctionnalités prêtes à l’emploi que vous allez adorer.</p>
			</div>
			<div class="section__wrapper">
				<div class="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
					<div class="col wow fadeInUp" data-wow-duration="1.5s">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="../../assets/images/about/01.jpg" alt="dating thumb">
								</div>
								<div class="about__content">
									<h4>Simple à utiliser</h4>
									<p>Étapes simples à suivre pour avoir une connexion correspondante.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col wow fadeInUp" data-wow-duration="1.6s">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="../../assets/images/about/02.jpg" alt="dating thumb">
								</div>
								<div class="about__content">
									<h4>Correspondance intelligente</h4>
									<p>Créez des connexions avec des utilisateurs qui vous ressemblent.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col wow fadeInUp" data-wow-duration="1.7s">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="../../assets/images/about/03.jpg" alt="dating thumb">
								</div>
								<div class="about__content">
									<h4>Filtrer très rapidement</h4>
									<p>Ne perdez pas votre temps ! Trouvez uniquement ce qui vous intéresse</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col wow fadeInUp" data-wow-duration="1.8s">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="../../assets/images/about/04.jpg" alt="dating thumb">
								</div>
								<div class="about__content">
									<h4>Communauté cool</h4>
									<p>Le réseau BuddyPress regorge de membres sympas.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <!-- ================> About section end here <================== -->
</template>

<style>
	.about {
    	position: relative; /* Assurez-vous que le conteneur a une position relative */
	}

	.about .bg_img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1; /* Positionner l'image en arrière-plan */
		object-fit: cover; /* Assurez-vous que l'image couvre tout le conteneur */
		opacity: 0.5; /* Réglez l'opacité de l'image au besoin */
	}
</style>