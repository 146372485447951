<script setup>
	import { accountService } from '@/_services';
	import { useStore } from 'vuex';
	import { useRouter } from 'vue-router'
	import { computed } from 'vue'

	const hasUserData = computed(() => {
		const appUser = store.getters.getAppUser;
		return appUser && appUser.id;
	});

	const store = useStore()
	const router = useRouter()

	function logout(){
		accountService.logout()
		store.commit('setLogged',false)
		store.commit('setAppUser',null)
		router.push({name: 'home'})
	}
</script>

<template>
    <header class="header header--style2" id="navbar">
        <div class="header__top d-none d-lg-block">
			<div class="container">
				<div class="header__top--area">
					<div class="header__top--left">
						<ul>
                            <li>
                                <i class="fa-solid fa-phone"></i> <span>+228 91 85 55 66</span>
                            </li>
                            <li>
                                <i class="fa-solid fa-location-dot"></i> Agoè Vakpossito, LOME-TOGO
                            </li>
                        </ul>
					</div>
					<div class="header__top--right">
						<ul>
                            <li><a href="#"><i class="fa-brands fa-facebook"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-youtube"></i></a></li>
                        </ul>
					</div>
				</div>
			</div>
		</div>
		<div class="header__bottom">
			<div class="container">
				<nav class="navbar navbar-expand-lg">
					<router-link :class="{active : $route.name === 'home'}" :to="{name:'home'}" ><img src="@/assets/images/logo/logo.png" alt="logo"></router-link>
					<button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
						aria-label="Toggle navigation">
						<span class="navbar-toggler--icon"></span>
					</button>
					<div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
						<div class="navbar-nav mainmenu">
							<!-- <ul>
								<li class="active">
									<a href="#0">Home</a>
									<ul>
										<li><a href="index.html">Home Page One</a></li>
										<li><a href="index-2.html">Home Page Two</a></li>
										<li><a href="index-3.html" class="active">Home Page Three</a></li>
									</ul>
								</li>
								<li>
									<a href="#0">Pages</a>
									<ul>
										<li><a href="about.html">About Us</a></li>
                                        <li><a href="membership.html">Membership</a></li>
                                        <li><a href="login.html">Log In</a></li>
                                        <li><a href="register.html">Sign Up</a></li>
                                        <li><a href="comingsoon.html">comingsoon</a></li>
                                        <li><a href="404.html">404</a></li>
									</ul>
								</li>
								<li>
									<a href="#0">Community</a>
									<ul>
										<li><a href="community.html">Community</a></li>
										<li><a href="group.html">All Group</a></li>
										<li><a href="members.html">All Members</a></li>
										<li><a href="activity.html">Activity</a></li>

									</ul>
								</li>
								<li>
									<a href="#0">Shops</a>
									<ul>
										<li><a href="shop.html">Product</a></li>
										<li><a href="shop-single.html">Product Details</a></li>
										<li><a href="shop-cart.html">Product Cart</a></li>
									</ul>
								</li>
								<li>
									<a href="#0">Blogs</a>
									<ul>
										<li><a href="blog.html">Blog</a></li>
										<li><a href="blog-2.html">Blog Style Two</a></li>
										<li><a href="blog-single.html">Blog Details</a></li>
									</ul>
								</li>
								<li><a href="contact.html">contact</a></li>
							</ul> -->
							<ul class="navigation-links">
								<li><router-link :class="{active : $route.name === 'home'}" :to="{name:'home'}" >Accueil</router-link></li>
								<li><router-link :class="{active : $route.name === 'about'}" :to="{name:'about'}">A propos</router-link></li>
								<li><router-link :class="{active : $route.name === 'contact'}" :to="{name:'contact'}">contact</router-link></li>
							</ul>
						</div>
						<div class="header__more" v-if="hasUserData">
							<button class="default-btn dropdown-toggle" type="button" id="moreoption" data-bs-toggle="dropdown" aria-expanded="false">Mon Compte</button>
                            <ul class="dropdown-menu" aria-labelledby="moreoption">
								<li>
									<router-link :to="{name:'dashboard'}" class="dropdown-item"><i class="fa-solid fa-gauge"></i> Dashboard</router-link>

									<div class="dropdown-divider"></div>

									<router-link :to="{name:'dashboard'}" class="dropdown-item"><i class="fa-solid fa-key"></i> Mot de passe</router-link>

									<div class="dropdown-divider"></div>

									<router-link :to="{name:'swipe'}" class="dropdown-item"><i class="fa-solid fa-heart text-danger"></i> Rencontres</router-link>

									<div class="dropdown-divider"></div>

									<a title="Deconnexion" style="cursor:pointer;" class="text-danger dropdown-item" @click="logout()">
										<i class="fa-solid fa-power-off"></i> Deconnexion
									</a>
								</li>
                            </ul>
						</div>
						<div class="header__more" v-else>
							<ul class="button-group">
								<li><router-link :to="{name:'login'}" class="default-btn login"><i class="fa-solid fa-user"></i> CONNEXION</router-link></li>
								<li><router-link :to="{name:'register'}" class="default-btn signup"><i class="fa-solid fa-users"></i> INSCRIPTION</router-link></li>
								<!-- <li><router-link :to="{name:'chat_login'}" class="default-btn login"><i class="fa-solid fa-user"></i> CONNEXION</router-link></li> -->
								<!-- <li><router-link :to="{name:'chat_register'}" class="default-btn signup"><i class="fa-solid fa-users"></i> INSCRIPTION</router-link></li> -->
							</ul>
						</div>
					</div>
				</nav>
			</div>
		</div>
    </header>
</template>

<style>
	.navigation-links .active{
		color: /*#f24570*/ #a34790 !important;
		/* text-decoration-line: line-through; */
	}
</style>