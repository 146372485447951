<template>
  <div class="component-container">
    <div class="container">
      <div class="chat-global">
        <header class="nav-top">
          <h3 class="py-3 px-3 text-dark">Discussions</h3>
          <div class="header-right">
            <router-link :to="{ name: 'allMatches'}" class="btn-primary">
              Tous les matchs
            </router-link>
            <img src="@/assets/ressources/notification-icon-50.svg" alt="Notifications" class="icon" />
          </div>
        </header>

        <div class="matches">
          <p class="text-dark">
            <span class="bold-text">{{ matchs.length }} Match(s)</span>
          </p>
          <div class="matches-list pt-3">
            <!-- Utilisation de limitedMatches pour afficher les 8 premiers matchs -->
            <router-link
              v-for="(match, index) in limitedMatches()"
              :key="index"
              :to="{ name: 'messages', params: { id: match.id, name: match.name } }"
              class="match-item"
            >
              <img :src="match.avatar" alt="User Avatar" class="match-avatar" />
              <div class="match-content">
                <p class="text-dark">{{ match.name }}</p>
              </div>
            </router-link>
          </div>

          <!-- Bouton Voir plus pour afficher tous les matchs -->
          <div v-if="matchs.length > 6" class="see-more">
            <!-- <router-link :to="{ name: 'allMatches'}">Voir plus</router-link> -->
          </div>
        </div>

        <div class="conversation-vert">
          <div class="discussion-list-vert">
            <div class="matches-list-vert">
              <router-link
                v-for="(match, index) in filteredMatches"
                :key="index"
                :to="{ name: 'messages', params: { id: match.id, name: match.name } }"
                class="match-item-vert"
              >
                <img :src="match.avatar" alt="User Avatar" class="match-avatar-vert" />
                <div class="match-info-vert">
                  <h6 class="match-name-vert">{{ match.name }}</h6>
                  <p class="last-message-vert">{{ truncateText(lastMessages[match.id]?.content || 'Chargement...') }}</p>
                </div>
                <div class="match-timestamp-vert">
                  <span v-if="lastMessages[match.id]?.timestamp">
                    {{ formatTimestamp(lastMessages[match.id]?.timestamp) }}
                  </span>
                </div>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed  } from 'vue';
import { useRouter } from 'vue-router';
import { accountService } from '@/_services';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import MessagesView from '@/views/Dating/MessagesView.vue';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

export default {
  name: 'ChatView',
  components: {
    MessagesView
  },
  setup() {
    const router = useRouter();
    const auth = getAuth();
    const baseURL = process.env.VUE_APP_API_BASE_URL.replace('/api', '');

    const matchs = ref([]);
    const discussions = ref([]);
    const currentUserName = ref('');
    const currentPeerUser = ref(null);
    const currentUserId = ref(localStorage.getItem('id'));
    const searchUsers = ref([]);
    const lastMessages = ref({});

    const fetchMatches = async () => {
      try {
        const auth_token = accountService.getToken();

        if (!auth_token) {
          console.error('Token non trouvé.');
          return;
        }

        const response = await accountService.getMatches();

        if (Array.isArray(response.data)) {
          matchs.value = response.data.map(match => {
            const profileImage = match.images.length ? `${baseURL}${match.images.find(image => image.is_profile)?.file || match.images[0].file}` : 'default-avatar-url';
            return {
              id: match.user.id,
              name: match.user.full_name || match.user.username,
              lastMessage: '', // Initialize with empty string
              avatar: profileImage
            };
          });

          await getUserList();
          await fetchLastMessages(); // Fetch the last messages after getting the user list
        } else {
          console.error('La réponse ne contient pas un tableau de matchs attendu.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des matchs :', error);
      }
    };

    const fetchLastMessages = async () => {
      const firestore = getFirestore();

      for (const match of matchs.value) {
        const groupChatId = currentUserId.value < match.id
          ? `${currentUserId.value}-${match.id}`
          : `${match.id}-${currentUserId.value}`;

        const messageCollection = collection(firestore, 'Messages', groupChatId, groupChatId);
        const lastMessageQuery = query(messageCollection, orderBy('timestamp', 'desc'), limit(1));

        try {
          const querySnapshot = await getDocs(lastMessageQuery);
          if (!querySnapshot.empty) {
            const lastMessageDoc = querySnapshot.docs[0].data();
            lastMessages.value[match.id] = {
              content: lastMessageDoc.content,
              timestamp: lastMessageDoc.timestamp
            }; // Stocker le dernier message et son timestamp
          } else {
            lastMessages.value[match.id] = {
              // content: 'No messages yet. Say hello !',
              timestamp: null
            }; // Message par défaut s'il n'y a pas de messages
          }
        } catch (error) {
          console.error('Error fetching last message:', error);
        }
      }

      // Trier les matchs par timestamp des derniers messages
      matchs.value.sort((a, b) => {
        const timeA = lastMessages.value[a.id]?.timestamp || 0;
        const timeB = lastMessages.value[b.id]?.timestamp || 0;
        return timeB - timeA;
      });
    };

    const letsChat = (item) => {
      console.log('Navigating to messages with:', item); // Vérifie l'objet complet
      router.push({ 
        name: 'messages', 
        params: { 
          id: item.id, 
          name: item.username || 'unknown' // Vérifie que `name` est défini
        }
      });
    };

    const getUserList = async () => {
      const firestore = getFirestore();
      const querySnapshot = await getDocs(collection(firestore, 'users'));

      // Récupérer l'ID de l'utilisateur connecté à partir du localStorage
      const currentUserId = localStorage.getItem('id');

      // Récupérer les IDs des matchs
      const matchedUserIds = new Set(matchs.value.map(match => match.id));

      searchUsers.value = []; // Réinitialiser la liste des utilisateurs

      querySnapshot.forEach(doc => {
        const data = doc.data();
        const userId = doc.id;

        // Exclure l'utilisateur connecté et filtrer en fonction des matchs
        if (userId !== currentUserId && matchedUserIds.has(userId)) {
          searchUsers.value.push({
            id: userId, // Utiliser l'ID du document Firestore
            username: data.username,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            born_on: data.born_on,
            gender: data.gender,
            gender_preference: data.gender_preference,
            avatar: '' // Ajouter un champ avatar par défaut (si disponible)
          });
        }
      });
    };

    const truncateText = (text, maxLength = 70) => {
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + '...';
    };

    const formatTimestamp = (timestamp) => {
      return moment(parseInt(timestamp)).format('DD MMM YYYY HH:mm');
    };

    // Fonction pour limiter le nombre de matchs affichés à 8
    const limitedMatches = () => {
      return matchs.value.slice(0, 6);
    };

    // Filter to show only users with messages
    const filteredMatches = computed(() => {
      return matchs.value.filter(match => lastMessages.value[match.id]?.content);
    });

    onMounted(() => {
      if (!localStorage.hasOwnProperty('id')) router.push('/');
      fetchMatches();
    });

    return {
      currentUserName,
      currentPeerUser,
      currentUserId,
      searchUsers,
      letsChat,
      matchs,
      discussions,
      lastMessages,
      formatTimestamp,
      truncateText,
      limitedMatches,
      filteredMatches
    };
  }
};
</script>

<style scoped>
/* Ajoutez ici vos styles */
.component-container {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  width: 54%;
  height: 90vh;
  box-shadow: 0 30px 70px 0px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.chat-global {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.conversation {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px 0 20px 8px;
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.conversation::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for other browsers */
.conversation {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nav-top {
  padding-top: 20px;
  flex-shrink: 0;
  background: #fff;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.icon {
  width: 30px;
  margin: 0 20px 0 10px;
}

.btn-primary {
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
}

.discussion-list {
  display: flex;
  flex-direction: column;
}

.discussion-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s;
  text-decoration: none;
  color: inherit;
}

.discussion-item:hover {
  background-color: #f0f0f0;
}

.discussion-avatar {
  width: 68px;
  height: 70px;
  border-radius: 50%;
  margin-right: 10px;
}

.discussion-content {
  display: flex;
  flex-direction: column;
}

.matches {
  margin: 10px 0 0 0;
  padding: 0 0 10px 18px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.matches p {
  margin: 0 0 0 0;
}

.matches-list {
  display: flex;
}

.match-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.match-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.match-item p {
  margin: 5px 0 0 0;
  font-size: 14px;
}

/* Conteneur principal pour la conversation verticale */
.conversation-vert {
  padding: 10px;
  overflow-y: auto; /* Permet le scroll si le contenu dépasse la hauteur */
}

.discussion-list-vert {
  /* display: flex; */
  /* flex-direction: column; */
  max-height: 100%; /* Limite la hauteur du conteneur */
  overflow-y: auto; /* Active le scroll si nécessaire */
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.conversation-vert::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.discussion-list-vert::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for other browsers */
.conversation-vert {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide scrollbar for other browsers */
.discussion-list-vert {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.matches-list-vert {
  display: flex;
  flex-direction: column; /* Alignement vertical */
}

.match-item-vert {
  display: flex;
  flex-direction: row;
  align-items: center; /* Aligner verticalement l'avatar et le texte */
  margin-bottom: 10px; /* Espacement entre les éléments */
  text-decoration: none; /* Enlever le soulignement des liens */
  color: inherit; /* Conserver la couleur du texte du parent */
  transition: background-color 0.3s, transform 0.3s; /* Transition douce pour le changement de fond et l'effet de transformation */
  border-radius: 8px; /* Bord arrondi pour un effet de survol plus doux */
  padding: 5px; /* Padding pour donner un peu d'espace autour des éléments */
}

.match-item-vert:hover {
  background-color: #d6aabd ; /* Couleur de fond lors du survol */
  transform: scale(1.02); /* Légère augmentation de la taille pour un effet de zoom */
}

.match-avatar-vert {
  width: 60px; /* Taille de l'avatar */
  height: 60px;
  border-radius: 50%; /* Avatar circulaire */
  object-fit: cover; /* Assurer que l'image est bien recadrée */
  margin-right: 15px; /* Espacement entre l'avatar et le texte */
  transition: transform 0.3s; /* Transition douce pour le zoom de l'avatar */
}

.match-item-vert:hover .match-avatar-vert {
  transform: scale(1.1); /* Légère augmentation de la taille de l'avatar lors du survol */
}

.match-info-vert {
  display: flex;
  flex-direction: column; /* Aligner le nom et le dernier message verticalement */
  flex-grow: 1; /* Permet au conteneur de prendre tout l'espace disponible */
}

.match-name-vert {
  font-size: 16px; /* Taille du texte */
  margin: 0; /* Enlever les marges par défaut */
  color: #333; /* Couleur du texte */
}

.last-message-vert {
  font-size: 14px; /* Taille du texte du dernier message */
  color: #666; /* Couleur du texte du dernier message */
  margin: 5px 0 0 0; /* Espacement pour le dernier message */
  white-space: nowrap; /* Empêche le texte de passer à la ligne suivante */
  overflow: hidden; /* Cache le texte qui dépasse */
  text-overflow: ellipsis; /* Ajoute "..." pour le texte qui dépasse */
}

.match-timestamp-vert {
  font-size: 0.7em;
  color: #666;
  white-space: nowrap;
  margin-left: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 5px 10px;
  display: inline-block;  
}

.bold-text {
  font-weight: bold;
}

/* Styles spécifiques pour les écrans de smartphones */
@media (max-width: 768px) {
  .container {
    width: 100%;
    height: 100vh;
    border-radius: 0;
    left: 0;
    transform: none;
    padding: 60px 8px 4px 8px;
  }

  .nav-top {
    padding-top: 10px;
  }

  .discussion-avatar {
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }

  .match-avatar {
    width: 50px;
    height: 50px;
  }

  .match-item {
    margin-right: 10px;
  }

  .icon {
    width: 25px;
    margin: 0 10px 0 5px;
  }

  /* .btn-primary {
    padding: 80px 8px 4px 8px;
    font-size: 13px;
  } */

  .discussion-item {
    padding: 8px;
  }
}
</style>
