<template>
    <!-- ================> Footer section start here <================== -->
	<footer class="footer footer--style2">
		<!-- <div class="footer__top bg_img" style="background-image: url(@/assets/images/footer/bg.jpg)"> -->
		<div class="footer__top">
			<img src="@/assets/images/footer/bg.jpg" class="bg_img" alt="Background Image">
			<div class="footer__newsletter wow fadeInUp" data-wow-duration="1.5s">
				<div class="container">
					<div class="row g-4 justify-content-center">
						<div class="col-lg-6 col-12">
							<div class="footer__newsletter--area">
								<div class="footer__newsletter--title">
									<h4>Inscription à la newsletter</h4>
								</div>
								<div class="footer__newsletter--form">
									<form action="#">
										<input type="email" placeholder="Votre adresse e-mail">
										<button type="submit" class="default-btn"><span>S'abonner</span></button>
									</form>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-12">
							<div class="footer__newsletter--area justify-content-xxl-end">
								<div class="footer__newsletter--title me-xl-4">
									<h4>Rejoindre la communauté</h4>
								</div>
								<div class="footer__newsletter--social">
									<ul>
										<li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
										<li><a href="#"><i class="fa-brands fa-twitch"></i></a></li>
										<li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
										<li><a href="#"><i class="fa-brands fa-dribbble"></i></a></li>
										<li><a href="#"><i class="fa-brands fa-facebook-messenger"></i></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer__toparea padding-top padding-bottom wow fadeInUp" data-wow-duration="1.5s">
				<div class="container">
					<div class="row g-4">
						<div class="col-lg-3 col-sm-6 col-12">
							<div class="footer__item footer--about">
								<div class="footer__inner">
									<div class="footer__content">
										<div class="footer__content--title">
											<h4>À propos de Hodalo</h4>
										</div>
										<div class="footer__content--desc">
											<p>hodalo.com est un site de rencontre convivial basé sur un modèle HTML pour la fonctionnalité communautaire</p>
										</div>
										<div class="footer__content--info">
											<p><b>Addresse :</b> Agoè Vakpossito, Lomé TOGO</p>
											<p><b>Contact :</b> +228 91 85 55 66 info@eretupay.com</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-sm-6 col-12">
							<div class="footer__item footer--feature">
								<div class="footer__inner">
									<div class="footer__content">
										<div class="footer__content--title">
											<h4>Membres en vedette</h4>
										</div>
										<div class="footer__content--desc">
											<ul>
												<li>
													<div class="thumb position-relative">
														<img src="../../assets/images/footer/feature/01.jpg" alt="member-img">
														<span class="feature__activity"></span>
													</div>
													<div class="content">
														<a href="member-single.html"><h6>Samantha Lee</h6></a>
														<p>Active</p>
													</div>
												</li>
												<li>
													<div class="thumb position-relative">
														<img src="../../assets/images/footer/feature/02.jpg" alt="member-img">
														<span class="feature__activity feature__activity--ofline"></span>
													</div>
													<div class="content">
														<a href="member-single.html"><h6>Peter McMillan</h6></a>
														<p>il y a 2 heures</p>
													</div>
												</li>
												<li>
													<div class="thumb position-relative">
														<img src="../../assets/images/footer/feature/03.jpg" alt="member-img">
														<span class="feature__activity"></span>
													</div>
													<div class="content">
														<a href="member-single.html"><h6>Tluagtea Tualzik</h6></a>
														<p>Active</p>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-sm-6 col-12">
							<div class="footer__item footer--support">
								<div class="footer__inner">
									<div class="footer__content">
										<div class="footer__content--title">
											<h4>Contacts et assistance</h4>
										</div>
										<div class="footer__content--desc">
											<ul>
												<li><a href="#"><i class="fa-solid fa-angle-right"></i> À propos de nous</a></li>
												<li><a href="#"><i class="fa-solid fa-angle-right"></i> Notre équipe</a></li>
												<li><a href="#"><i class="fa-solid fa-angle-right"></i> Témoignages</a></li>
												<li><a href="#"><i class="fa-solid fa-angle-right"></i> Entrer en contact</a></li>
												<li><a href="#"><i class="fa-solid fa-angle-right"></i> FAQ</a></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-sm-6 col-12">
							<div class="footer__item footer--activity">
								<div class="footer__inner">
									<div class="footer__content">
										<div class="footer__content--title">
											<h4>Activité récente</h4>
										</div>
										<div class="footer__content--desc">
											<ul>
												<li>
													<div class="thumb">
														<a href="group-single.html"><img src="../../assets/images/footer/activity/01.jpg" alt="dating thumb"></a>
													</div>
													<div class="content">
														<a href="group-single.html"><h6>Où trouver un bon...</h6></a>
														<p>13 août 2022</p>
													</div>
												</li>
												<li>
													<div class="thumb">
														<a href="group-single.html"><img src="../../assets/images/footer/activity/02.jpg" alt="dating thumb"></a>
													</div>
													<div class="content">
														<a href="group-single.html"><h6>Où trouver un bon...</h6></a>
														<p>13 août 2022</p>
													</div>
												</li>
												<li>
													<div class="thumb">
														<a href="group-single.html"><img src="../../assets/images/footer/activity/03.jpg" alt="dating thumb"></a>
													</div>
													<div class="content">
														<a href="group-single.html"><h6>Où trouver un bon...</h6></a>
														<p>13 août 2022</p>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="footer__bottom wow fadeInUp" data-wow-duration="1.5s">
			<div class="container">
				<div class="footer__content text-center">
					<p class="mb-0">Tous droits réservés &copy; <a href="index.html">Hodalo</a> || Conception par : Eretu</p>
				</div>
			</div>
		</div>
	</footer>
    <!-- ================> Footer section end here <================== -->
</template>

<style>
	.footer__top {
    	position: relative; /* Assurez-vous que le conteneur a une position relative */
	}

	.footer__top .bg_img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1; /* Positionner l'image en arrière-plan */
		object-fit: cover; /* Assurez-vous que l'image couvre tout le conteneur */
		opacity: 0.5; /* Réglez l'opacité de l'image au besoin */
	}
</style>