<template>
    <!-- ================> App section start here <================== -->
	<div class="app app--style2 padding-top padding-bottom">
		<div class="container">
			<div class="row g-4 justify-content-center">
				<div class="col-xxl-6 col-12">
					<div class="app__item wow fadeInUp" data-wow-duration="1.5s">
						<div class="app__inner">
							<div class="app__content text-center">
								<h4>Téléchargez l'application Hodalo</h4>
								<h2>Connectez-vous facilement à tout le monde</h2>
								<p>Vous nous trouvez enfin et vous êtes déjà amoureux. Plus de 5 000 000 de personnes dans le monde ont déjà partagé la même expérience et utilisent notre système. Nous rejoindre aujourd'hui est devenu plus facile !</p>
								<ul class="justify-content-center">
									<li><a href="#"><img src="../../assets/images/app/01.jpg" alt="dating thumb"></a></li>
									<li><a href="#"><img src="../../assets/images/app/02.jpg" alt="dating thumb"></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <!-- ================> App section end here <================== -->
</template>