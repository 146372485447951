/** Import des modules nécessaires */
import Axios from './caller.service';
import { googleLogout } from 'vue3-google-login';
import qs from 'qs';

/** Fonction d'inscription */
let register = (data) => {
    return Axios.post('/auth/register', qs.stringify(data));
};

/** Fonction de connexion */
let login = (credentials) => {
    return Axios.post('/auth/login', credentials);
};

/** Fonction de validation de la connexion sociale (Google) */
let validateSocialConnect = (data) => {
    return Axios.post('/social-auth/google', data);
};

/** Fonction de déconnexion */
let logout = () => {
    localStorage.removeItem('auth_token');
    googleLogout();
};

/** Fonction pour obtenir le token d'authentification */
let getToken = () => {
    return localStorage.getItem('auth_token');
};

/** Fonction pour sauvegarder le token d'authentification */
let saveToken = (auth_token) => {
    localStorage.setItem('auth_token', auth_token);
};

/** Fonction pour vérifier si l'utilisateur est connecté */
let isLogged = () => {
    let auth_token = localStorage.getItem('auth_token');
    return !!auth_token;
};

/** Fonction pour obtenir l'ID de l'utilisateur connecté */
let getUserId = async () => {
    try {
        const auth_token = getToken();

        if (!auth_token) {
            console.error('Token non trouvé.');
            return;
        }

        const response = await Axios.get('/auth/profile', {
            headers: {
                'Authorization': `Bearer ${auth_token}`
            }
        });

        console.log('Données du profil:', response.data);
        return response.data.user.id;
    } catch (error) {
        console.error('Erreur lors de la récupération de l\'ID de l\'utilisateur:', error);
        throw error;
    }
};

let getMatches = () => {
    const auth_token = getToken();
    return Axios.get('/matches/', {
        headers: {
            'Authorization': `Bearer ${auth_token}`
        }
    });
};

let getLikes = () => {
    const auth_token = getToken();
    return Axios.get('/user/likes', {
        headers: {
            'Authorization': `Bearer ${auth_token}`
        }
    });
};

let getDates = () => {
    const auth_token = getToken();
    return Axios.get('/user/dates', {
        headers: {
            'Authorization': `Bearer ${auth_token}`
        }
    });
};

let swipeToLike = (userFromId, userToId, like) => {
    const auth_token = getToken();
    return Axios.post('/swipes/', {
        user_from_id: userFromId,
        user_to_id: userToId,
        like: like
    }, {
        headers: {
            'Authorization': `Bearer ${auth_token}`
        }
    });
}

let uploadProfilePhoto = (formData) => {
    const auth_token = getToken();
    return Axios.post('/user-media/', formData, {
        headers: {
            'Authorization': `Bearer ${auth_token}`,
            'Content-Type': 'multipart/form-data',
        }
    });
}

let fetchUserPhotos = () => {
    const auth_token = getToken();
    return Axios.get('/user-media/', {
        headers: {
            'Authorization': `Bearer ${auth_token}`
        }
    });
}

/** Fonction pour mettre à jour les informations de l'utilisateur */
let updateUser = (data) => {
    const auth_token = getToken();
    return Axios.put('/auth/update', data, {
        headers: {
            'Authorization': `Bearer ${auth_token}`
        }
    });
};

/** Fonction pour obtenir les données du profil de l'utilisateur */
let getUserProfil = async () => {
    try {
        const auth_token = getToken();

        if (!auth_token) {
            console.error('Token non trouvé.');
            return;
        }

        const response = await Axios.get('/auth/profile', {
            headers: {
                'Authorization': `Bearer ${auth_token}`
            }
        });

        console.log('Données du profil:', response.data);
        return response;
    } catch (error) {
        console.error('Erreur lors de la récupération du profil utilisateur:', error);
        throw error;
    }
};

/** Exportation des services du compte */
export const accountService = {
    register,
    login,
    logout,
    getToken,
    saveToken,
    isLogged,
    validateSocialConnect,
    getUserId,
    getMatches,
    getLikes,
    getDates,
    swipeToLike,
    uploadProfilePhoto,
    fetchUserPhotos,
    updateUser,
    getUserProfil
};
