import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import vue3GoogleLogin from 'vue3-google-login';
import blurDirective from './directives/v-blur';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// import BootstrapVue3 from 'bootstrap-vue-3';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

import '@/assets/styles.css';

import { messaging } from '@/_services/firebase.js'; // Import your firebase configuration

const CLIENT_ID = "1088908856516-1neo2antlogca91ujoq38448oiv7vk5h.apps.googleusercontent.com";

library.add(faPencilAlt);

const app = createApp(App);

// Utilisation de FontAwesome
app.component('font-awesome-icon', FontAwesomeIcon);

// Utilisation de BootstrapVue3
// app.use(BootstrapVue3);

import router from './router';
app.use(router);
app.use(store);
app.use(vue3GoogleLogin, {
  clientId: CLIENT_ID
});
app.directive('blur', blurDirective);

app.mount('#app');

// Enregistrer le service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
  }).catch((err) => {
      console.error('Service Worker registration failed:', err);
  });
}