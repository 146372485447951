<template>
    <!-- <div class="pageheader bg_img" style="background-image: url(@/assets/images/bg-img/pageheader.jpg);"> -->
    <div class="pageheader">
        <img src="@/assets/images/bg-img/pageheader.jpg" class="bg_img" alt="Background Image">
        <div class="container">
            <div class="pageheader__content text-center">
                <h2>Contact</h2>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center mb-0">
                      <li class=""><router-link :class="{active : $route.name === 'home'}" :to="{name:'home'}">Accueil</router-link></li>
                      <li class="ms-3"> > </li>
                      <li class=""><router-link :class="{active : $route.name === 'contact'}" :to="{name:'contact'}"><span style="margin-left: 10px;">Contact</span></router-link></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="info-section padding-top padding-bottom">
        <div class="container">
			<div class="section__header style-2 text-center">
				<h2>Informations de contact</h2>
				<p>Faites-nous part de vos opinions. Vous pouvez également nous écrire si vous avez des questions.</p>
			</div>
            <div class="section-wrapper">
                <div class="row justify-content-center g-4">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="contact-item text-center">
                            <div class="contact-thumb mb-4">
                                <img src="@/assets/images/contact/icon/01.png" alt="contact-thumb">
                            </div>
                            <div class="contact-content">
                                <h6 class="title">Adresse de bureau</h6>
                                <p>Agoè Vakpossito, Lomé TOGO</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="contact-item text-center">
                            <div class="contact-thumb mb-4">
                                <img src="@/assets/images/contact/icon/02.png" alt="contact-thumb">
                            </div>
                            <div class="contact-content">
                                <h6 class="title">Numéro de téléphone</h6>
                                <p>+228 91 85 55 66</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="contact-item text-center">
                            <div class="contact-thumb mb-4">
                                <img src="@/assets/images/contact/icon/03.png" alt="contact-thumb">
                            </div>
                            <div class="contact-content">
                                <h6 class="title">Envoyer un e-mail</h6>
                                <p>info@eretupay.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contact-section bg-white">
        <div class="contact-top padding-top padding-bottom">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-9">
                        <div class="contact-form-wrapper text-center">
                            <h2>Retour</h2>
							<p class="mb-5">Faites-nous part de vos opinions. Vous pouvez également nous écrire si vous avez des questions.</p>
                            <form class="contact-form" action="https://demos.codexcoder.com/themeforest/html/ollya/contact.php" id="contact-form" method="POST">
                                <div class="form-group">
                                    <input type="text" placeholder="Votre nom" id="name" name="name" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Votre e-mail" id="email" name="email" required>
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Téléphone" id="phone" name="phone" required>
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Sujet" id="subject" name="subject" required>
                                </div>
                                <div class="form-group w-100">
                                    <textarea name="message" rows="8" id="message" placeholder="Votre Message" required></textarea>
                                </div>
                                <div class="form-group w-100 text-center">
                                    <button class="default-btn reverse" type="submit"><span>Envoyez le message</span></button>
                                </div>
                            </form>
                            <p class="form-message"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-bottom">
            <div class="contac-bottom">
                <div class="row justify-content-center g-0">
                    <div class="col-12">
                        <div class="location-map">
                            <div id="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423283.4355669374!2d-118.69192993092697!3d34.02073049448939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1633958856057!5m2!1sen!2sbd" allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
    export default {
      
    }
</script>

<style>
	.pageheader {
    	position: relative; /* Assurez-vous que le conteneur a une position relative */
	}

	.pageheader .bg_img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1; /* Positionner l'image en arrière-plan */
		object-fit: cover; /* Assurez-vous que l'image couvre tout le conteneur */
		opacity: 0.5; /* Réglez l'opacité de l'image au besoin */
	}
</style>