<template>
    <!-- <div class="pageheader bg_img" style="background-image: url(@/assets/images/bg-img/pageheader.jpg);"> -->
    <div class="pageheader">
        <img src="@/assets/images/bg-img/pageheader.jpg" class="bg_img" alt="background-image">
        <div class="container">
            <div class="pageheader__content text-center">
                <h2>À propos de Hodalo</h2>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center mb-0">
                      <li class=""><router-link :class="{active : $route.name === 'home'}" :to="{name:'home'}">Accueil</router-link></li>
                      <li class="ms-3"> > </li>
                      <li class=""><router-link :class="{active : $route.name === 'about'}" :to="{name:'about'}"><span style="margin-left: 10px;">A propos</span></router-link></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="about about--style5 padding-top padding-bottom">
		<div class="container">
			<div class="row justify-content-center g-4 align-items-center">
                <div class="col-lg-6 col-12">
                    <div class="about__thumb">
                        <img src="@/assets/images/about/01.png" alt="dating thumb">
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="about__content">
                        <h2>Bonjour mon ami</h2>
                        <h5>Nous sommes là pour créer de l'émotion, connecter les gens et créer des histoires heureuses.</h5>
                        <p>Hodalo est un site de rencontre convivial. Il vous permet de faire facilement des rencontres.</p>
                        <a href="membership.html" class="default-btn reverse"><span>Obtenez un abonnement</span></a>
                    </div>
                </div>
            </div>
		</div>
	</div>

	<!-- <div class="about padding-top padding-bottom bg_img" style="background-image: url(@/assets/images/bg-img/02.jpg);"> -->
    <div class="about">
        <img src="@/assets/images/bg-img/02.jpg" class="bg_img" alt="background-image">
        <div class="container">
			<div class="section__header style-2 text-center">
				<h2>Pourquoi choisir Hodalo</h2>
				<p>Notre plateforme de rencontres est comme une bouffée d’air frais. Un design épuré et tendance avec des fonctionnalités prêtes à l’emploi que vous allez adorer.</p>
			</div>
			<div class="section__wrapper">
				<div class="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
					<div class="col">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="@/assets/images/about/01.jpg" alt="dating thumb">
								</div>
								<div class="about__content">
									<h4>Simple à utiliser</h4>
									<p>Étapes simples à suivre pour avoir une connexion correspondante.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="@/assets/images/about/02.jpg" alt="dating thumb">
								</div>
								<div class="about__content">
									<h4>Correspondance intelligente</h4>
									<p>Créez des connexions avec des utilisateurs qui vous ressemblent.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="@/assets/images/about/03.jpg" alt="dating thumb">
								</div>
								<div class="about__content">
									<h4>Filtrer très rapidement</h4>
									<p>Ne perdez pas votre temps ! Trouvez uniquement ce qui vous intéresse</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="about__item text-center">
							<div class="about__inner">
								<div class="about__thumb">
									<img src="@/assets/images/about/04.jpg" alt="dating thumb">
								</div>
								<div class="about__content">
									<h4>Communauté cool</h4>
									<p>Le réseau BuddyPress regorge de membres sympas.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="story padding-top padding-bottom">
		<div class="container">
			<div class="section__header style-2 text-center">
				<h2>Histoires de réussite de Hodalo</h2>
				<p>Écoutez et apprenez des membres de notre communauté et découvrez des trucs et astuces pour rencontrer votre amour. Rejoignez-nous et faites partie d'une plus grande famille.</p>
			</div>
			<div class="section__wrapper">
				<div class="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
					<div class="col">
						<div class="story__item">
							<div class="story__inner">
								<div class="story__thumb">
									<a href="blog-single.html"><img src="@/assets/images/story/01.jpg" alt="dating thumb"></a>
									<span class="member__activity member__activity--ofline">Divertissement</span>
								</div>
								<div class="story__content">
									<a href="blog-single.html"><h4>Des lieux de rêve et des lieux à visiter en 2024</h4></a>
									<div class="story__content--author">
										<div class="story__content--thumb">
											<img src="@/assets/images/story/author/01.jpg" alt="dating thumb">
										</div>
										<div class="story__content--content">
											<h6>Hester Reeves</h6>
											<p>16 avril 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="story__item">
							<div class="story__inner">
								<div class="story__thumb">
									<a href="blog-single.html"><img src="@/assets/images/story/02.jpg" alt="dating thumb"></a>
									<span class="member__activity member__activity--ofline">Histoires d'amour</span>
								</div>
								<div class="story__content">
									<a href="blog-single.html"><h4>Réalisez vos rêves et monétisez rapidement</h4></a>
									<div class="story__content--author">
										<div class="story__content--thumb">
											<img src="@/assets/images/story/author/02.jpg" alt="dating thumb">
										</div>
										<div class="story__content--content">
											<h6>Arika Q Smith</h6>
											<p>14 mars 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="story__item">
							<div class="story__inner">
								<div class="story__thumb">
									<a href="blog-single.html"><img src="@/assets/images/story/03.jpg" alt="dating thumb"></a>
									<span class="member__activity member__activity--ofline">Attirance</span>
								</div>
								<div class="story__content">
									<a href="blog-single.html"><h4>L'amour ne regarde pas avec les yeux, mais avec l'esprit.</h4></a>
									<div class="story__content--author">
										<div class="story__content--thumb">
											<img src="@/assets/images/story/author/03.jpg" alt="dating thumb">
										</div>
										<div class="story__content--content">
											<h6>William Show</h6>
											<p>18 juin 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="work work--style2 padding-top padding-bottom bg_img" style="background-image: url(assets/images/bg-img/01.jpg);">
		<div class="container">
			<div class="section__wrapper">
				<div class="row g-4 justify-content-center">
					<div class="col-xl-6 col-lg-8 col-12">
						<div class="work__item">
							<div class="work__inner">
								<div class="work__thumb">
									<img src="@/assets/images/work/09.png" alt="dating thumb">
								</div>
								<div class="work__content">
									<h3>Confiance et sécurité</h3>
									<p>Choisissez parmi l’un de nos niveaux d’adhésion et débloquez les fonctionnalités dont vous avez besoin. </p>
									<a href="policy.html" class="default-btn"><span>See More Details</span></a>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-8 col-12">
						<div class="work__item">
							<div class="work__inner">
								<div class="work__thumb">
									<img src="@/assets/images/work/10.png" alt="dating thumb">
								</div>
								<div class="work__content">
									<h3>Simple Membership</h3>
									<p>Choisissez parmi l’un de nos niveaux d’adhésion et débloquez les fonctionnalités dont vous avez besoin. </p>
									<a href="membership.html" class="default-btn reverse"><span>Membership Details</span></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="app app--style2 padding-top padding-bottom">
		<div class="container">
			<div class="row g-4 justify-content-center">
				<div class="col-xxl-6 col-12">
					<div class="app__item">
						<div class="app__inner">
							<div class="app__content text-center">
								<h4>Téléchargez l'application Hodalo</h4>
								<h2>Connectez-vous facilement à tout le monde</h2>
								<p>Vous nous trouvez enfin et vous êtes déjà amoureux. Plus de 5 000 000 de personnes dans le monde ont déjà partagé la même expérience et utilisent notre système. Nous rejoindre aujourd'hui est devenu plus facile !</p>
								<ul class="justify-content-center">
									<li><a href="#"><img src="@/assets/images/app/01.jpg" alt="dating thumb"></a></li>
									<li><a href="#"><img src="@/assets/images/app/02.jpg" alt="dating thumb"></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
    export default {
      
    }
</script>

<style>
	.pageheader .about{
    	position: relative; /* Assurez-vous que le conteneur a une position relative */
	}

	.pageheader .bg_img .about{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1; /* Positionner l'image en arrière-plan */
		object-fit: cover; /* Assurez-vous que l'image couvre tout le conteneur */
		opacity: 0.5; /* Réglez l'opacité de l'image au besoin */
	}
</style>