<template>
  <div class="mt-4">
    <h2>Welcome to Vue.js Chat App</h2>
    <form class="detail-box my-5" @submit.prevent="login">
      <div class="form-group my-3">
        <h4>Login to Chat</h4>
        <input
          type="text"
          v-model="email"
          class="form-control mb-4 mt-4"
          placeholder="Enter your email..."
        />
        <input
          type="password"
          v-model="password"
          class="form-control mb-4"
          placeholder="Enter your password..."
          v-on:keyup.enter="login"
        />
        <router-link :to="{ path: 'chat_register' }">
          <h6 class="mb-3" style="font-weight: 600">Create an account</h6>
        </router-link>
        <button
          type="button"
          v-on:click="login"
          class="btn btn-primary"
          style="font-weight: 600"
        >Login</button>
      </div>
    </form>
    <div v-if="error" class="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from '@/_services/firebase.js';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';

export default {
  name: 'Login',
  setup() {
    const email = ref('');
    const password = ref('');
    const error = ref(null);
    const router = useRouter();

    const login = async () => {
      error.value = null; // Reset error message
      try {
        const res = await signInWithEmailAndPassword(auth, email.value, password.value);
        console.log(res);
        if (res.user) {
          const q = query(collection(db, 'users'), where('id', '==', res.user.uid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            localStorage.setItem('id', userData.id);
            localStorage.setItem('name', userData.name);
            localStorage.setItem('email', userData.email);
            localStorage.setItem('password', userData.password);
            localStorage.setItem('photoURL', userData.URL);
            localStorage.setItem('description', userData.description);
            localStorage.setItem('FirebaseDocumentId', doc.id);
          });
          router.push('/chat_side_bar');
        }
      } catch (err) {
        console.error('Error logging in:', err);
        error.value = err.message;
      }
    };

    onMounted(() => {
      if (localStorage.getItem('id')) router.push('/chat_side_bar');
    });

    return {
      email,
      password,
      error,
      login
    };
  }
};
</script>


<style scoped>
input[type="text"],
input[type="password"] {
  margin: 0 auto;
  width: 80%;
  border: 1px solid lightgrey;
}
.detail-box {
  padding: 5px;
  border: 1px solid lightgrey;
  width: 400px;
  min-height: 250px;
  margin: 0 auto;
}
h2,
h4 {
  color: dodgerblue;
  font-weight: 600;
}
</style>