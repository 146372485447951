<script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { accountService } from '@/_services'

  const router = useRouter()

  const baseURL = process.env.VUE_APP_API_BASE_URL.replace('/api', '');

  // Référence pour stocker les likes
  const likes = ref([])

  // Fonction pour récupérer les likes de l'utilisateur
  const fetchLikes = async () => {
    try {
      const auth_token = accountService.getToken();

      if (!auth_token) {
        console.error('Token non trouvé.');
        return;
      }

      const response = await accountService.getLikes();

      console.log('Réponse de l\'API pour les likes :', response.data.items);

      if (Array.isArray(response.data.items)) {
        likes.value = response.data.items; 
      } else {
        console.error('La réponse ne contient pas un tableau d\'items de likes attendu.');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des likes :', error);
    }
  }

  // Méthode pour naviguer vers la vue des likes
  const goToLikes = () => {
    router.push({ name: 'likes' })
  }

  // Méthode pour gérer le like
  const like = (id) => {
    console.log(`Liked ${id}`)
    // Implémentez la logique de like si nécessaire
  }

  // Méthode pour gérer le dislike
  const dislike = (id) => {
    console.log(`Disliked ${id}`)
    // Implémentez la logique de dislike si nécessaire
  }

  // Chargement initial des likes au montage du composant
  onMounted(() => {
    fetchLikes()
  })
</script>

<template>
  <div class="component-container">
    <div class="container">
      <div class="likes-view">
        <div class="header">
          <p class="text-dark">Voici vos Likes</p>
          <p class="text-dark">Plus vite vous renvoyez un Like, plus grandes sont vos chances de discuter et de vous rencontrer !</p>
          <button @click="goToLikes" class="btn-primary">Voir mes Likes</button>
        </div>
        <div class="likes-container">
          <div v-for="like in likes" :key="like.id" class="like-card">
            <img v-blur="!isSubscribed" :src="like.images.length ? `${baseURL}${like.images[0].file}` : ''" class="profile-image" />
            <div class="actions">
              <!-- <button @click="dislike(like.id)">✗</button>
              <button @click="like(like.id)">❤</button> -->
              <button @click="">✗</button>
              <button @click="">❤</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Réinitialisation des marges et paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container principal */
.container {
  width: 54%; /* Largeur fixe pour les grands écrans */
  height: 90vh;
  box-shadow: 0 30px 70px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

/* Vue des likes */
.likes-view {
  padding: 50px 30px;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/* Masquer la scrollbar pour les navigateurs basés sur WebKit */
.likes-view::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Masquer la scrollbar pour les autres navigateurs */
.likes-view {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Style de l'en-tête */
.header {
  margin-bottom: 20px;
}

/* Style du bouton primaire */
.btn-primary {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

/* Container des likes */
.likes-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Trois colonnes pour les grands écrans */
  row-gap: 30px; /* Espace entre les lignes */
  column-gap: 30px; /* Espace entre les colonnes */
  flex-wrap: wrap;
}

/* Carte de like */
.like-card {
  border: 1px solid hsl(0, 1%, 50%);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 200px;
  height: 250px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
}

/* Image de la carte de like */
.like-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

/* Actions sur la carte de like */
.actions {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}

/* Boutons d'action */
.actions button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.actions button:hover {
  color: #000;
}

/* Texte sombre */
.text-dark {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Media Query pour les petits écrans */
@media (max-width: 767px) {
  .container {
    width: 100%; /* Prendre toute la largeur de l'écran sur mobile */
    left: 0;
    transform: none; /* Supprimer la transformation pour les petits écrans */
  }

  .likes-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adapter les colonnes à la largeur de l'écran */
    row-gap: 15px; /* Réduire l'espace entre les lignes */
    column-gap: 15px; /* Réduire l'espace entre les colonnes */
  }

  .like-card {
    width: 100%; /* Prendre toute la largeur de la carte de like */
    height: auto; /* Adapter la hauteur pour le contenu */
  }

  .like-card img {
    height: 150px; /* Ajuster la hauteur de l'image pour les petits écrans */
  }
}

</style>
