import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithCredential, signInWithPopup  } from "firebase/auth";
import { getFirestore, collection, doc, setDoc, onSnapshot, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Votre configuration Firebase
const firebaseConfig = {
    apiKey: "AIzaSyB-MIOfPjnHK_5SsfP4XnlekYtHpogYl4M",
    authDomain: "hodalo-web-chat.firebaseapp.com",
    projectId: "hodalo-web-chat",
    storageBucket: "hodalo-web-chat.appspot.com",
    messagingSenderId: "151540193206",
    appId: "1:151540193206:web:33314cd0294304ee3ed317"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser le service d'authentification
const auth = getAuth(app);

// Configurer le fournisseur Google
const googleProvider = new GoogleAuthProvider();

// Initialiser Firestore
const db = getFirestore(app);

// Initialiser Firebase Storage
const storage = getStorage(app);

// Initialiser Firebase Messaging
const messaging = getMessaging(app);

// Récupérer le FCM token
async function getAndSaveFcmToken(currentUserId) {
    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BO0MDaVjeU9_3wOfJq65QafPxBPtgIVdj-ldRP6_ixF29bmCYHBu1qgeLjbhFadpivlLu3uXleDZ5nhxbM7ps9I' });
        if (currentToken) {
            console.log('FCM Token:', currentToken);
            const userDocRef = doc(db, 'users', currentUserId);
            await setDoc(userDocRef, { fcmToken: currentToken }, { merge: true });
        } else {
            console.error('No registration token available. Request permission to generate one.');
        }
    } catch (error) {
        console.error('Erreur lors de la récupération du FCM token:', error);
    }
}

// Enregistrer le FCM token dans Firestore
async function saveFcmTokenToFirestore(currentUserId, token) {
    try {
        const userRef = doc(db, 'users', currentUserId);
        await setDoc(userRef, { fcmToken: token }, { merge: true });
        console.log('Token enregistré avec succès');
    } catch (error) {
        console.error('Erreur lors de l\'enregistrement du token dans Firestore:', error);
    }
}

// Mettre à jour le FCM token dans Firestore
async function updateFcmToken(currentUserId, newToken) {
    try {
        const userRef = doc(db, 'users', currentUserId);
        await setDoc(userRef, { fcmToken: newToken }, { merge: true });
        console.log('Token mis à jour avec succès');
    } catch (error) {
        console.error('Erreur lors de la mise à jour du token dans Firestore:', error);
    }
}

// Gérer les notifications reçues en premier plan
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Personnalise l'affichage des notifications ici
});

export { 
    auth, 
    db, 
    storage, 
    collection, 
    doc, 
    setDoc, 
    onSnapshot, 
    getDoc, 
    googleProvider, 
    signInWithCredential,
    signInWithPopup,
    updateFcmToken,
    messaging,
    getAndSaveFcmToken,
    saveFcmTokenToFirestore
};
