// Dans votre store Vuex (store/index.js)

import { createStore } from 'vuex';

export default createStore({
  state: {
      notifDisplay: false,
      notifMessage: '',

      // logged: false,
      // appUser: {
      //   id: null, firstname : null, lastname : null, birthday : null, address : null, telephone : null,
      // },

      logged: localStorage.getItem('auth_token') !== null,
      appUser: JSON.parse(localStorage.getItem('appUser')) || {
        id: null,
        firstname: null,
        lastname: null,
        username: null,
        born_on: null
      }
  },
  mutations: {
    displayNotif(state, payload) {
      state.notifDisplay = payload.d;
      state.notifMessage = payload.mes;
    },
    setLogged(state, bool) {
      state.logged = bool;
      if (!bool) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('appUser');
        state.appUser = {
          id: null,
          firstname: null,
          lastname: null,
          username: null,
          born_on: null
        };
      }
    },
    // setLogged(state,bool){
    //   state.logged = bool
    // },
    setAppUser(state, user) {
      state.appUser = { ...state.appUser, ...user };
      localStorage.setItem('appUser', JSON.stringify(state.appUser));
    }
  },
  getters: {
    getNotif: (state) => {
      return state.notifDisplay;
    },
    getNotifMessage: (state) => {
      return state.notifMessage;
    },
    getLogged(state) {
      return state.logged;
    },
    getAppUser(state) {
      return state.appUser;
    }
  }
})
