<template>

  <div style="display: flex; flex-direction: column; height: 100vh">
    
    <header>
      <div style="height: 60px; background: lightgrey">
        <img v-if="currentPeerUser" :src="currentPeerUser.URL" width="40px" class="br-50 header-image" />
        <div class="header-image">
          <h6 class="mt-2" style="font-weight: 600" v-if="currentPeerUser">{{ currentPeerUser.name }}</h6>
        </div>
      </div>
    </header>

    <div class="pt-3" style="background: #efe9e2; flex: 1; overflow-y: auto">
      <!-- <h2 class="welcome">Welcome to Chatbox</h2> -->
      <div class="text-outer">
        <div
          :class="item.idFrom === currentUserId ? 'textFrom' : 'textTo'"
          class="text-inner"
          v-for="item in listMessage"
          :key="item.id"
        >
          <h6>{{ item.content }}</h6>
        </div>
      </div>
    </div>

    <footer>
      <div style="min-height: 60px; background: lightgrey">
          <div style="display: flex; align-items: center; padding: 15px; gap: 10px;">
              <img
              class="pointer"
              src="../assets/picture.png"
              alt="select picture"
              width="30px"
              height="30px"
              />
              <img
              class="pointer"
              src="../assets/sticker.png"
              alt="select sticker"
              width="30px"
              height="30px"
              />
              <input
              type="text"
              style="flex-grow: 1; border: 1px solid; border-radius: 4px; padding: 5px 10px;"
              v-model="inputValue"
              v-on:keyup.enter="sendMessage(inputValue)"
              />
              <img
              class="pointer"
              src="../assets/send.png"
              alt="send message"
              width="30px"
              height="30px"
              v-on:click="sendMessage(inputValue)"
              />
          </div>
      </div>
    </footer>
  </div>

</template>
  
<script>
import { db, collection, doc, setDoc, onSnapshot } from '../_services/firebase.js';
import moment from 'moment';

export default {
  app: 'ChatBox',
  props: ['currentPeerUser'],
  data() {
    return {
      currentUserName: localStorage.getItem('name'),
      currentUserId: localStorage.getItem('id'),
      currentUserPhoto: localStorage.getItem('photoURL'),
      inputValue: '',
      photoURL: localStorage.getItem('photoURL'),
      listMessage: [],
      groupChatId: null,
    };
  },
  watch: {
    currentPeerUser(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.getMessages();
      }
    },
  },
  methods: {
    async sendMessage(content) {
      if (content.trim() === '') {
        console.warn('Message content is empty');
        return;
      }
      if (!this.currentPeerUser) {
        console.error('currentPeerUser is not defined');
        return;
      }
      const timestamp = moment().valueOf().toString();
      const message = {
        id: timestamp,
        idFrom: this.currentUserId,
        idTo: this.currentPeerUser.id,
        timestamp: timestamp,
        content: content.trim(),
      };
      try {
        const messageDoc = doc(collection(db, 'Messages', this.groupChatId, this.groupChatId), timestamp);
        await setDoc(messageDoc, message);
        this.inputValue = '';
        console.log('Message sent successfully');
      } catch (error) {
        console.error('Error sending message: ', error);
      }
    },
    getMessages() {
      if (!this.currentPeerUser) {
        console.warn('currentPeerUser is not defined');
        return;
      }
      this.listMessage = [];
      let groupChatId = `${this.currentPeerUser.id} + ${this.currentUserId}`;
      const messageCollection = collection(db, 'Messages', groupChatId, groupChatId);
      onSnapshot(messageCollection, (snapshot) => {
        if (snapshot.docChanges().length > 0) {
          this.groupChatId = groupChatId;
          snapshot.docChanges().forEach((res) => {
            this.listMessage.push(res.doc.data());
          });
        } else {
          this.groupChatId = `${this.currentUserId} + ${this.currentPeerUser.id}`;
          const reverseMessageCollection = collection(db, 'Messages', this.groupChatId, this.groupChatId);
          onSnapshot(reverseMessageCollection, (snapshot) => {
            snapshot.docChanges().forEach((res) => {
              if (res.type === 'added') {
                this.listMessage.push(res.doc.data());
              }
            });
          });
        }
      });
    },
  },
  mounted() {
    if (this.currentPeerUser) {
      this.getMessages();
    } else {
      console.error('currentPeerUser is not defined during mounting');
    }
  },
};
</script>
  
<style scoped>
  .welcome {
    color: #635a5a;
    font-weight: 600;
    margin: 10px 0px 32px;
  }
  .br-50 {
    border-radius: 50%;
  }
  .header-image {
    float: left;
    margin-left: 10px;
    margin-top: 10px;
  }
  .text-outer {
    display: flex;
    flex-direction: column;
  }
  .text-inner {
    padding: 10px 10px 2px;
    border-radius: 0.5rem;
    width: 20%;
  }
  .textFrom {
    background: teal;
    color: white;
    margin: 0% 0% 20px 70%;
  }
  .textTo {
    background: lightcoral;
    color: black;
    margin: 0% 0% 20px 5%;
  }
  .pointer {
    cursor: pointer;
  }
  </style>
  