<script>
export default {
  name: 'VipComponent',
};
</script>

<template>
  <div class="component-container">
    <div class="container">
      <div class="vip-view">
        <p class="text-dark pt-4">VIP</p>
        <p class="text-dark">Comming Soon!!</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Réinitialisation des marges et paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Conteneur principal */
.component-container {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Container principal */
.container {
  width: 54%; /* Largeur fixe pour les grands écrans */
  height: 90vh;
  box-shadow: 0 30px 70px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

/* Vue VIP */
.vip-view {
  padding: 50px 30px;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/* Texte sombre */
.text-dark {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Media Query pour les petits écrans */
@media (max-width: 767px) {
  .container {
    width: 100%; /* Prendre toute la largeur de l'écran sur mobile */
    left: 0;
    transform: none; /* Supprimer la transformation pour les petits écrans */
  }

  .vip-view {
    padding: 20px; /* Réduire le padding pour les petits écrans */
  }
}

</style>
