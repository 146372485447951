<script>
import { db, collection, doc, setDoc, onSnapshot, getDoc } from '@/_services/firebase.js';
import { getFirestore, query,  orderBy, limit, getDocs } from 'firebase/firestore';
import moment from 'moment';

export default {
  name: 'MessagesView',
  props: ['id', 'name'],
  data() {
    return {
      currentUserName: localStorage.getItem('username'),
      currentUserId: localStorage.getItem('id'),
      currentUserPhoto: localStorage.getItem('photoURL'),
      inputValue: '',
      listMessage: [],
      groupChatId: null,
      currentPeerUser: {
        id: this.id,
        name: this.name
      },
      lastMessages: {} // Ajouter un objet pour stocker les derniers messages
    };
  },
  watch: {
    id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentPeerUser = { id: newVal, name: this.name };
        this.getMessages();
      }
    },
    name(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentPeerUser.name = newVal;
      }
    }
  },
  methods: {
    async sendMessage(content) {
      if (content.trim() === '') {
        console.warn('Message content is empty');
        return;
      }
      if (!this.currentPeerUser || !this.currentUserId) {
        console.error('currentPeerUser or currentUserId is not defined', { 
          currentPeerUser: this.currentPeerUser, 
          currentUserId: this.currentUserId 
        });
        return;
      }

      const timestamp = moment().valueOf().toString();
      const message = {
        id: timestamp,
        idFrom: this.currentUserId,
        idTo: this.currentPeerUser.id,
        timestamp: timestamp,
        content: content.trim(),
      };

      console.log('Sending message:', message);

      try {
        const messageDoc = doc(collection(db, 'Messages', this.groupChatId, this.groupChatId), timestamp);
        await setDoc(messageDoc, message);
        this.inputValue = '';
        console.log('Message sent successfully');

        // Fonction sendNotification intégrée directement ici
        async function sendNotification(fcmToken, title, body) {
          const payload = {
            to: fcmToken,
            notification: {
              title: title,
              body: body,
            },
          };

          try {
            const response = await fetch('https://fcm.googleapis.com/fcm/send', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'key=AIzaSyAs5FgZj3xJhbplGmEm83DlHkZdgtNmgB0',
              },
              body: JSON.stringify(payload),
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Notification sent successfully:', responseData);
          } catch (error) {
            console.error('Erreur lors de l\'envoi de la notification:', error);
          }
        }

        // Récupérer le FCM token de l'utilisateur destinataire et envoyer la notification
        const userDoc = await getDoc(doc(db, 'users', this.currentPeerUser.id));
        const fcmToken = userDoc.data().fcmToken;
        if (fcmToken) {
          const title = 'Nouveau message';
          const body = `Vous avez reçu un nouveau message de ${this.currentUserName}`;
          console.log('Calling sendNotification with:', fcmToken, title, body);
          await sendNotification(fcmToken, title, body);
          console.log('Notification envoyée avec succès');
        } else {
          console.log('Aucun FCM token trouvé pour cet utilisateur.');
        }


      } catch (error) {
        console.error('Error sending message: ', error);
      }
    },

    getMessages() {
      if (!this.currentPeerUser || !this.currentUserId) {
        console.warn('currentPeerUser or currentUserId is not defined', { 
          currentPeerUser: this.currentPeerUser, 
          currentUserId: this.currentUserId 
        });
        return;
      }

      this.listMessage = [];
      const groupChatId = this.currentUserId < this.currentPeerUser.id
        ? `${this.currentUserId}-${this.currentPeerUser.id}`
        : `${this.currentPeerUser.id}-${this.currentUserId}`;
      this.groupChatId = groupChatId;

      console.log('Fetching messages for groupChatId:', groupChatId);

      const firestore = getFirestore();
      const messageCollection = collection(firestore, 'Messages', groupChatId, groupChatId);

      // Requête pour obtenir le dernier message
      const q = query(messageCollection, orderBy('timestamp', 'desc'), limit(1));

      // Obtenir le dernier message
      getDocs(q).then(querySnapshot => {
        if (!querySnapshot.empty) {
          const lastMessageDoc = querySnapshot.docs[0].data();
          this.lastMessages.push({
            id: this.currentPeerUser.id,
            lastMessage: lastMessageDoc.content
          });
          console.log('Dernier message:', lastMessageDoc);
        } else {
          console.log('Aucun message trouvé.');
        }
      }).catch(error => {
        console.error('Erreur lors de la récupération du dernier message:', error);
      });

      onSnapshot(messageCollection, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.listMessage.push(change.doc.data());
            this.$nextTick(() => {
              // Faire défiler le conteneur vers le bas
              const container = this.$refs.messagesContainer;
              if (container) {
                container.scrollTop = container.scrollHeight;
              }
            });
            console.log('Message added:', change.doc.data());
          }
        });
        console.log('All messages:', this.listMessage);
      }, (error) => {
        console.error('Error fetching messages: ', error);
      });
    },

    checkMessageDirection(idFrom, currentUserId) {
      console.log('idFrom:', idFrom, 'currentUserId:', currentUserId);
      return idFrom === currentUserId ? 'textFrom' : 'textTo';
    },

    formatTimestamp(timestamp) {
      return moment(parseInt(timestamp)).format('DD MMM YYYY, HH:mm');
    },

  },
  mounted() {
    console.log('Mounted, currentUserId:', this.currentUserId);
    console.log('Mounted, currentPeerUser:', this.currentPeerUser);
    
    if (this.currentPeerUser.id) {
      this.getMessages();
    } else {
      console.error('currentPeerUser is not defined during mounting');
    }
  },
};

</script>

<template>
  <div class="component-container">
    <div class="container">
      <div class="chat-global">
        <div class="pt-3 nav-top">
          <router-link :class="'py-2'" :to="{ name: 'chat' }">
            <div class="location">
              <img src="@/assets/ressources/left-chevron.svg" alt="Retour">
              <p>Retour</p>
            </div>
          </router-link>
          <div class="utilisateur">
            <p class="username">{{ currentPeerUser.name }}</p>
            <p>En ligne</p>
          </div>
          <div class="logos-call">
            <img src="@/assets/ressources/phone.svg" alt="Appel">
            <img src="@/assets/ressources/video-camera.svg" alt="Vidéo">
          </div>
        </div>

        <div class="pt-5 mt-5" style="flex: 1; overflow-y: auto" ref="messagesContainer">
          <div class="text-outer">
            <div
              :class="checkMessageDirection(item.idFrom, currentUserId)"
              class="text-inner"
              v-for="item in listMessage"
              :key="item.id"
            >
              <p>{{ item.content }}</p>
              <span class="timestamp">{{ formatTimestamp(item.timestamp) }}</span>
            </div>
          </div>
        </div>

        <footer>
          <div style="min-height: 60px; background: #f0f0f0">
            <div style="display: flex; align-items: center; padding: 15px 15px 30px 15px; gap: 10px;">
              <img
                class="pointer"
                src="@/assets/picture.png"
                alt="select picture"
                width="30px"
                height="30px"
              />
              <img
                class="pointer"
                src="@/assets/sticker.png"
                alt="select sticker"
                width="30px"
                height="30px"
              />
              <input
                type="text"
                style="flex-grow: 1; border: 1px solid; border-radius: 4px; padding: 5px 10px; background: #fffff0"
                v-model="inputValue"
                v-on:keyup.enter="sendMessage(inputValue)"
              />
              <img
                class="pointer"
                src="@/assets/send.png"
                alt="send message"
                width="30px"
                height="30px"
                v-on:click="sendMessage(inputValue)"
              />
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<style scoped>
*, ::before, ::after {
    box-sizing: border-box;
    margin: 0; 
    padding: 0;
}

.component-container {
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Utiliser toute la hauteur de l'écran */
    padding-right: 290px; /* Ajuster le padding pour les écrans plus grands */
}

/* Styles pour les écrans plus grands */
.container {
  width: 54%; /* Largeur pour les écrans d'ordinateur */
  height: 90vh;
  box-shadow: 0 30px 70px 0px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.chat-global {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.conversation {
    flex-grow: 1;
    overflow-y: auto;
    padding: 100px 0 120px 50px;
    background-color: white;
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.conversation::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for other browsers */
.conversation {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nav-top {
    position: absolute;
    background: #fff;
    top: 20px;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(51,51,51,0.2);
}

.location {
    width: 100px;
    display: flex;
    align-items: center;
}
.location img {
    width: 30px;
    margin-left: 10px;
}
.location p {
    font-size: 20px;
    margin-left: 5px;
}

.utilisateur {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.utilisateur p:nth-child(1){
    font-size: 20px;
}
.utilisateur p:nth-child(2){
    font-size: 14px;
    opacity: 0.8;
}

.logos-call {
    width: 100px;
    display: flex;
    padding-bottom: 10px;
}
.logos-call img {
    margin-right: 20px;
    width: 30px
}

.talk {
    margin: 20px 0;
    color: #f1f1f1;
    display: flex;
    align-items: center;
    font-size: 18px;
    width: 50%;
  
}
.talk img {
    width: 50px;
    border-radius: 50%;
    margin-bottom: auto;
}

.talk p {
    padding: 15px;
    line-height: 25px;
    border-radius: 20px;
}

.right {
    margin-left: auto;
}
.right p {
    margin-right: 10px;
    background:#0078FF;
}
.left p {
    margin-left: 10px;
    background: rgb(216,216,216);
    color: #000;
}

/* **********FORM********** */

.chat-form {
    flex-shrink: 0;
    width: 600px;
    height: 90px;
    background: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
}

.container-inputs-stuffs {
    background: #fff;
    display: flex;
    width: 90%;
    height: 60px;
    border-radius: 9999px;
}

.group-inp {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.group-inp img {
    width: 30px;
}
.group-inp textarea {
    resize: none;
    border: none;
    outline: none;
    padding-left: 10px;
    font-size: 16px;
    width: 100%;
    height: 40px;
    padding-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.files-logo-cont {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-left: 10px;
    display: block;
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.files-logo-cont img {
    width: 30px;
}

.submit-msg-btn {
    background: #fff;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 70px;
    border: none;
    display: flex;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
}

.submit-msg-btn img {
    width: 30px;
}

.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Ajuster cette largeur si nécessaire */
}

.text-outer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligne les boîtes à gauche */
  padding: 0 10px;
}

.text-inner {
  display: inline-block; /* S'adapte à la longueur du message */
  padding: 10px;
  border-radius: 0.5rem;
  width: auto; /* La largeur est dynamique pour les messages */
  max-width: 280px; /* Ajuster cette valeur pour avoir environ 26 caractères par ligne */
  margin-bottom: 10px; /* Espace sous chaque boîte */
  word-wrap: break-word; /* Permet aux mots longs de passer à la ligne suivante */
  white-space: pre-wrap; /* Respecte les sauts de ligne et les espaces multiples */
}

.textFrom {
  background-color: #4692e9; /* Bleu clair */
  color: #ffffff; /* Blanc */
  border-radius: 10px;
  margin-left: auto; /* Aligne la boîte à droite */
}

.textTo {
  background-color: #c9819d; /* Gris clair */
  color: #ffffff; /* Noir */
  border-radius: 10px;
  margin-right: auto; /* Aligne la boîte à gauche */
}

.pointer {
  cursor: pointer;
}

.timestamp {
  font-size: 0.7em;
  color: rgb(255, 255, 255);
  right: 10px;
}

/* Styles spécifiques pour les smartphones */
@media (max-width: 768px) {
  .component-container {
    height: 100vh; /* Utiliser toute la hauteur de l'écran sur les smartphones */
    padding-right: 0; /* Supprimer le padding pour les smartphones */
  }

  .container {
    width: 100%; /* Utiliser toute la largeur de l'écran sur les smartphones */
    height: 100vh; /* Utiliser toute la hauteur de l'écran sur les smartphones */
    position: relative;
    left: 0;
    transform: none;
    padding: 30px 0px 10px 0px;
  }

  .chat-global {
    padding: 0 10px; /* Réduire les marges pour les smartphones */
  }

  .conversation {
    padding: 60px 10px 70px 10px; /* Réduire le padding pour les smartphones */
  }

  .chat-form {
    width: 100%; /* Utiliser toute la largeur de l'écran pour le formulaire de chat sur les smartphones */
    bottom: 0; /* Placer le formulaire au bas de l'écran */
    left: 0;
    transform: none;
  }

  .container-inputs-stuffs {
    width: 100%; /* Utiliser toute la largeur de l'écran pour les éléments de saisie */
  }

  .group-inp {
    width: 100%; /* Utiliser toute la largeur de l'écran pour le groupe d'inputs */
  }

  .username {
    max-width: 150px; /* Réduire la largeur maximale pour les smartphones */
  }

  .text-inner {
    max-width: 220px; /* Ajuster la largeur maximale des messages pour les smartphones */
  }

  @media (max-width: 768px) {
    .logos-call img {
      width: 20px; /* Réduire la taille des icônes sur les smartphones */
      margin-right: 10px; /* Ajuster l'espacement entre les icônes */
    }
  }

  @media (max-width: 768px) {
    .location p {
      font-size: 16px; /* Réduire la taille du texte du nom de l'utilisateur sur les smartphones */
    }
  }

  @media (max-width: 768px) {
    .utilisateur p {
      font-size: 10px; /* Réduire la taille du texte du nom de l'utilisateur sur les smartphones */
    }
  }

}
</style>

