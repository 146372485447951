<template>
    <div class="w-100 mt-3">
      <h2 class="fw-600">Welcome to Profile section</h2>
      <div class="profile">
        <img class="br-06" :src="photoURL" width="100%" height="100%" />
        <label class="avatar">
          <font-awesome-icon icon="pencil-alt" />
          <input type="file" name="upload" class="w-0" @change="changeAvatar" />
        </label>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input type="text" name="name" class="form-control" v-model="name" />
      </div>
      <div class="form-group">
        <label>Tell me about yourself</label>
        <input type="text" name="aboutMe" class="form-control" v-model="aboutMe" />
      </div>
      <button type="button" class="btn btn-primary" @click="uploadAvatar">Save</button>
      <button type="button" class="btn btn-info" @click="goBack">Back</button>
      <div v-if="error" class="error">
        {{ error }}
      </div>
    </div>
</template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { db, storage } from '@/_services/firebase'; // Assurez-vous que ces imports sont corrects
  import { doc, updateDoc } from 'firebase/firestore';
  import { getDownloadURL, ref as storageRef, uploadBytesResumable } from 'firebase/storage';
  
  export default {
    name: 'Profile',
    setup() {
      const firebaseDocId = localStorage.getItem('FirebaseDocumentId');
      const id = localStorage.getItem('id');
      const name = ref(localStorage.getItem('name') || '');
      const aboutMe = ref(localStorage.getItem('description') || '');
      const photoURL = ref(localStorage.getItem('photoURL'));
      const newPhoto = ref(null);
      const error = ref(null);
      const router = useRouter();
  
      const goBack = () => {
        router.push('/chat');
      };
  
      const changeAvatar = (event) => {
        error.value = null;
        if (event.target.files && event.target.files[0]) {
          const fileType = event.target.files[0].type.toString();
          if (fileType.indexOf('image') !== 0) {
            error.value = 'Please choose an image';
            return;
          }
          newPhoto.value = event.target.files[0];
          photoURL.value = URL.createObjectURL(event.target.files[0]);
        } else {
          error.value = 'Something went wrong!';
        }
      };
  
      const uploadAvatar = () => {
        if (newPhoto.value) {
          const storageReference = storageRef(storage, id); // Correction ici
          const uploadTask = uploadBytesResumable(storageReference, newPhoto.value);
          uploadTask.on(
            'state_changed',
            null,
            (err) => {
              console.error('Upload error:', err.message);
              error.value = err.message;
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                updateUserInfo(true, url);
              });
            }
          );
        } else {
          updateUserInfo(false, null);
        }
      };
  
      const updateUserInfo = async (isUrlPresent, downloadURL) => {
        let newInfo = isUrlPresent
          ? {
              name: name.value,
              description: aboutMe.value,
              URL: downloadURL,
            }
          : {
              name: name.value,
              description: aboutMe.value,
            };
        try {
          const userDoc = doc(db, 'users', firebaseDocId);
          await updateDoc(userDoc, newInfo);
          localStorage.setItem('name', name.value);
          localStorage.setItem('description', aboutMe.value);
          if (isUrlPresent) {
            localStorage.setItem('photoURL', downloadURL);
          }
          error.value = 'Profile updated';
        } catch (err) {
          console.error('Error updating profile:', err);
          error.value = err.message;
        }
      };
  
      onMounted(() => {
        if (!localStorage.hasOwnProperty('id')) router.push('/');
      });
  
      return {
        name,
        aboutMe,
        photoURL,
        newPhoto,
        error,
        goBack,
        changeAvatar,
        uploadAvatar,
      };
    },
  };
</script>
  
<style scoped>
  input[type="text"] {
    margin: 0 auto;
    width: 25%;
  }
  label {
    font-size: 18px;
    font-weight: 600;
  }
  .fw-600 {
    font-weight: 600;
  }
  .w-100 {
    width: 100vw;
  }
  .w-0 {
    width: 0;
  }
  .br-06 {
    border-radius: 0.6rem;
  }
  .profile {
    margin: 20px auto;
    width: 150px;
    height: 150px;
    background-color: royalblue;
    position: relative;
    border-radius: 0.6rem;
  }
  .avatar {
    width: 35px;
    height: 35px;
    background: #f46e13;
    border-radius: 0.6rem;
    position: absolute;
    top: 115px;
    right: 0px;
    padding: 2px 0px 0px 7px;
  }
</style>