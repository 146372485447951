<template>
    <div v-if="show" class="modal-overlay">
        <div class="modal-container">
            <div class="modal-header">
                <h5>Mettre à jour le genre que vous désirez</h5>
                <button @click="closeModal">X</button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="updateGenderPref">
                    
                    <div class="mb-3">
                        <label for="gender" class="form-label">Genre</label>
                        <select v-model="form.gender_preference" class="form-control" id="gender_preference">
                            <option value="" disabled>--Sélectionner--</option>
                            <option value="MALE">Homme</option>
                            <option value="FEMALE">Femme</option>
                            <option value="All">Homme / Femme</option>
                        </select>
                    </div>
                    
                    <button type="submit" class="btn btn-primary">Mettre à jour</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import swal from 'sweetalert';
import { accountService } from '@/_services';

const props = defineProps({
    show: Boolean
});

const emit = defineEmits(['close']);

const form = ref({
    gender_preference: '',
});

const store = useStore();

const loadUserData = () => {
    const user = store.getters.getAppUser;
    form.value.gender_preference = user.gender_preference || '';
};

const updateGenderPref = async () => {
    try {
        const response = await accountService.updateUser(form.value);
        if (response.data.user) {
            store.commit('setAppUser', { ...form.value });
            await fetchUser();
            emit('close');
            swal({
                title: 'Succès',
                text: 'Genre mis à jour',
                icon: 'success'
            });
        }
    } catch (error) {
        console.error('Erreur lors de la mise à jour du genre:', error);
        swal({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la mise à jour du genre.',
            icon: 'error'
        });
    }
};

const fetchUser = async () => {
    try {
        const response = await accountService.getUserProfile();
        if (response.data.user) {
            store.commit('setAppUser', response.data.user);
        }
    } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
    }
};

const closeModal = () => {
    emit('close');
};

watch(props.show, (newValue) => {
    if (newValue) {
        loadUserData();
    }
});
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-width: 100%;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.modal-body {
    max-height: 70vh;
    overflow-y: auto;
}
</style>
