<script setup>
    import { useStore } from 'vuex';
    import { computed, ref, onMounted  } from 'vue';
    import { accountService } from '@/_services';

    import UpdateProfilView from '@/views/User/UpdateProfilView.vue';
    import UpateGenderView from '@/views/User/UpateGenderView.vue';
    import UpdateGPView from '@/views/User/UpdateGPView.vue';
    import UpdateDescriptionView from '@/views/User/UpdateDescriptionView.vue';
    import UpdateHoleNameView from '@/views/User/UpdateHoleNameView.vue';
    import UpdateBDView from '@/views/User/UpdateBDView.vue';
    import UpdateUNView from '@/views/User/UpdateUNView.vue';
    import UpdateUserProfileView from '@/views/User/UpdateUserProfileView.vue';

    const showProfilModal = ref(false);
    const showGenderModal = ref(false);
    const showGenderPrefModal = ref(false);
    const showDescriptionModal = ref(false);
    const showHoleNameModal = ref(false);
    const showBDModal = ref(false);
    const showUNModal = ref(false);
    const showUMModal = ref(false);

    const store = useStore();
    const user = computed(() => store.getters.getAppUser);

    // Définir la baseURL en utilisant VUE_APP_API_BASE_URL
    const baseURL = process.env.VUE_APP_API_BASE_URL.replace('/api', '');

    const holeName = () => {
        // return user.value.lastname + ' ' + user.value.firstname;
        return user.value.firstname + ' ' + user.value.lastname;
    };

    // Fonction pour construire l'URL de l'image
    const getImageUrl = (filePath) => {
        if (!filePath) return '';
        return `${baseURL}${filePath}`;
    };

    const profilePhotos = ref([]);
    //const profilePhotoUrl = computed(() => profilePhotos.value.length > 0 ? 'http://localhost:8000' + profilePhotos.value[profilePhotos.value.length - 1].file : null);

    const profilePhotoUrl = computed(() => {
    if (profilePhotos.value.length > 0) {
        const firstPhoto = profilePhotos.value[0];
        return getImageUrl(firstPhoto.file);
    }
    return null;
    });

    async function uploadProfilePhoto(event) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        try {
            const auth_token = accountService.getToken();
            console.log('Auth Token:', auth_token);

            if (!auth_token) {
                console.error('Token non trouvé.');
                return;
            }

            // Utiliser la méthode accountService.uploadProfilePhoto pour effectuer la requête
            const response = await accountService.uploadProfilePhoto(formData);

            if (response.status === 200) {
                // Ajouter la nouvelle photo à la liste des photos téléchargées
                profilePhotos.value.push(response.data.photo);

                // Parcourir toutes les photos et définir is_profile à false sauf pour la dernière photo ajoutée
                profilePhotos.value.forEach(photo => {
                    if (photo.id === response.data.photo.id) {
                        photo.is_profile = true;
                    } else {
                        photo.is_profile = false;
                    }
                });
            } else {
                throw new Error('Error uploading profile photo: ' + response.statusText);
            }
        } catch (error) {
            console.error('Error uploading profile photo:', error);
            if (error.response) {
                console.error('Détails de l\'erreur retournée par l\'API :', error.response.data);
            }
        }
    }

    const fetchUserPhotos = async () => {
        try {
            const auth_token = accountService.getToken();
            
            if (!auth_token) {
                console.error('Token non trouvé.');
                return;
            }

            // Utiliser la méthode accountService.fetchUserPhotos pour effectuer la requête
            const response = await accountService.fetchUserPhotos();

            const photos = response.data;

            profilePhotos.value = photos;
        } catch (error) {
            console.error('Erreur lors de la récupération des photos:', error);
        }
    };

    const age = computed(() => {
        if (user.value && user.value.born_on) {
            const born_on = new Date(user.value.born_on);
            
            const currentDate = new Date();

            const difference = currentDate.getTime() - born_on.getTime();

            const ageInYears = Math.floor(difference / (1000 * 60 * 60 * 24 * 365.25));
        
            return ageInYears;
        } else {
            return 'date de naissance non_defini';
        }
    });

    function getGenderLabel(value) {
        switch (value) {
            case 'MALE':
                return 'Homme';
            case 'FEMALE':
                return 'Femme';
            case 'All':
                return 'Homme / Femme';
            default:
                return value;
        }
    }

    function getUserProfile(value) {
        switch (value) {
            case 'DISCUSS':
                return 'Discussion';
            case 'MEET':
                return 'Rencontre';
            case 'SERIOUS_STORY':
                return 'Histoire sérieuse';
            default:
                return value;
        }
    }

    function truncateText(text, length) {
        if (!text) {
            return ''; // Retourne une chaîne vide si text est undefined ou null
        }
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + '...';
    }

    onMounted(fetchUserPhotos);
</script>

<template>
    <div class="pageheader">
        <img src="@/assets/images/bg-img/pageheader.jpg" class="bg_img" alt="Background Image">
        <div class="container">
            <div class="pageheader__content text-center">
                <h2>Bienvenue, {{ holeName() }}</h2>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class=""><router-link :class="{active : $route.name === 'home'}" :to="{name:'home'}">Accueil</router-link></li>
                        <li class="ms-3"> > </li>
                        <li class=""><router-link :class="{active : $route.name === 'dashboard'}" :to="{name:'dashboard'}"><span style="margin-left: 10px;">Tableau de bord</span></router-link></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="group group--single padding-bottom">
        <div class="group__top">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 d-none d-xl-block"></div>
                    <div class="col-xl-9">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="gt1-tab" data-bs-toggle="tab" data-bs-target="#gt1" type="button" role="tab" aria-controls="gt1" aria-selected="true"><i class="fa-solid fa-users"></i> Mon Profil </button>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <button class="nav-link" id="gt2-tab" data-bs-toggle="tab" data-bs-target="#gt2" type="button" role="tab" aria-controls="gt2" aria-selected="false"><i class="fa-solid fa-users"></i> Profil <span>30%</span></button>
                            </li> -->
                            <!-- <li class="nav-item" role="presentation">
                                <button class="nav-link" id="gt3-tab" data-bs-toggle="tab" data-bs-target="#gt3" type="button" role="tab" aria-controls="gt3" aria-selected="false"><i class="fa-solid fa-heart"></i> Likes <span>06</span></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="gt4-tab" data-bs-toggle="tab" data-bs-target="#gt4" type="button" role="tab" aria-controls="gt4" aria-selected="false"><i class="fa-solid fa-users"></i> Matchs <span>16</span></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="gt5-tab" data-bs-toggle="tab" data-bs-target="#gt5" type="button" role="tab" aria-controls="gt5" aria-selected="false"><i class="fa-solid fa-layer-group"></i> Groups <span>08</span></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="gt6-tab" data-bs-toggle="tab" data-bs-target="#gt6" type="button" role="tab" aria-controls="gt6" aria-selected="false"><i class="fa-solid fa-photo-film"></i> Media <span>06</span></button>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="group__bottom">
            <div class="container">
                <div class="row g-4">
                    <div class="col-xl-9 order-xl-1">
                        <div class="group__bottom--left">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="gt1" role="tabpanel" aria-labelledby="gt1-tab">
                                    <!-- <div class="group__bottom--head group__bottom--activity bg-white mb-4 border-0">
                                        <ul class="nav nav-tabs" id="myTab2" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active" id="Personal-tab" data-bs-toggle="tab" data-bs-target="#Personal" type="button" role="tab" aria-controls="Personal" aria-selected="true">Personnel</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="Mentions-tab" data-bs-toggle="tab" data-bs-target="#Mentions" type="button" role="tab" aria-controls="Mentions" aria-selected="false">Mentionné</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="Favorites-tab" data-bs-toggle="tab" data-bs-target="#Favorites" type="button" role="tab" aria-controls="Favorites" aria-selected="false">Favoris</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="Friends-tab" data-bs-toggle="tab" data-bs-target="#Friends" type="button" role="tab" aria-controls="Friends" aria-selected="false">Ami(e)s</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="Groups-tab" data-bs-toggle="tab" data-bs-target="#Groups" type="button" role="tab" aria-controls="Groups" aria-selected="false">Groupes</button>
                                            </li>
                                        </ul>
                                    </div> -->

                                    <div class="info">
                                        <div class="info-card mb-4">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6 class="mb-0">Informations de base</h6>
                                                <!-- <a @click="showProfilModal = true" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</a> -->
                                            </div>
                                            
                                            <div class="info-card-content">
                                                <p class="note animate__animated animate__bounceIn mb-4">
                                                    <i class="fas fa-exclamation-triangle"></i>
                                                    Vous devez définir votre genre, le genre des personnes que vous recherchez ainsi que le type de relation pour être visible aux autres utilisateurs. 
                                                    Pour se faire, cliquez sur modifier pour mettre à jour vos informations.
                                                </p>

                                                <ul class="info-list">
                                                    <li>
                                                        <p class="info-name">Nom & prénom</p>
                                                        <div class="d-flex justify-content-between align-items-center info-details">
                                                            <span class="fw-bold" v-if="holeName()">
                                                                {{ holeName() }}
                                                            </span>
                                                            <span v-else>
                                                                <i>Non défini</i>
                                                            </span>
                                                            <a @click="showHoleNameModal = true" class="text">
                                                                <i class="fa-solid fa-pen-to-square small"></i> <span class="small">Modifier</span> 
                                                            </a>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <p class="info-name">Nom d'utilisateur</p>
                                                        <div class="d-flex justify-content-between align-items-center info-details">
                                                            <span class="fw-bold" v-if="user.username">
                                                                {{ user.username }}
                                                            </span>
                                                            <span v-else class="text-danger">
                                                                <i>Non défini</i>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a @click="showUNModal = true" class="text">
                                                                <i class="fa-solid fa-pen-to-square small"></i> <span class="small">Modifier</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Je suis un(e)</p>
                                                        <div class="d-flex justify-content-between align-items-center info-details">
                                                            <span class="fw-bold" v-if="getGenderLabel(user.gender)">
                                                                {{ getGenderLabel(user.gender) }}
                                                            </span>
                                                            <span v-else class="text-danger">
                                                                <i>Non défini</i>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a @click="showGenderModal = true" class="text">
                                                                <i class="fa-solid fa-pen-to-square small"></i> <span class="small">Modifier</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">A la recherche d'un(e)</p>
                                                        <div class="d-flex justify-content-between align-items-center info-details">
                                                            <span class="fw-bold" v-if="getGenderLabel(user.gender_preference)">
                                                                {{ getGenderLabel(user.gender_preference) }}
                                                            </span>
                                                            <span v-else class="text-danger">
                                                                <i>Non défini</i>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a @click="showGenderPrefModal = true" class="text">
                                                                <i class="fa-solid fa-pen-to-square small"></i> <span class="small">Modifier</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Pour une</p>
                                                        <div class="d-flex justify-content-between align-items-center info-details">
                                                            <span class="fw-bold" v-if="getUserProfile(user.profile)">
                                                                {{ getUserProfile(user.profile) }}
                                                            </span>
                                                            <span v-else class="text-danger">
                                                                <i>Non défini</i>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a @click="showUMModal = true" class="text">
                                                                <i class="fa-solid fa-pen-to-square small"></i> <span class="small">Modifier</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Date de naissance</p>
                                                        <div class="d-flex justify-content-between align-items-center info-details">
                                                            <span class="fw-bold" v-if="user.born_on">
                                                                {{ user.born_on }} ({{ age }} ans)
                                                            </span>
                                                            <span v-else class="text-danger">
                                                                <i>Non défini</i>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a @click="showBDModal = true" class="text">
                                                                <i class="fa-solid fa-pen-to-square small"></i> <span class="small">Modifier</span>
                                                            </a>
                                                        </div>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div class="info-card mb-4">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6>Ma description</h6>
                                                <!-- <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link> -->
                                                <a @click="showDescriptionModal = true" class="text">
                                                    <i class="fa-solid fa-pen-to-square small"></i> <span class="small">Modifier</span>
                                                </a>
                                            </div>
                                            <div class="info-card-content">
                                                <span v-if="user.description">
                                                    {{ user.description }}
                                                </span>
                                                <span v-else>
                                                    <i>Non défini</i>
                                                </span>
                                            </div>
                                        </div>

                                        <!-- <div class="info-card mb-4">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6>Ce que je recherche</h6>
                                                <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link>
                                            </div>
                                            <div class="info-card-content">
                                                <ul class="info-list">
                                                    <li>
                                                        <p class="info-name">Je suis à la recherche d'une</p>
                                                        <p class="info-details">Personne drôle</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Tout ce que j'aime</p>
                                                        <p class="info-details">J'aime beaucoup voyager</p>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div> -->

                                        <!-- <div class="info-card mb-4">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6>Mode de vie</h6>
                                                <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link>
                                            </div>
                                            <div class="info-card-content">
                                                <ul class="info-list">
                                                    <li>
                                                        <p class="info-name">Centres d'Intérêt</p>
                                                        <p class="info-details">Chiens, Chats</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Endroits favoris</p>
                                                        <p class="info-details">Maldives, Bangladesh</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">À la recherche de</p>
                                                        <p class="info-details">Relation sérieuse, affaires</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Fumeur</p>
                                                        <p class="info-details">Fumeur occasionnel</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Langues</p>
                                                        <p class="info-details">Anglais, Français, Italien</p>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div> -->

                                        <!-- <div class="info-card">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6>Détails sur le physique</h6>
                                                <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link>
                                            </div>
                                            <div class="info-card-content">
                                                <ul class="info-list">
                                                    <li>
                                                        <p class="info-name">Taille</p>
                                                        <p class="info-details">172,72 cm</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Poids</p>
                                                        <p class="info-details">72 kg</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Couleur des cheveux</p>
                                                        <p class="info-details">Noir</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Couleur des yeux</p>
                                                        <p class="info-details">Noisette</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Silhouette</p>
                                                        <p class="info-details">Elancé</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Ethnie</p>
                                                        <p class="info-details">Africain</p>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div> -->
                                    </div>
                                    
                                    <!-- <div class="group__bottom--area group__bottom--memberactivity">
                                        <div class="group__bottom--body">
                                            <div class="group__bottom--allmedia">
                                                <div class="media-wrapper">
                                                    <div class="tab-content" id="myTabContent2">
                                                        <div class="tab-pane fade show active" id="Personal"
                                                            role="tabpanel" aria-labelledby="Personal-tab">
                                                            <div class="create-post mb-4">
                                                                <div class="lab-inner">
                                                                    <div class="lab-thumb">
                                                                        <div class="thumb-inner">
                                                                            <div class="thumb-img">
                                                                                <img src="@/assets/images/member/profile/profile.jpg" alt="datting thumb">
                                                                                <img v-if="profilePhotos.length > 0" :src="'http://localhost:8000' + profilePhotos[0].file" alt="Photo de profil">
                                                                                <img v-if="profilePhotos.length > 0" :src="profilePhotoUrl" alt="Photo de profil">
                                                                                <img v-else src="@/assets/images/member/profile/default_profile.png">
                                                                            </div>
                                                                            <div class="thumb-content">
                                                                                <h6><a href="#"> {{ holeName() }}</a></h6>
                                                                                <div class="custom-select">
                                                                                    <select>
                                                                                        <option value="1"> Public</option>
                                                                                        <option value="2"> Privé</option>
                                                                                        <option value="3"> Ami(e)s</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="lab-content">
                                                                        <form action="#" class="post-form">
                                                                            <input type="text" placeholder="Whats on your mind. William?">
                                                                            <div class="content-type">
                                                                                <ul class="content-list">
                                                                                    <li class="text"><a href="#"><i class="fa-solid fa-pen-to-square"></i>Texte </a></li>
                                                                                    <li class="image-video">
                                                                                        <div class="file-btn"><i class="fa-solid fa-camera"></i>Photos/Videos</div>
                                                                                        <input type="file">
                                                                                    </li>
                                                                                    <li class="attach-file">
                                                                                        <div class="file-btn"><i class="fa-solid fa-link"></i>Pièce jointe</div>
                                                                                        <input type="file">
                                                                                    </li>
                                                                                    <li class="post-submit">
                                                                                        <input type="submit" value="Post" class="default-btn">
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="post-item mb-4">
                                                                <div class="post-content">
                                                                    <div class="post-author">
                                                                        <div class="post-author-inner">
                                                                            <div class="author-thumb">
                                                                                <img src="@/assets/images/member/profile/profile.jpg" alt="datting thumb">
                                                                                <img v-if="profilePhotos.length > 0" :src="profilePhotoUrl" alt="Photo de profil">
                                                                                <img v-else src="@/assets/images/member/profile/default_profile.png">
                                                                            </div>
                                                                            <div class="author-details">
                                                                                <h6><a href="#">{{ holeName() }}</a></h6>
                                                                                <ul class="post-status">
                                                                                    <li class="post-privacy"><i class="icofont-world"></i> Public</li>
                                                                                    <li class="post-time">Il y a 6 minutes</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div class="post-description">
                                                                        <p>Fournir rapidement des informations sur les méthodes futures, créer une autonomisation avant avec une bande passante centrée sur le client Pontifier de manière crédible des compétences de leadership interopérables et des données B2B géniales Un tableau blanc en permanence et des données B2B géniales Un tableau blanc en permanence</p>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="post-meta">
                                                                    <div class="post-meta-top">
                                                                        <p><a href="#"><i class="fa-solid fa-thumbs-up"></i> <i class="fa-solid fa-heart"></i> <i class="fa-solid fa-face-laugh"></i> <span>Julia, Petrova et 306 ont aimé</span></a></p>
                                                                        <p><a href="#">136 commentaires</a></p>
                                                                    </div>
                                                                    <div class="post-meta-bottom">
                                                                        <ul class="react-list">
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-thumbs-up"></i>Like</a> </li>
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-comment"></i>commentaire</a></li>
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-share-nodes"></i>Partager</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="post-item mb-4">
                                                                <div class="post-content">
                                                                    <div class="post-author">
                                                                        <div class="post-author-inner">
                                                                            <div class="author-thumb">
                                                                                <img src="@/assets/images/member/profile/profile.jpg" alt="datting thumb">
                                                                                <img v-if="profilePhotos.length > 0" :src="profilePhotoUrl" alt="Photo de profil">
                                                                                <img v-else src="@/assets/images/member/profile/default_profile.png">
                                                                            </div>
                                                                            <div class="author-details">
                                                                                <h6><a href="#">{{ holeName() }}</a></h6>
                                                                                <ul class="post-status">
                                                                                    <li class="post-privacy"><i class="icofont-world"></i> Public</li>
                                                                                    <li class="post-time">Il y a 6 minutes </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div class="post-description">
                                                                        <p>Fournir rapidement des informations sur les méthodes futures, créer une autonomisation avant avec une bande passante centrée sur le client Pontifier de manière crédible des compétences de leadership interopérables et des données B2B géniales Un tableau blanc en permanence et des données B2B géniales Un tableau blanc en permanence</p>
                                                                        <div class="post-desc-img">
                                                                            <img src="@/assets/images/group/single/01.jpg" alt="dating thumb">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="post-meta">
                                                                    <div class="post-meta-top">
                                                                        <p><a href="#"><i class="fa-solid fa-thumbs-up"></i> <i class="fa-solid fa-heart"></i> <i class="fa-solid fa-face-laugh"></i><span>Julia, Petrova et 306 ont aimé</span></a></p>
                                                                        <p><a href="#">136 commentaires</a></p>
                                                                    </div>
                                                                    <div class="post-meta-bottom">
                                                                        <ul class="react-list">
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-thumbs-up"></i>Like</a> </li>
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-comment"></i>Comment</a></li>
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-share-nodes"></i> Share </a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="post-item mb-4">
                                                                <div class="post-content">
                                                                    <div class="post-author">
                                                                        <div class="post-author-inner">
                                                                            <div class="author-thumb">
                                                                                <img src="@/assets/images/member/profile/profile.jpg" alt="datting thumb">
                                                                                <img v-if="profilePhotos.length > 0" :src="profilePhotoUrl" alt="Photo de profil">
                                                                                <img v-else src="@/assets/images/member/profile/default_profile.png">
                                                                            </div>
                                                                            <div class="author-details">
                                                                                <h6><a href="#">{{ holeName() }}</a></h6>
                                                                                <ul class="post-status">
                                                                                    <li class="post-privacy"><i class="icofont-world"></i> Public</li>
                                                                                    <li class="post-time">Il y a 6 minutes </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div class="post-description">
                                                                        <p>Fournir rapidement des informations sur les méthodes futures, créer une autonomisation avant avec une bande passante centrée sur le client Pontifier de manière crédible des compétences de leadership interopérables et des données B2B géniales Un tableau blanc en permanence et des données B2B géniales Un tableau blanc en permanence</p>
                                                                        <div class="post-desc-img">
                                                                            <div class="row g-3">
                                                                                <div class="col-md-6">
                                                                                    <img src="@/assets/images/group/single/01.jpg" alt="dating thumb">
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <img src="@/assets/images/group/single/02.jpg" alt="dating thumb">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="post-meta">
                                                                    <div class="post-meta-top">
                                                                        <p><a href="#"><i class="fa-solid fa-thumbs-up"></i> <i class="fa-solid fa-heart"></i> <i class="fa-solid fa-face-laugh"></i><span>Julia, Petrova et 306 ont aimé</span></a></p>
                                                                        <p><a href="#">136 commentaires</a></p>
                                                                    </div>
                                                                    <div class="post-meta-bottom">
                                                                        <ul class="react-list">
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-thumbs-up"></i>Like</a> </li>
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-comment"></i>Commentaire</a></li>
                                                                            <li class="react"><a href="#"><i class="fa-solid fa-share-nodes"></i> Partager </a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="text-center mt-4">
                                                                <a href="#" class="default-btn"><span>Plus de posts</span></a>
                                                            </div>
                                                        </div>
    
                                                        <div class="tab-pane fade" id="Mentions" role="tabpanel" aria-labelledby="Mentions-tab">
                                                            <div class="post-item">
                                                                <div class="post-content">
                                                                    <div class="post-author pb-3">
                                                                        <div class="post-author-inner">
                                                                            <div class="author-thumb">
                                                                                <img src="@/assets/images/member/home2/01.jpg" alt="datting thumb">
                                                                            </div>
                                                                            <div class="author-details">
                                                                                <h6><a href="#">David Baecker</a></h6>
                                                                                <ul class="post-status">
                                                                                    <li class="post-privacy"><i class="icofont-world"></i> Public</li>
                                                                                    <li class="post-time">Il y a 6 minutes</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div class="post-description ps-5">
                                                                        <p class="ms-5 ps-2">Hello @<a href="#">Sara Hartmann</a></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="tab-pane fade" id="Favorites" role="tabpanel" aria-labelledby="Favorites-tab">
                                                            <div class="post-item mb-4">
                                                                <div class="post-content p-4">
                                                                    <p class="mb-0">Désolé, Aucune activité n'a été trouvée. Veuillez essayer un autre filtre.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="tab-pane fade" id="Friends" role="tabpanel" aria-labelledby="Friends-tab">
                                                            <div class="activity__item mb-3">
                                                                <div class="activity__inner bg-white">
                                                                    <div class="activity__thumb">
                                                                        <a href="member-single.html"><img src="@/assets/images/member/home2/01.jpg" alt="dating thumb"></a>
                                                                    </div>
                                                                    <div class="activity__content">
                                                                        <h5><a href="member-single.html">Daniel Becker </a><span>est devenu ami avec <a href="#">Angelika Schaefer</a></span></h5>
                                                                        <p>il y a 2 jours</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="activity__item mb-3">
                                                                <div class="activity__inner bg-white">
                                                                    <div class="activity__thumb">
                                                                        <a href="member-single.html"><img src="@/assets/images/member/home2/03.jpg" alt="dating thumb"></a>
                                                                    </div>
                                                                    <div class="activity__content">
                                                                        <h5><a href="member-single.html">Thorsten Schwab </a><span>est devenu ami avec <a href="#">David Kirsch</a></span></h5>
                                                                        <p>il y a 2 jours</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="activity__item mb-3">
                                                                <div class="activity__inner bg-white">
                                                                    <div class="activity__thumb">
                                                                        <a href="member-single.html"><img src="@/assets/images/member/home2/04.jpg" alt="dating thumb"></a>
                                                                    </div>
                                                                    <div class="activity__content">
                                                                        <h5><a href="member-single.html">Leah Friedman </a><span>est devenu ami avec <a href="#">Manuela Lehrer</a></span></h5>
                                                                        <p>il y a 2 jours</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="activity__item mb-3">
                                                                <div class="activity__inner bg-white">
                                                                    <div class="activity__thumb">
                                                                        <a href="member-single.html"><img src="@/assets/images/member/home2/05.jpg" alt="dating thumb"></a>
                                                                    </div>
                                                                    <div class="activity__content">
                                                                        <h5><a href="member-single.html">Maximilian </a><span>est devenu ami avec <a href="#">Franziska Pfeiffer</a></span></h5>
                                                                        <p>il y a 2 jours</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="activity__item">
                                                                <div class="activity__inner bg-white">
                                                                    <div class="activity__thumb">
                                                                        <a href="member-single.html"><img src="@/assets/images/member/home2/06.jpg" alt="dating thumb"></a>
                                                                    </div>
                                                                    <div class="activity__content">
                                                                        <h5><a href="member-single.html">Michelle Foerster </a><span>est devenu ami avec <a href="#">Ines Dresdner</a></span></h5>
                                                                        <p>il y a 2 jours</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="tab-pane fade" id="Groups" role="tabpanel" aria-labelledby="Groups-tab">
                                                            <div class="activity__item mb-3">
                                                                <div class="activity__inner bg-white">
                                                                    <div class="activity__thumb">
                                                                        <a href="member-single.html"><img src="@/assets/images/member/home2/01.jpg" alt="dating thumb"></a>
                                                                    </div>
                                                                    <div class="activity__content">
                                                                        <h5><a href="member-single.html">Daniel Becker </a><span>a rejoint le groupe</span></h5>
                                                                        <p>il y a 28 jours</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="activity__item mb-3">
                                                                <div class="activity__inner bg-white">
                                                                    <div class="activity__thumb">
                                                                        <a href="member-single.html"><img src="@/assets/images/member/home2/02.jpg" alt="dating thumb"></a>
                                                                    </div>
                                                                    <div class="activity__content">
                                                                        <h5><a href="member-single.html">Simone Wulf </a><span>a rejoint le groupe</span></h5>
                                                                        <p>1il y a 2 jours</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    
                                </div>

                                <!-- <div class="tab-pane fade" id="gt2" role="tabpanel" aria-labelledby="gt2-tab">
                                    <div class="info">
                                        <div class="info-card mb-4">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6 class="mb-0">Informations de base</h6>
                                                <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link>
                                            </div>

                                            <div class="info-card-content">
                                                <ul class="info-list">
                                                   <li>
                                                        <p class="info-name">Nom & prénom</p>
                                                        <p v-if="holeName()" class="info-details">{{ holeName() }}</p>
                                                        <p v-else class="info-details">Non defini</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Nom</p>
                                                        <p class="info-details">{{ user.first_name }}</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Prénom</p>
                                                        <p class="info-details">{{ user.last_name }}</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Nom d'utilisateur</p>
                                                        <p class="info-details">{{ user.username }}</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Email</p>
                                                        <p class="info-details">{{ user.email }}</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">je suis un(e)</p>
                                                        <p v-if="getGenderLabel(user.gender)" class="info-details">{{ getGenderLabel(user.gender) }}</p>
                                                        <p v-else class="info-details">Non defini</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">A la recherche d'un(e)</p>
                                                        <p v-if="getGenderLabel(user.gender_preference)" class="info-details">{{ getGenderLabel(user.gender_preference) }}</p>
                                                        <p v-else class="info-details">Non defini</p>

                                                    </li>
                                                    <li>
                                                        <p class="info-name">Age</p>
                                                        <p class="info-details">{{ age }}</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Date de naissance</p>
                                                        <p class="info-details">{{ user.born_on }}</p>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div class="info-card mb-4">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6>Ma description</h6>
                                                <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link>
                                            </div>
                                            <div class="info-card-content">
                                                {{ user.description }}
                                            </div>
                                        </div>

                                        <div class="info-card mb-4">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6>Ce que je recherche</h6>
                                                <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link>
                                            </div>
                                            <div class="info-card-content">
                                                <ul class="info-list">
                                                    <li>
                                                        <p class="info-name">Je suis à la recherche d'une</p>
                                                        <p class="info-details">Personne drôle</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Tout ce que j'aime</p>
                                                        <p class="info-details">J'aime beaucoup voyager</p>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>

                                        <div class="info-card mb-4">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6>Mode de vie</h6>
                                                <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link>
                                            </div>
                                            <div class="info-card-content">
                                                <ul class="info-list">
                                                    <li>
                                                        <p class="info-name">Centres d'Intérêt</p>
                                                        <p class="info-details">Chiens, Chats</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Endroits favoris</p>
                                                        <p class="info-details">Maldives, Bangladesh</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">À la recherche de</p>
                                                        <p class="info-details">Relation sérieuse, affaires</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Fumeur</p>
                                                        <p class="info-details">Fumeur occasionnel</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Langues</p>
                                                        <p class="info-details">Anglais, Français, Italien</p>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>

                                        <div class="info-card">
                                            <div class="info-card-title d-flex justify-content-between align-items-center">
                                                <h6>Détails sur le physique</h6>
                                                <router-link :to="{name:'update_profil'}" class="text"><i class="fa-solid fa-pen-to-square"></i> Modifier</router-link>
                                            </div>
                                            <div class="info-card-content">
                                                <ul class="info-list">
                                                    <li>
                                                        <p class="info-name">Taille</p>
                                                        <p class="info-details">172,72 cm</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Poids</p>
                                                        <p class="info-details">72 kg</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Couleur des cheveux</p>
                                                        <p class="info-details">Noir</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Couleur des yeux</p>
                                                        <p class="info-details">Noisette</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Silhouette</p>
                                                        <p class="info-details">Elancé</p>
                                                    </li>
                                                    <li>
                                                        <p class="info-name">Ethnie</p>
                                                        <p class="info-details">Africain</p>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="tab-pane fade" id="gt3" role="tabpanel" aria-labelledby="gt3-tab">
                                    <div class="site">
                                        <div class="info-card mb-4">
                                            <div class="info-card-title">
                                                <h6>Ma liste de likes</h6>
                                            </div>
                                            <div class="info-card-content">
                                                <ul class="info-list">
                                                    <li>
                                                        <div class="info-details">
                                                            <div class="id-left">
                                                                <img src="@/assets/images/site/01.png" alt="dating thumb">
                                                            </div>
                                                            <div class="id-right">
                                                                <a href="#" target="_blank"><h5>Ollya Dating</h5></a>
                                                                <p>Active il y a 2 jours</p>
                                                            </div>
                                                        </div>
                                                        <a href="#" class="default-btn" target="_blank"><span>Matcher</span></a>
                                                    </li>
                                                    <li>
                                                        <div class="info-details">
                                                            <div class="id-left">
                                                                <img src="@/assets/images/site/02.png" alt="dating thumb">
                                                            </div>
                                                            <div class="id-right">
                                                                <a href="#" target="_blank"><h5>Ollya</h5></a>
                                                                <p>Active il ya 3h</p>
                                                            </div>
                                                        </div>
                                                        <a href="#" class="default-btn" target="_blank"><span>Matcher</span></a>
                                                    </li>
                                                    <li>
                                                        <div class="info-details">
                                                            <div class="id-left">
                                                                <img src="@/assets/images/site/03.png" alt="dating thumb">
                                                            </div>
                                                            <div class="id-right">
                                                                <a href="#" target="_blank"><h5>Ollya Dating</h5></a>
                                                                <p>Active il y a 2 jours</p>
                                                            </div>
                                                        </div>
                                                        <a href="#" class="default-btn" target="_blank"><span>Matcher</span></a>
                                                    </li>
                                                    <li>
                                                        <div class="info-details">
                                                            <div class="id-left">
                                                                <img src="@/assets/images/site/04.png" alt="dating thumb">
                                                            </div>
                                                            <div class="id-right">
                                                                <a href="#" target="_blank"><h5>Ollya</h5></a>
                                                                <p>Active il ya 3h</p>
                                                            </div>
                                                        </div>
                                                        <a href="#" class="default-btn" target="_blank"><span>Matcher</span></a>
                                                    </li>
                                                    <li>
                                                        <div class="info-details">
                                                            <div class="id-left">
                                                                <img src="@/assets/images/site/05.png" alt="dating thumb">
                                                            </div>
                                                            <div class="id-right">
                                                                <a href="#" target="_blank"><h5>Ollya Dating</h5></a>
                                                                <p>Active il y a 2 jours</p>
                                                            </div>
                                                        </div>
                                                        <a href="#" class="default-btn" target="_blank"><span>Matcher</span></a>
                                                    </li>
                                                    <li>
                                                        <div class="info-details">
                                                            <div class="id-left">
                                                                <img src="@/assets/images/site/06.png" alt="dating thumb">
                                                            </div>
                                                            <div class="id-right">
                                                                <a href="#" target="_blank"><h5>Ollya</h5></a>
                                                                <p>Active il ya 3h</p>
                                                            </div>
                                                        </div>
                                                        <a href="#" class="default-btn" target="_blank"><span>Matcher</span></a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="info-card-title info-card-pagination">
                                                <p>Affichage 1 - 2 of 2 sites</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="tab-pane fade" id="gt4" role="tabpanel" aria-labelledby="gt4-tab">
                                    <div class="group__bottom--area">
                                        <div class="group__bottom--head">
                                            <div class="left">
                                                <form action="#">
                                                    <input type="text" name="search" placeholder="Recherher">
                                                    <button type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
                                                </form>
                                            </div>
                                            <div class="right">
                                                Trier par:
                                                <div class="banner__inputlist">
                                                    <select>
                                                        <option selected="">Nouveau</option>
                                                        <option value="">Populaire</option>
                                                        <option value="">Ancien</option>
                                                        <option value="">Récent</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group__bottom--body">
                                            <div class="member">
                                                <div class="row g-3 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/profile/profile.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Michele Storm</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/male/02.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Hanna Marcovick</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-venus"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/male/03.jpg" alt="member-img">
                                                                    <span class="member__activity member__activity--ofline">il y a 2 jours</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Smith Jhonson</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/female/01.jpg" alt="member-img">
                                                                    <span class="member__activity member__activity--ofline">32 Minites Ago</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Brady Patterson</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/male/04.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Peter Shull</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-venus"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/female/02.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Alesandra Cirilo</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/female/03.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Diana Kuhn</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/male/05.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Mathias Maur</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-venus"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/female/04.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Maria Glockner</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/female/05.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Nadine Lange</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/male/06.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Marko Mayer</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-venus"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/female/06.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Sophia Bader</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/female/07.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Maria Braun</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/male/08.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Leon Baader</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-venus"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="member__item w-100">
                                                            <div class="member__inner m-0">
                                                                <div class="member__thumb">
                                                                    <img src="@/assets/images/member/female/08.jpg" alt="member-img">
                                                                    <span class="member__activity">En ligne</span>
                                                                </div>
                                                                <div class="member__content">
                                                                    <a href="member-single.html"><h6>Janina Baer</h6></a>
                                                                    <p>32 ans <i class="fa-solid fa-mars"></i></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center mt-5">
                                                <a href="#" class="default-btn"><span><i class="fa-solid fa-spinner"></i> Voir plus</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="tab-pane fade" id="gt5" role="tabpanel" aria-labelledby="gt5-tab">
                                    <div class="group__bottom--area">
                                        <div class="group__bottom--head">
                                            <div class="left">
                                                <form action="#">
                                                    <input type="text" name="search" placeholder="search" class="">
                                                    <button type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
                                                </form>
                                            </div>
                                            <div class="right">
                                              Trier par:
                                                <div class="banner__inputlist">
                                                    <select>
                                                        <option selected="">Nouveau</option>
                                                        <option value="">Populaire</option>
                                                        <option value="">Ancien</option>
                                                        <option value="">Récent</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group__bottom--body bg-white">
                                            <div class="group__bottom--group">
                                                <div class="row g-3 justify-content-center mx-12-none row-cols-lg-2 row-cols-sm-2 row-cols-1">
                                                    <div class="col">
                                                        <div class="story__item style2">
                                                            <div class="story__inner">
                                                                <div class="story__thumb position-relative">
                                                                    <a href="group-single.html"><img src="@/assets/images/group/01.jpg" alt="dating thumb"></a>
                                                                    <span class="member__activity member__activity--ofline">il y a 2 jours</span>
                                                                </div>
                                                                <div class="story__content px-0 pb-0">
                                                                    <a href="group-single.html"><h4>Be young, love life</h4></a>
                                                                    <p>You become what you believe. –Oprah Winfrey</p>
                                                                    <div class="story__content--author justify-content-between border-top pt-3">
                                                                        <div class="story__content--content mb-2">
                                                                            <p><i class="fa-solid fa-earth-africa"></i> Public</p>
                                                                        </div>
                                                                        <ul class="img-stack">
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/01.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Klaus Klug</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/02.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Mary C. Shen</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/03.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Lucas Vogler</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/04.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Manuela Moench</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/05.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Matthias Propst</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bg-theme">
                                                                                <a href="#">
                                                                                    12+
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Plus</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="story__item style2 story--theme-color2">
                                                            <div class="story__inner">
                                                                <div class="story__thumb position-relative">
                                                                    <a href="group-single.html"><img src="@/assets/images/group/02.jpg" alt="dating thumb"></a>
                                                                    <span class="member__activity member__activity--ofline">4 days ago</span>
                                                                </div>
                                                                <div class="story__content px-0 pb-0">
                                                                    <a href="group-single.html"><h4>Learn how to love</h4></a>
                                                                    <p>Fall seven times and stand up eight. –Japanese Proverb</p>
                                                                    <div class="story__content--author justify-content-between border-top pt-3">
                                                                        <div class="story__content--content mb-2">
                                                                            <p><i class="fa-solid fa-earth-africa"></i> Public</p>
                                                                        </div>
                                                                        <ul class="img-stack">
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/06.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Klaus Klug</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/07.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Mary C. Shen</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/08.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Lucas Vogler</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/09.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Manuela Moench</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/10.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Matthias Propst</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bg-theme">
                                                                                <a href="#">
                                                                                    12+
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">More</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="story__item style2 story--theme-color3">
                                                            <div class="story__inner">
                                                                <div class="story__thumb position-relative">
                                                                    <a href="group-single.html"><img src="@/assets/images/group/03.jpg" alt="dating thumb"></a>
                                                                    <span class="member__activity member__activity--ofline">8 days ago</span>
                                                                </div>
                                                                <div class="story__content px-0 pb-0">
                                                                    <a href="group-single.html"><h4>Love, health and...</h4></a>
                                                                    <p>Definiteness of purpose is the starting point of all</p>
                                                                    <div class="story__content--author justify-content-between border-top pt-3">
                                                                        <div class="story__content--content mb-2">
                                                                            <p><i class="fa-solid fa-earth-africa"></i> Public</p>
                                                                        </div>
                                                                        <ul class="img-stack">
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/11.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Klaus Klug</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/12.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Mary C. Shen</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/13.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Lucas Vogler</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/14.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Manuela Moench</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/15.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Matthias Propst</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bg-theme">
                                                                                <a href="#">
                                                                                    12+
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">More</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="story__item style2 story--theme-color4">
                                                            <div class="story__inner">
                                                                <div class="story__thumb position-relative">
                                                                    <a href="group-single.html"><img src="@/assets/images/group/04.jpg" alt="dating thumb"></a>
                                                                    <span class="member__activity member__activity--ofline">1il y a 2 jours</span>
                                                                </div>
                                                                <div class="story__content px-0 pb-0">
                                                                    <a href="group-single.html"><h4>Ollya Dating Theme...</h4></a>
                                                                    <p> will most likely be using this theme really nice dating theme for</p>
                                                                    <div class="story__content--author justify-content-between border-top pt-3">
                                                                        <div class="story__content--content mb-2">
                                                                            <p><i class="fa-solid fa-earth-africa"></i> Public</p>
                                                                        </div>
                                                                        <ul class="img-stack">
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/01.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Klaus Klug</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/06.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Mary C. Shen</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/03.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Lucas Vogler</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/07.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Manuela Moench</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/02.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Matthias Propst</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bg-theme">
                                                                                <a href="#">
                                                                                    12+
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">More</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="story__item style2 story--theme-color5">
                                                            <div class="story__inner">
                                                                <div class="story__thumb position-relative">
                                                                    <a href="group-single.html"><img src="@/assets/images/group/05.jpg" alt="dating thumb"></a>
                                                                    <span class="member__activity member__activity--ofline">18 days ago</span>
                                                                </div>
                                                                <div class="story__content px-0 pb-0">
                                                                    <a href="group-single.html"><h4>Skip relation routine</h4></a>
                                                                    <p>Twenty years from now you will be more disappointed</p>
                                                                    <div class="story__content--author justify-content-between border-top pt-3">
                                                                        <div class="story__content--content mb-2">
                                                                            <p><i class="fa-solid fa-earth-africa"></i> Public</p>
                                                                        </div>
                                                                        <ul class="img-stack">
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/11.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Klaus Klug</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/15.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Mary C. Shen</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/03.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Lucas Vogler</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/09.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Manuela Moench</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/07.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Matthias Propst</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bg-theme">
                                                                                <a href="#">
                                                                                    12+
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">More</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="story__item style2 story--theme-color6">
                                                            <div class="story__inner">
                                                                <div class="story__thumb position-relative">
                                                                    <a href="group-single.html"><img src="@/assets/images/group/06.jpg" alt="dating thumb"></a>
                                                                    <span class="member__activity member__activity--ofline">21 days ago</span>
                                                                </div>
                                                                <div class="story__content px-0 pb-0">
                                                                    <a href="group-single.html"><h4>Gay Community</h4></a>
                                                                    <p>It’s your place in the world; it’s your life. </p>
                                                                    <div class="story__content--author justify-content-between border-top pt-3">
                                                                        <div class="story__content--content mb-2">
                                                                            <p><i class="fa-solid fa-earth-africa"></i> Public</p>
                                                                        </div>
                                                                        <ul class="img-stack">
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/15.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Klaus Klug</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/12.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Mary C. Shen</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/10.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Lucas Vogler</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/01.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Manuela Moench</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/08.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Matthias Propst</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bg-theme">
                                                                                <a href="#">
                                                                                    12+
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">More</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="story__item style2 story--theme-color7">
                                                            <div class="story__inner">
                                                                <div class="story__thumb position-relative">
                                                                    <a href="group-single.html"><img src="@/assets/images/group/07.jpg" alt="dating thumb"></a>
                                                                    <span class="member__activity member__activity--ofline">24 days ago</span>
                                                                </div>
                                                                <div class="story__content px-0 pb-0">
                                                                    <a href="group-single.html"><h4>Only Buddies</h4></a>
                                                                    <p>You miss 100% of the shots you don’t take.</p>
                                                                    <div class="story__content--author justify-content-between border-top pt-3">
                                                                        <div class="story__content--content mb-2">
                                                                            <p><i class="fa-solid fa-earth-africa"></i> Public</p>
                                                                        </div>
                                                                        <ul class="img-stack">
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/15.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Klaus Klug</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/02.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Mary C. Shen</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/09.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Lucas Vogler</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/04.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Manuela Moench</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/08.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Matthias Propst</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bg-theme">
                                                                                <a href="#">
                                                                                    12+
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">More</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="story__item style2 story--theme-color8">
                                                            <div class="story__inner">
                                                                <div class="story__thumb position-relative">
                                                                    <a href="group-single.html"><img src="@/assets/images/group/08.jpg" alt="dating thumb"></a>
                                                                    <span class="member__activity member__activity--ofline">30 days ago</span>
                                                                </div>
                                                                <div class="story__content px-0 pb-0">
                                                                    <a href="group-single.html"><h4>Love Stories</h4></a>
                                                                    <p>You become what you believe. –Oprah Winfrey</p>
                                                                    <div class="story__content--author justify-content-between border-top pt-3">
                                                                        <div class="story__content--content mb-2">
                                                                            <p><i class="fa-solid fa-earth-africa"></i> Public</p>
                                                                        </div>
                                                                        <ul class="img-stack">
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/01.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Klaus Klug</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/07.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Mary C. Shen</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/14.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Lucas Vogler</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/02.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Manuela Moench</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#">
                                                                                    <img src="@/assets/images/group/icon/05.png" alt="member-img">
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">Matthias Propst</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li class="bg-theme">
                                                                                <a href="#">
                                                                                    12+
                                                                                    <div class="time-tooltip">
                                                                                        <div class="time-tooltip-holder">
                                                                                            <span class="tooltip-info">More</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="text-center mt-5">
                                                    <a href="#" class="default-btn"><i class="fa-solid fa-spinner"></i> Load More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="tab-pane fade" id="gt6" role="tabpanel" aria-labelledby="gt6-tab">
                                    <div class="group__bottom--body bg-white">
                                        <div class="group__bottom--allmedia">
                                            <div class="media-wrapper">
                                                <ul class="nav nav-tabs" id="myTab3" role="tablist">
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link active" id="all-media-tab" data-bs-toggle="tab" data-bs-target="#all-media" type="button" role="tab" aria-controls="all-media" aria-selected="true"><i class="fa-solid fa-table-cells-large"></i> All <span>12</span></button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="album-tab" data-bs-toggle="tab" data-bs-target="#album" type="button" role="tab" aria-controls="album" aria-selected="false"><i class="fa-solid fa-camera"></i> Albums <span>4</span></button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="photos-media-tab" data-bs-toggle="tab" data-bs-target="#photos-media" type="button" role="tab" aria-controls="photos-media" aria-selected="false"><i class="fa-solid fa-image"></i> Photos <span>4</span></button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video" type="button" role="tab" aria-controls="video" aria-selected="false"><i class="fa-solid fa-video"></i> Videos <span>4</span></button>
                                                    </li>
                                                    <li class="nav-item" role="presentation">
                                                        <button class="nav-link" id="music-tab" data-bs-toggle="tab" data-bs-target="#music" type="button" role="tab" aria-controls="music" aria-selected="false"><i class="fa-solid fa-music"></i> Music <span>0</span></button>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent3">
                                                    <div class="tab-pane fade show active" id="all-media"
                                                        role="tabpanel" aria-labelledby="all-media-tab">
                                                        <div class="media-content">
                                                            <ul class="media-upload">
                                                                <li class="upload-now">
                                                                    <div class="custom-upload">
                                                                        <div class="file-btn"><i class="fa-solid fa-upload"></i> Upload</div>
                                                                        <input type="file">
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-3 g-3">
                                                                <div class="col">
                                                                    <div class="media-thumb video-thumb">
                                                                        <img src="@/assets/images/allmedia/01.jpg" alt="dating thumb">
                                                                        <a href="#groupmodalvideo" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-circle-play"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb albam-thumb">
                                                                        <img src="@/assets/images/allmedia/02.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase:collection" class="icon">
                                                                            <i class="fa-solid fa-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb">
                                                                        <img src="@/assets/images/allmedia/03.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb">
                                                                        <img src="@/assets/images/allmedia/04.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb video-thumb">
                                                                        <img src="@/assets/images/allmedia/05.jpg" alt="dating thumb">
                                                                        <a href="#groupmodalvideo" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-circle-play"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb albam-thumb">
                                                                        <img src="@/assets/images/allmedia/06.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase:collection" class="icon">
                                                                            <i class="fa-solid fa-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb video-thumb">
                                                                        <img src="@/assets/images/allmedia/07.jpg" alt="dating thumb">
                                                                        <a href="#groupmodalvideo" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-circle-play"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb">
                                                                        <img src="@/assets/images/allmedia/08.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb">
                                                                        <img src="@/assets/images/allmedia/09.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb albam-thumb">
                                                                        <img src="@/assets/images/allmedia/10.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase:collection" class="icon">
                                                                            <i class="fa-solid fa-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb video-thumb">
                                                                        <img src="@/assets/images/allmedia/11.jpg" alt="dating thumb">
                                                                        <a href="#groupmodalvideo" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-circle-play"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb albam-thumb">
                                                                        <img src="@/assets/images/allmedia/12.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase:collection" class="icon">
                                                                            <i class="fa-solid fa-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="text-center mt-5">
                                                                <a href="#" class="default-btn"><i class="fa-solid fa-spinner"></i> Load More</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="tab-pane fade" id="album" role="tabpanel" aria-labelledby="album-tab">
                                                        <div class="media-content">
                                                            <ul class="media-upload">
                                                                 <li class="upload-now">
                                                                    <div class="custom-upload">
                                                                        <div class="file-btn"><i class="fa-solid fa-upload"></i> Upload</div>
                                                                        <input type="file">
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-3 g-3">
                                                                <div class="col">
                                                                    <div class="media-thumb albam-thumb">
                                                                        <img src="@/assets/images/allmedia/02.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase:collection" class="icon">
                                                                            <i class="fa-solid fa-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb albam-thumb">
                                                                        <img src="@/assets/images/allmedia/06.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase:collection" class="icon">
                                                                            <i class="fa-solid fa-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb albam-thumb">
                                                                        <img src="@/assets/images/allmedia/10.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase:collection" class="icon">
                                                                            <i class="fa-solid fa-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb albam-thumb">
                                                                        <img src="@/assets/images/allmedia/12.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase:collection" class="icon">
                                                                            <i class="fa-solid fa-camera"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="text-center mt-5">
                                                                <a href="#" class="default-btn"><i class="fa-solid fa-spinner"></i> Load More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="tab-pane fade" id="photos-media" role="tabpanel" aria-labelledby="photos-media-tab">
                                                        <div class="media-content">
                                                            <ul class="media-upload">
                                                                 <li class="upload-now">
                                                                    <div class="custom-upload">
                                                                        <div class="file-btn"><i class="fa-solid fa-upload"></i> Upload</div>
                                                                        <input type="file">
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-3 g-3">
                                                                <div class="col">
                                                                    <div class="media-thumb">
                                                                        <img src="@/assets/images/allmedia/03.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb">
                                                                        <img src="@/assets/images/allmedia/04.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb">
                                                                        <img src="@/assets/images/allmedia/08.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb">
                                                                        <img src="@/assets/images/allmedia/09.jpg" alt="dating thumb">
                                                                        <a href="#groupmodal" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-image"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="text-center mt-5">
                                                                <a href="#" class="default-btn"><i class="fa-solid fa-spinner"></i> Load More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
                                                        <div class="media-content">
                                                            <ul class="media-upload">
                                                                 <li class="upload-now">
                                                                    <div class="custom-upload">
                                                                        <div class="file-btn"><i class="fa-solid fa-upload"></i> Upload</div>
                                                                        <input type="file">
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-3 g-3">
                                                                <div class="col">
                                                                    <div class="media-thumb video-thumb">
                                                                        <img src="@/assets/images/allmedia/01.jpg" alt="dating thumb">
                                                                        <a href="#groupmodalvideo" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-circle-play"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb video-thumb">
                                                                        <img src="@/assets/images/allmedia/05.jpg" alt="dating thumb">
                                                                        <a href="#groupmodalvideo" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-circle-play"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb video-thumb">
                                                                        <img src="@/assets/images/allmedia/07.jpg" alt="dating thumb">
                                                                        <a href="#groupmodalvideo" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-circle-play"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="media-thumb video-thumb">
                                                                        <img src="@/assets/images/allmedia/11.jpg" alt="dating thumb">
                                                                        <a href="#groupmodalvideo" data-rel="lightcase" class="icon">
                                                                            <i class="fa-solid fa-circle-play"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="text-center mt-5">
                                                                <a href="#" class="default-btn"><i class="fa-solid fa-spinner"></i> Load More</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="tab-pane fade" id="music" role="tabpanel" aria-labelledby="music-tab">
                                                        <div class="media-content">
                                                            <ul class="media-upload">
                                                                 <li class="upload-now">
                                                                    <div class="custom-upload">
                                                                        <div class="file-btn"><i class="fa-solid fa-upload"></i> Upload</div>
                                                                        <input type="file">
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <div class="row">
                                                                <div class="col">
                                                                    <p><i class="icofont-worried"></i> Sorry !!
                                                                        There's no media found for the
                                                                        request !! </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 order-xl-0">
                        <div class="group__bottom--center">
                            <div class="story__item style2">
                                <div class="story__inner">
                                    <div class="story__inner">

                                        <!-- Profile photo section start -->
                                        <div class="story__thumb position-relative">
                                            <img v-if="profilePhotos.length > 0" :src="profilePhotoUrl" alt="Photo de profil">
                                            <img v-else src="@/assets/images/member/profile/default_profile.png">
                                        </div>
                                        <div class="story__content px-0 pb-0">
                                            <div class="group__bottom--allmedia p-0 m-0">
                                                <ul class="media-upload">
                                                    <li class="upload-now">
                                                        <div class="custom-upload">
                                                            <div class="file-btn"><i class="fa-solid fa-upload"></i> Ajouter une photo</div>
                                                            <input type="file" ref="fileInput" @change="uploadProfilePhoto">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- Profile photo section end -->
                                        <h4>{{ holeName() }}</h4>
                                        <div class="story__content--content mb-2 pb-3">
                                            <p><i class="fa-solid fa-clock"></i> Actif il y a 3 h</p>
                                        </div>
                                        <p class="mb-2">{{ truncateText(user.description, 100) }}</p>
                                        <div class="story__content--author mt-3 pb-2">
                                            <h6 class="d-block w-100 mb-3">{{ holeName() }} Photos</h6>
                                            <div class="row g-2">
                                                <div class="col-4" v-for="(photo, index) in profilePhotos" :key="index">
                                                    <a :href="'#groupmodal' + index" data-rel="lightcase:callection">
                                                        <img :src="getImageUrl(photo.file)" alt="dating thumb">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xl-3 order-xl-2">
                        <div class="group__bottom--right">
                            <div class="modal-content border-0 mb-4">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Filter your search</h5>
                                </div>
                                <div class="modal-body">
                                    <form action="#">
                                        <div class="banner__list">
                                            <div class="row align-items-center row-cols-1">
                                                <div class="col">
                                                    <label>I am a</label>
                                                    <div class="banner__inputlist">
                                                        <select>
                                                            <option>Select Gender</option>
                                                            <option value="male" selected>Male</option>
                                                            <option value="female">Female</option>
                                                            <option value="others">Others</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <label>Looking for</label>
                                                    <div class="banner__inputlist">
                                                        <select>
                                                            <option>Select Gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female" selected>Female</option>
                                                            <option value="others">Others</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <label>Age</label>
                                                    <div class="row g-3">
                                                        <div class="col-6">
                                                            <div class="banner__inputlist">
                                                                <select>
                                                                    <option value="18" selected>18</option>
                                                                    <option value="19">19</option>
                                                                    <option value="20">20</option>
                                                                    <option value="21">21</option>
                                                                    <option value="22">22</option>
                                                                    <option value="23">23</option>
                                                                    <option value="24">24</option>
                                                                    <option value="25">25</option>
                                                                    <option value="26">26</option>
                                                                    <option value="27">27</option>
                                                                    <option value="28">28</option>
                                                                    <option value="29">29</option>
                                                                    <option value="30">30</option>
                                                                    <option value="31">31</option>
                                                                    <option value="32">32</option>
                                                                    <option value="33">33</option>
                                                                    <option value="34">34</option>
                                                                    <option value="35">35</option>
                                                                    <option value="36">36</option>
                                                                    <option value="37">37</option>
                                                                    <option value="38">38</option>
                                                                    <option value="39">39</option>
                                                                    <option value="40">40</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="banner__inputlist">
                                                                <select>
                                                                    <option value="18">18</option>
                                                                    <option value="19">19</option>
                                                                    <option value="20">20</option>
                                                                    <option value="21">21</option>
                                                                    <option value="22">22</option>
                                                                    <option value="23">23</option>
                                                                    <option value="24">24</option>
                                                                    <option value="25" selected>25</option>
                                                                    <option value="26">26</option>
                                                                    <option value="27">27</option>
                                                                    <option value="28">28</option>
                                                                    <option value="29">29</option>
                                                                    <option value="30">30</option>
                                                                    <option value="31">31</option>
                                                                    <option value="32">32</option>
                                                                    <option value="33">33</option>
                                                                    <option value="34">34</option>
                                                                    <option value="35">35</option>
                                                                    <option value="36">36</option>
                                                                    <option value="37">37</option>
                                                                    <option value="38">38</option>
                                                                    <option value="39">39</option>
                                                                    <option value="40">40</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <label>Country</label>
                                                    <div class="banner__inputlist">
                                                        <select id="country" name="country">
                                                            <option value="Afganistan">Afghanistan</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="American Samoa">American Samoa</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Anguilla">Anguilla</option>
                                                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Aruba">Aruba</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh" selected>Bangladesh</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Bermuda">Bermuda</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bonaire">Bonaire</option>
                                                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                            <option value="Brunei">Brunei</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Canary Islands">Canary Islands</option>
                                                            <option value="Cape Verde">Cape Verde</option>
                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                            <option value="Central African Republic">Central African Republic</option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Channel Islands">Channel Islands</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Christmas Island">Christmas Island</option>
                                                            <option value="Cocos Island">Cocos Island</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Congo">Congo</option>
                                                            <option value="Cook Islands">Cook Islands</option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Cote DIvoire">Cote DIvoire</option>
                                                            <option value="Croatia">Croatia</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Curaco">Curacao</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czech Republic">Czech Republic</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                            <option value="East Timor">East Timor</option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Falkland Islands">Falkland Islands</option>
                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="France">France</option>
                                                            <option value="French Guiana">French Guiana</option>
                                                            <option value="French Polynesia">French Polynesia</option>
                                                            <option value="French Southern Ter">French Southern Ter</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Gibraltar">Gibraltar</option>
                                                            <option value="Great Britain">Great Britain</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Greenland">Greenland</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                            <option value="Guam">Guam</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Hawaii">Hawaii</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hong Kong">Hong Kong</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="India">India</option>
                                                            <option value="Iran">Iran</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Isle of Man">Isle of Man</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Korea North">Korea North</option>
                                                            <option value="Korea Sout">Korea South</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Laos">Laos</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Lebanon">Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libya">Libya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Macau">Macau</option>
                                                            <option value="Macedonia">Macedonia</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Martinique">Martinique</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mayotte">Mayotte</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Midway Islands">Midway Islands</option>
                                                            <option value="Moldova">Moldova</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Montserrat">Montserrat</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Nambia">Nambia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherland Antilles">Netherland Antilles</option>
                                                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                            <option value="Nevis">Nevis</option>
                                                            <option value="New Caledonia">New Caledonia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="Niue">Niue</option>
                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Palau Island">Palau Island</option>
                                                            <option value="Palestine">Palestine</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Phillipines">Philippines</option>
                                                            <option value="Pitcairn Island">Pitcairn Island</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                            <option value="Republic of Serbia">Republic of Serbia</option>
                                                            <option value="Reunion">Reunion</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russia">Russia</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="St Barthelemy">St Barthelemy</option>
                                                            <option value="St Eustatius">St Eustatius</option>
                                                            <option value="St Helena">St Helena</option>
                                                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                            <option value="St Lucia">St Lucia</option>
                                                            <option value="St Maarten">St Maarten</option>
                                                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                                            <option value="Saipan">Saipan</option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="Samoa American">Samoa American</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia">Slovakia</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="Spain">Spain</option>
                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Swaziland">Swaziland</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syria">Syria</option>
                                                            <option value="Tahiti">Tahiti</option>
                                                            <option value="Taiwan">Taiwan</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Tanzania">Tanzania</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tokelau">Tokelau</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Erimates">United Arab Emirates</option>
                                                            <option value="United States of America">United States of America</option>
                                                            <option value="Uraguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="Vatican City State">Vatican City State</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Vietnam">Vietnam</option>
                                                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                            <option value="Wake Island">Wake Island</option>
                                                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Zaire">Zaire</option>
                                                            <option value="Zambia">Zambia</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <button type="submit" class="default-btn d-block w-100"><span>Find Your Partner </span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="active-member bg-white mb-4">
                                <div class="modal-header">
                                    <h6>Members</h6>
                                    <ul class="nav nav-tabs" id="myTab4" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="Newest-tab" data-bs-toggle="tab" data-bs-target="#Newest" type="button" role="tab" aria-controls="Newest" aria-selected="true">Newest </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="Popular-tab" data-bs-toggle="tab" data-bs-target="#Popular" type="button" role="tab" aria-controls="Popular" aria-selected="false">Popular</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="modal-body group__bottom--body">
                                    <div class="tab-content" id="myTabContent4">
                                        <div class="tab-pane fade show active" id="Newest" role="tabpanel" aria-labelledby="Newest-tab">
                                            <ul>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/01.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/02.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Marina Holzman</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/03.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Thorsten Luft</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/04.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Juliane Bieber</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/05.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Jens Klein</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane fade" id="Popular" role="tabpanel" aria-labelledby="Popular-tab">
                                            <ul>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/06.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/07.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/08.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/09.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/10.jpg" alt="member-img">
                                                                <span class="member__activity"></span>
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="member-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="active-group bg-white">
                                <div class="modal-header">
                                    <h6>Group</h6>
                                    <ul class="nav nav-tabs" id="myTab5" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="aNewest-tab" data-bs-toggle="tab" data-bs-target="#aNewest" type="button" role="tab" aria-controls="aNewest" aria-selected="true">Newest </button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="aPopular-tab" data-bs-toggle="tab" data-bs-target="#aPopular" type="button" role="tab" aria-controls="aPopular" aria-selected="false">Popular</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="modal-body group__bottom--body">
                                    <div class="tab-content" id="myTabContent5">
                                        <div class="tab-pane fade show active" id="aNewest" role="tabpanel" aria-labelledby="aNewest-tab">
                                            <ul>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/01.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/02.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Marina Holzman</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/03.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Thorsten Luft</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/04.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Juliane Bieber</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/05.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Jens Klein</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-pane fade" id="aPopular" role="tabpanel" aria-labelledby="aPopular-tab">
                                            <ul>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/06.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/07.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/08.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/09.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="top">
                                                        <div class="left member--style2">
                                                            <div class="member__thumb">
                                                                <img src="@/assets/images/member/home2/10.jpg" alt="member-img">
                                                            </div>
                                                        </div>
                                                        <div class="right">
                                                            <a href="group-single.html"><h6>Leon Schmid</h6></a>
                                                            <span>2 years ago</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <UpdateProfilView :show="showProfilModal" @close="showProfilModal = false" />
    <UpateGenderView :show="showGenderModal" @close="showGenderModal = false" />
    <UpdateGPView :show="showGenderPrefModal" @close="showGenderPrefModal = false" />
    <UpdateDescriptionView :show="showDescriptionModal" @close="showDescriptionModal = false" />
    <UpdateHoleNameView :show="showHoleNameModal" @close="showHoleNameModal = false" />
    <UpdateBDView :show="showBDModal" @close="showBDModal = false" />
    <UpdateUNView :show="showUNModal" @close="showUNModal = false" />
    <UpdateUserProfileView :show="showUMModal" @close="showUMModal = false" />

</template>

<style>
.note {
    color: #856404; /* Couleur de texte d'alerte (warning) Bootstrap */
    background-color: #fff3cd; /* Couleur de fond d'alerte (warning) Bootstrap */
    border: 1px solid #ffeeba; /* Bordure d'alerte (warning) Bootstrap */
    padding: 10px; /* Espacement interne */
    border-radius: 5px; /* Coins arrondis */
    margin-top: 10px; /* Espacement au-dessus de la note */
    font-weight: bold; /* Texte en gras */
    font-size: 1rem; /* Taille de police légèrement augmentée */
    animation: blinker 2s linear infinite; /* Animation de clignotement */
}

@keyframes blinker {
    50% {
        opacity: 0.3;
    }
}

</style>

<style>

</style>