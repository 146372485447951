<template>
    <!-- <h2> Error 404 <br> Page Not Found</h2> -->
    <section class="log-reg forezero">
        <div class="container">
            <div class="row justify-content-end">
                <div class="image image-404"></div>
                <div class="col-lg-7 ">
                    <div class="log-reg-inner">
                        <div class="main-thumb mb-5">
                            <img src="@/assets/images/404.png" alt="datting thumb">
                        </div>
                        <div class="main-content inloginp">
                            <div class="text-content text-center">
                                <h2>Ops! Impossible de retrouver la page</h2>
                                <p>Nous sommes vraiment désolés mais la page que vous avez demandée est manquante :( </p>
                                <router-link :to="{name:'home'}" class="default-btn reverse"><span>Accueil</span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>

<style scoped>
     /* h2{
        font-family: Avenir, Arial, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osz-font-smooting: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    } */
</style>