<script setup>
    import { accountService } from '@/_services';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router'
    import { reactive, ref } from 'vue'
    import { auth, googleProvider, signInWithPopup } from '@/_services/firebase.js';
    import SocialLoginComponent from '@/components/public/SocialLoginComponent.vue';
    import { db } from '@/_services/firebase.js';
    import { doc, setDoc } from 'firebase/firestore';
    import swal from 'sweetalert';
    import { getAndSaveFcmToken } from '@/_services/firebase.js';

    const router = useRouter()
    const store = useStore()

    const errorMessage = ref('')

    const data = reactive({
		login:'',
		password:''
	})

    const showPassword = ref(false);

    const togglePasswordVisibility = () => {
        showPassword.value = !showPassword.value;
    };

    function login() {
        // console.log('Form data:', data); 
        accountService.login(data).then(res => {
            // console.log('Api data:', res.data); 
            if (res.data.user) {

                const userData = {
                    id: res.data.user.id,
                    firstname: res.data.user.first_name,
                    lastname: res.data.user.last_name,
                    username: res.data.user.username,
                    born_on: res.data.user.born_on,
                    gender: res.data.user.gender,
                    description: res.data.user.description,
                    // Ajoutez d'autres propriétés d'utilisateur au besoin
                };

                // Utilisez le auth_token renvoyé par l'API
                const auth_token = res.data.user.auth_token;

                // Stockez le auth_token localement
                accountService.saveToken(auth_token);

                // Stockez l'ID de l'utilisateur localement
                localStorage.setItem('id', userData.id);
                console.log('User ID:', userData.id); // Ajout du console.log

                store.commit('setAppUser', userData);
                store.commit('setLogged', true);

                // Appelle la fonction pour obtenir et enregistrer le FCM token
                getAndSaveFcmToken(userData.id).then(() => {
                    console.log('FCM token enregistré avec succès');
                }).catch((error) => {
                    console.error('Erreur lors de l\'enregistrement du FCM token:', error);
                });

                // console.log('Api data:', res.data);
                router.push({ name: 'swipe' });
                // console.log('Api data:', res.data); 
                swal({
                    title: 'Succès',
                    text: 'Utilisateur connecté',
                    icon: 'success'
                });
            } else {
                // Afficher un message d'erreur approprié en fonction de la réponse
                if (res.data.errors.length > 0) {
                    errorMessage.value = res.data.errors[0];
                    swal({
                        title: 'Erreur',
                        text: 'Identifiant ou mot de passe incorrect',
                        icon: 'error'
                    });
                } else {
                    if (res.data.errors.length > 0) {
                        errorMessage.value = res.data.errors[0];
                    } else {
                        switch (res.data.user.username) {
                            case null:
                                errorMessage.value = "Cet utilisateur n'existe pas. Veuillez vous inscrire.";
                                break;
                            case 'blocked':
                                errorMessage.value = "Votre compte a été désactivé. Veuillez contacter l'administrateur.";
                                break;
                            default:
                                errorMessage.value = "Une erreur s'est produite lors de la connexion. Veuillez réessayer.";
                        }
                    }
                }
            }
        }).catch((error) => {
            console.error('Erreur de connexion:', error);
            // errorMessage.value = 'Une erreur s\'est produite lors de la connexion au serveur.';
            swal({
                title: 'Erreur',
                text: 'Une erreur s\'est produite lors de la connexion au serveur',
                icon: 'error'
            });
        });
    }

    async function handleGoogleSignIn() {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            // Obtenir les données de l'utilisateur
            const firstName = user.displayName?.split(' ')[0] || '';
            const lastName = user.displayName?.split(' ')[1] || '';

            // Enregistre l'utilisateur dans le backend
            const response = await accountService.validateSocialConnect({
                provider: 'google',
                display_name: user.displayName,
                first_name: firstName,
                last_name: lastName,
                email: user.email,
                photo_url: user.photoURL,
                user_id: user.uid
            });

            const userId = response.data.user.id;
            const authToken = response.data.user.auth_token; // Obtention du auth_token depuis la réponse du backend

            if (userId && authToken) {
                // Utiliser l'ID du backend pour Firestore
                await saveUserToFirestore(userId, {
                    email: user.email,
                    first_name: firstName,
                    last_name: lastName,
                    photo_url: user.photoURL
                });

                // Stocker le auth_token et l'ID de l'utilisateur localement
                accountService.saveToken(authToken);
                localStorage.setItem('id', userId);

                store.commit('setAppUser', {
                    id: userId,
                    firstname: firstName,
                    lastname: lastName,
                    username: user.email
                });

                store.commit('setLogged', true);
                router.push({ name: 'swipe' });

                swal({
                    title: 'Succès',
                    text: 'Utilisateur connecté avec Google',
                    icon: 'success'
                });
            } else {
                throw new Error('ID utilisateur ou auth_token manquant dans la réponse');
            }
        } catch (error) {
            console.error('Erreur de connexion avec Google:', error);
            swal({
                title: 'Erreur',
                text: 'Une erreur est survenue lors de la connexion avec Google',
                icon: 'error'
            });
        }
    }

    async function saveUserToFirestore(userId, userData) {
        try {
            await setDoc(doc(db, 'users', userId), userData);
            console.log('Utilisateur enregistré dans Firestore');
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement dans Firestore:', error);
        }
    }

</script>

<template>
    <section class="log-reg">
        <div class="top-menu-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-7">
                        <div class="logo">
                            <router-link :to="{name:'home'}" class="backto-home"><img src="../../assets/images/logo/logo.png" alt="logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-5">
                        <router-link :to="{name:'home'}" class="backto-home"><i class="fas fa-chevron-left"></i> Accueil</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="image image-log"></div>
                <div class="col-lg-7">
                    <div class="log-reg-inner">
                        <div class="section-header inloginp pt-0">
                            <h2 class="title">Bienvenue sur Hodalo</h2>
                            <!-- <p v-if="errorMessage" class="pt-2 fs-6 text-danger">{{ errorMessage }}</p> -->
                        </div>
                        <div class="main-content inloginp">
                            <form @submit.prevent="login">
                                <div class="form-group">
                                    <label >Identifiant</label>
                                    <input type="text" class="my-form-control" placeholder="Identifiant" v-model="data.login" required>
                                </div>
                                <div class="form-group">
                                    <label >Mot de passe</label>
                                    <input class="my-form-control" placeholder="Mot de passe" v-model="data.password" v-bind:type="showPassword ? 'text' : 'password'"  required>
                                    <span class="toggle-password" @click="togglePasswordVisibility">
                                        <i class="fas" v-bind:class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                                    </span>
                                </div>
                                <p class="f-pass">Mot de passe oublié ? <a href="#">Récupérer le mot de passe</a></p>
                                <div class="text-center">
                                    <button type="submit" class="default-btn"><span>Connexion</span></button>
                                </div>
                                <div class="or">
                                    <p>ou</p>
                                </div>
                                <div class="or-content">
                                    <p>Connexion avec email</p>
                                    <!-- <a href="#" class="default-btn reverse"><img src="../../assets/images/login/google.png" alt="google"> <span>Connexion avec Google</span></a> -->
                                    <!-- <SocialLoginComponent /> -->

                                    <!-- <a href="#" id="google-login-button" class="default-btn reverse">
                                        <img src="../../assets/images/login/google.png" alt="google">
                                        <span>Connexion avec Google</span>
                                    </a> -->
                                    <button @click="handleGoogleSignIn" class="default-btn reverse"><img src="../../assets/images/login/google.png" alt="google">Connexion avec Google</button>
                                    <p class="or-signup pt-3"> Vous n'avez pas de compte ? <router-link :to="{name:'register'}">Inscription</router-link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
    .form-group {
    position: relative;
    }

    .toggle-password {
        position: absolute;
        right: 0;
        top: 71%;
        transform: translateY(-50%);
    }

    .fas {
        padding-right: 10px;
    }

    .my-form-control {
        padding-right: 30px; /* Ajouter un espace à droite pour le bouton de l'oeil */
    }
</style>