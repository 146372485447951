<template>
    <div class="wrapper">
      <!-- Sidebar  -->
      <nav style="color: white" id="sidebar">
        <div class="sidebar-header d-flex justify-content-around">
          <div
            class="d-flex pointer"
            style="margin-right: 25px; width: 160px"
            @click="onProfileClick"
          >
            <img
              :src="photoURL"
              alt="user"
              width="50px"
              height="50px"
              style="border-radius: 50%; background: white;"
            />
          </div>
          <button type="button" class="btn btn-danger" @click="logout">Logout</button>
        </div>
        <div style="height: 1px; border-bottom: 1px solid #00388b"></div>
        <ul class="list-unstyled components">
          <li
            class="active mb-3"
            @click="letsChat(item)"
            v-for="item in searchUsers"
            :key="item.id"
            v-show="item.id != currentUserId"
          >
            <div class="d-flex" style="cursor: pointer; padding-bottom: 15px; width: 100%">
              <div style="width: 30%">
                <img
                  :src="item.URL"
                  alt="user"
                  width="50px"
                  height="50px"
                  style="border-radius: 50%; background: white;"
                />
              </div>
              <div
                style="padding: 10px 0px 0px; width: 50%; display: flex; justify-content: space-between"
              >
                <h6 style="line-height: 2; font-weight: 600">{{item.name}}</h6>
              </div>
            </div>
            <div style="height: 1px; border-bottom: 1px solid #00388b"></div>
          </li>
        </ul>
      </nav>
  
      <!-- Page Content  -->
      <div id="content" v-if="currentPeerUser===null">
        <div class="my-4">
          <img :src="photoURL" width="200px" class="br-50" />
        </div>
        <div>
          <h2>Welcome {{currentUserName}},</h2>
          <h3>Start chating with friends</h3>
        </div>
      </div>
      <div v-else class="header-width">
        <ChatBox :currentPeerUser="currentPeerUser" />
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { getAuth, signOut } from 'firebase/auth';
  import { getFirestore, collection, getDocs } from 'firebase/firestore';
  import { useStorage } from '@vueuse/core';
  import ChatBox from './ChatBox';
  import { auth, db } from '@/_services/firebase.js';
  
  export default {
    name: 'Chat',
    components: {
      ChatBox
    },
    setup() {
      const router = useRouter();
      const auth = getAuth();
  
      const currentUserName = useStorage('name', '');
      const currentPeerUser = ref(null);
      const currentUserId = useStorage('id', '');
      const currentUserPhoto = useStorage('photoURL', '');
      const searchUsers = ref([]);
      const photoURL = useStorage('photoURL', '');
  
      const onProfileClick = () => {
        router.push('/profile');
      };
  
      const logout = async () => {
        await signOut(auth);
        router.push('/');
        localStorage.clear();
      };
  
      const letsChat = (item) => {
        currentPeerUser.value = item;
      };
  
      const getUserList = async () => {
        const result = await getDocs(collection(db, 'users'));
        if (result.docs.length > 0) {
          let listUsers = [];
          listUsers = [...result.docs];
          listUsers.forEach((item, index) => {
            searchUsers.value.push({
              key: index,
              documentKey: item.id,
              id: item.data().id,
              name: item.data().name,
              URL: item.data().URL,
              description: item.data().description
            });
          });
        }
      };
  
      onMounted(() => {
        if (!localStorage.hasOwnProperty('id')) router.push('/');
        getUserList();
      });
  
      return {
        currentUserName,
        currentPeerUser,
        currentUserId,
        currentUserPhoto,
        searchUsers,
        photoURL,
        onProfileClick,
        logout,
        letsChat
      };
    }
  };
  </script>
  
  <style scoped>
  .pointer {
    cursor: pointer;
  }
  .br-50 {
    border-radius: 50%;
  }
  .header-width {
    width: calc(100% - 350px);
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
  }
  </style>
  