<template>
    <!-- ================> Work section start here <================== -->
	<!-- <div class="work work--style2 padding-top padding-bottom bg_img" style="background-image: url(@/assets/images/bg-img/01.jpg);"> -->
	<div class="work work--style2 padding-top padding-bottom">
		<img src="@/assets/images/bg-img/01.jpg" class="bg_img" alt="Background Image">
		<div class="container">
			<div class="section__wrapper">
				<div class="row g-4 justify-content-center">
					<div class="col-xl-6 col-lg-8 col-12 wow fadeInLeft" data-wow-duration="1.5s">
						<div class="work__item">
							<div class="work__inner">
								<div class="work__thumb">
									<img src="../../assets/images/work/09.png" alt="dating thumb">
								</div>
								<div class="work__content">
									<h3>Confiance et sécurité</h3>
									<p>Choisissez parmi l’un de nos niveaux d’adhésion et débloquez les fonctionnalités dont vous avez besoin. </p>
									<a href="policy.html" class="default-btn"><span>Voir plus de détails</span></a>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-8 col-12 wow fadeInRight" data-wow-duration="1.5s">
						<div class="work__item">
							<div class="work__inner">
								<div class="work__thumb">
									<img src="../../assets/images/work/10.png" alt="dating thumb">
								</div>
								<div class="work__content">
									<h3>Adhésion simple</h3>
									<p>Choisissez parmi l’un de nos niveaux d’adhésion et débloquez les fonctionnalités dont vous avez besoin. </p>
									<a href="membership.html" class="default-btn reverse"><span>Détails de l'adhésion</span></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <!-- ================> Work section end here <================== -->
</template>

<style>
	.work {
    	position: relative; /* Assurez-vous que le conteneur a une position relative */
	}

	.work .bg_img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1; /* Positionner l'image en arrière-plan */
		object-fit: cover; /* Assurez-vous que l'image couvre tout le conteneur */
		opacity: 0.5; /* Réglez l'opacité de l'image au besoin */
	}
</style>