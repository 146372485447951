<template>
    <div class="mt-4">
      <h2>Welcome to Vue.js Chat App</h2>
      <form class="detail-box my-5" @submit.prevent="signup">
        <div class="form-group my-3">
          <h4>Signup to Chat</h4>
          <input
            type="text"
            v-model="name"
            class="form-control mb-4 mt-4"
            placeholder="Enter your name..."
          />
          <input
            type="text"
            v-model="email"
            class="form-control mb-4"
            placeholder="Enter your email..."
          />
          <input
            type="password"
            v-model="password"
            class="form-control mb-4"
            placeholder="Enter your password..."
          />
          <router-link :to="{name:'chat_login'}">
            <h6 class="mb-3" style="font-weight: 600">Back to login</h6>
          </router-link>
          <button
            style="font-weight: 600"
            type="button"
            v-on:click="signup"
            class="btn btn-primary"
          >Signup</button>
        </div>
      </form>
      <div v-if="error" class="error">
        {{ error }}
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { auth, db } from '@/_services/firebase.js';
  import { createUserWithEmailAndPassword } from 'firebase/auth';
  import { addDoc, collection } from 'firebase/firestore';
  
  export default {
    name: 'Signup',
    setup() {
      const name = ref('');
      const email = ref('');
      const password = ref('');
      const error = ref(null);
      const router = useRouter();
  
      const signup = async () => {
        error.value = null; // Reset error message
        try {
          const res = await createUserWithEmailAndPassword(auth, email.value, password.value);
          console.log('res', res);
          const docRef = await addDoc(collection(db, 'users'), {
            name: name.value,
            id: res.user.uid,
            email: email.value,
            password: password.value,
            URL: '',
            description: ''
          });
          localStorage.setItem('id', res.user.uid);
          localStorage.setItem('name', name.value);
          localStorage.setItem('email', email.value);
          localStorage.setItem('password', password.value);
          localStorage.setItem('photoURL', '');
          localStorage.setItem('description', '');
          localStorage.setItem('FirebaseDocumentId', docRef.id);
          name.value = '';
          email.value = '';
          password.value = '';
          router.push('/chat_side_bar');
        } catch (err) {
          console.error('Error signing up:', err);
          error.value = err.message;
        }
      };
  
      return {
        name,
        email,
        password,
        error,
        signup
      };
    }
  };
  </script>

<style scoped >
input[type="text"],
input[type="password"] {
  margin: 0 auto;
  width: 80%;
}
.detail-box {
  padding: 5px;
  border: 1px solid lightgrey;
  width: 400px;
  min-height: 250px;
  margin: 0 auto;
}
h2,
h4 {
  color: dodgerblue;
  font-weight: 600;
}
</style>