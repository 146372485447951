<template>
  <div class="component-container">
    <div id="container">
      <div class="swipe">
        <div id="logo">
            <router-link :to="{name:'home'}" class="backto-home"><img src="../../assets/images/logo/logo.png" alt="logo"></router-link>
          </div>
        
          <div id="menu">
            <router-link :to="{name:'dashboard'}" class="backto-home text-dark"><i class="fas fa-chevron-left"></i> Dashboard</router-link>
          </div>
      </div>
      <ion-icon id="dislike" name="heart-dislike"></ion-icon>
      <div id="swiper">
      </div>
      <ion-icon id="like" name="heart"></ion-icon>
      <div id="bottom-menu">
        <router-link :to="{name:'swipe'}" class="menu-item"><ion-icon name="swap-horizontal-outline"></ion-icon>Rencontres</router-link>
        <router-link :to="{name:'proximity'}" class="menu-item"><ion-icon name="locate-outline"></ion-icon>Proximité</router-link>
        <router-link :to="{name:'likes'}" class="menu-item"><ion-icon name="heart-outline"></ion-icon>Likes</router-link>
        <router-link :to="{name:'vip'}" class="menu-item"><ion-icon name="star-outline"></ion-icon>VIP</router-link>
        <router-link :to="{name:'chat'}" class="menu-item"><ion-icon name="chatbubble-outline"></ion-icon>Chat</router-link>
        <router-link :to="{name:'dashboard'}" class="menu-item"><ion-icon name="person-outline"></ion-icon>Profil</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import axios from 'axios';
import { accountService } from '@/_services'

const baseURL = process.env.VUE_APP_API_BASE_URL.replace('/api', '');

// Fonction pour construire l'URL de l'image
const getImageUrl = (filePath) => {
    if (!filePath) return '';
    return `${baseURL}${filePath}`;
  }

const fetchUserPhotos = async () => {
  try {
    const auth_token = accountService.getToken();

    if (!auth_token) {
      console.error('Token non trouvé.');
      return [];
    }

    const response = await accountService.getDates()

    const usersData = response.data.items.map(item => ({
      id: item.user.id,
      full_name: item.user.full_name,
      age: calculateAge(item.user.born_on),
      description: item.user.description,
      religion: item.user.religion,
      profile: item.user.profile,
      school_degree : item.user.school_degree ,
      size: item.user.size,
      astrological_sign: item.user.astrological_sign,
      child_state: item.user.child_state,
      alcohol_state: item.user.alcohol_state,
      job: item.user.job,
      school: item.user.school,
      country: item.user.country,
      location: item.user.location,
      sexual_orientation: item.user.sexual_orientation,
      photos: item.images.map(image => getImageUrl(image.file))
    }));

    return usersData;
  } catch (error) {
    console.error('Erreur lors de la récupération des photos:', error);
    return [];
  }
};

const calculateAge = (birthdate) => {
  const birthDate = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

onMounted(async () => {
  const swiper = document.querySelector('#swiper');
  const like = document.querySelector('#like');
  const dislike = document.querySelector('#dislike');

  let cardCount = 0;

  class Card {
    constructor({ userData, onDismiss, onLike, onDislike }) {
      this.userData = userData;
      this.onDismiss = onDismiss;
      this.onLike = onLike;
      this.onDislike = onDislike;
      this.#init();
    }

  #startPoint;
  #offsetX;
  #offsetY;

  #isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  #init = () => {
    const card = document.createElement("div");
    card.classList.add("card");

    const content = document.createElement("div");
    content.classList.add("card-content");

    // Ajoutez les informations de l'utilisateur en haut à gauche
    const userInfo = document.createElement("div");
    userInfo.classList.add("user-info");
    userInfo.innerText = `${this.userData.full_name}, ${this.userData.age}`;
    content.appendChild(userInfo);

    // Ajoutez la première image principale
    if (this.userData.photos[0]) {
      const mainImg = document.createElement("img");
      mainImg.src = this.userData.photos[0];
      mainImg.classList.add("main-image");
      content.appendChild(mainImg);
    }

    // Ajoutez un emplacement
    const looking_for = document.createElement("h6");
    looking_for.classList.add("description-title");
    looking_for.innerText = "Ce que je recherche";
    content.appendChild(looking_for);

    // Ajoutez ce que vous recherchez
    if (this.userData.profile) {
      const textProfile = document.createElement("p");
      textProfile.classList.add("description-profile");
      textProfile.innerText = `${this.userData.profile}`;
      content.appendChild(textProfile);
    }

    // Ajoutez le titre A propos
    if (this.userData.description) {
      const titleAbout = document.createElement("h3");
      titleAbout.classList.add("description-title");
      titleAbout.innerText = "A propos";
      content.appendChild(titleAbout);

      const textAbout = document.createElement("p");
      textAbout.classList.add("description-about");
      textAbout.innerText = `${this.userData.description}`;
      content.appendChild(textAbout);
    }

    // Ajoutez les autres images de l'utilisateur
    if (this.userData.photos.length > 1) {
      const additionalImages = document.createElement("div");
      additionalImages.classList.add("additional-images");
      this.userData.photos.slice(1).forEach(photoUrl => {
        const additionalImg = document.createElement("img");
        additionalImg.src = photoUrl;
        additionalImages.appendChild(additionalImg);
      });
      content.appendChild(additionalImages);
    }

    // Ajoutez le titre info
    const info = document.createElement("h6");
    info.classList.add("description-title");
    info.innerText = "Info";
    content.appendChild(info);

    // Ajoutez la religion
    if (this.userData.religion && this.userData.religion.label) {
      const religionLabel = this.userData.religion.label;
      const religionText = document.createElement("p");
      religionText.innerText = `⛪ ${religionLabel}`;
      content.appendChild(religionText);
    }

    // Ajoutez le niveau d'études
    if (this.userData.school_degree && this.userData.school_degree.label) {
      const schoolDegreeLabel = this.userData.school_degree.label;
      const schoolDegreeText = document.createElement("p");
      schoolDegreeText.innerText = `🎓 ${schoolDegreeLabel}`;
      content.appendChild(schoolDegreeText);
    }

    // Ajoutez la taille
    if (this.userData.size) {
      const sizeInCm = this.userData.size * 100; // Convertir en centimètres
      const textSize = document.createElement("p");
      textSize.classList.add("description-size");
      textSize.innerText = '📏'+` ${sizeInCm} cm`;
      content.appendChild(textSize);
    }

    // Ajoutez le signe astrologique
    if (this.userData.astrological_sign && this.userData.astrological_sign.label) {
      const astrological_signLabel = this.userData.astrological_sign.label;
      const astrological_signText = document.createElement("p");
      astrological_signText.innerText = `🦀 ${astrological_signLabel}`;
      content.appendChild(astrological_signText);
    }

    // Ajoutez avoir ou pas des enfants
    if (this.userData.child_state && this.userData.child_state.label) {
      const child_stateLabel = this.userData.child_state.label;
      const child_stateText = document.createElement("p");
      child_stateText.innerText = `👶 ${child_stateLabel}`;
      content.appendChild(child_stateText);
    }

    // Ajoutez si buveur ou non
    if (this.userData.alcohol_state && this.userData.alcohol_state.label) {
      const alcohol_stateLabel = this.userData.alcohol_state.label;
      const alcohol_stateText = document.createElement("p");
      alcohol_stateText.innerText = `🍺 ${alcohol_stateLabel}`;
      content.appendChild(alcohol_stateText);
    }

    // Ajoutez la profession
    if (this.userData.job && this.userData.job.label) {
      const jobLabel = this.userData.job.label;
      const jobText = document.createElement("p");
      jobText.innerText = `💼 ${jobLabel}`;
      content.appendChild(jobText);
    }

    // Ajoutez un établissement scolaire
    if (this.userData.school && this.userData.school.label) {
      const schoolLabel = this.userData.school.label;
      const schoolText = document.createElement("p");
      schoolText.innerText = `🏫 ${schoolLabel}`;
      content.appendChild(schoolText);
    }

    // Ajoutez le pays
    if (this.userData.country && this.userData.country.name) {
      const countryName = this.userData.country.name;
      const countryText = document.createElement("p");
      countryText.innerText = `🌍 ${countryName}`;
      content.appendChild(countryText);
    }

    // Ajoutez une orientation sexuelle
    if (this.userData.sexual_orientation && this.userData.sexual_orientation.label) {
      const sexual_orientationLabel = this.userData.sexual_orientation.label;
      const sexual_orientationText = document.createElement("p");
      sexual_orientationText.innerText = `⚧ ${sexual_orientationLabel}`;
      content.appendChild(sexual_orientationText);
    }

    // Ajoutez le pays
    const pays = document.createElement("h6");
    pays.classList.add("description-title");
    pays.innerText = "Pays";
    content.appendChild(pays);

    if (this.userData.country && this.userData.country.name) {
      const countryName = this.userData.country.name;
      const countryText = document.createElement("p");
      countryText.innerText = `${countryName}`;
      content.appendChild(countryText);
    }

    // Ajoutez un emplacement
    const location = document.createElement("h6");
    location.classList.add("description-title");
    location.innerText = "Emplacement";
    content.appendChild(location);

    if (this.userData.location && this.userData.location.name) {
      const locationName = this.userData.location.name;
      const locationText = document.createElement("p");
      locationText.innerText = `${locationName}`;
      content.appendChild(locationText);
    }

    card.appendChild(content);
    this.element = card;

    if (this.#isTouchDevice()) {
      this.#listenToTouchEvents();
    } else {
      this.#listenToMouseEvents();
    }

  };

  #listenToTouchEvents = () => {
    this.element.addEventListener("touchstart", (e) => {
      const touch = e.changedTouches[0];
      if (!touch) return;
      const { clientX, clientY } = touch;
      this.#startPoint = { x: clientX, y: clientY };
      document.addEventListener("touchmove", this.#handleTouchMove);
      this.element.style.transition = "transform 0s";
    });

    document.addEventListener("touchend", this.#handleTouchEnd);
    document.addEventListener("cancel", this.#handleTouchEnd);
  };

  #listenToMouseEvents = () => {
    this.element.addEventListener("mousedown", (e) => {
      const { clientX, clientY } = e;
      this.#startPoint = { x: clientX, y: clientY };
      document.addEventListener("mousemove", this.#handleMouseMove);
      this.element.style.transition = "transform 0s";
    });

    document.addEventListener("mouseup", this.#handleMoveUp);

    // prevent card from being dragged
    this.element.addEventListener("dragstart", (e) => {
      e.preventDefault();
    });
  };

  #handleMove = (x, y) => {
    this.#offsetX = x - this.#startPoint.x;
    this.#offsetY = y - this.#startPoint.y;
    const rotate = this.#offsetX * 0.1;
    this.element.style.transform = `translate(${this.#offsetX}px, ${this.#offsetY}px) rotate(${rotate}deg)`;
    // dismiss card
    if (Math.abs(this.#offsetX) > this.element.clientWidth * 0.7) {
      this.#dismiss(this.#offsetX > 0 ? 1 : -1);
    }
  };

  #handleMouseMove = (e) => {
    e.preventDefault();
    if (!this.#startPoint) return;
    const { clientX, clientY } = e;
    this.#handleMove(clientX, clientY);
  };

  #handleMoveUp = () => {
    this.#startPoint = null;
    document.removeEventListener("mousemove", this.#handleMouseMove);
    this.element.style.transform = "";
  };

  #handleTouchMove = (e) => {
    if (!this.#startPoint) return;
    const touch = e.changedTouches[0];
    if (!touch) return;
    const { clientX, clientY } = touch;
    this.#handleMove(clientX, clientY);
  };

  #handleTouchEnd = () => {
    this.#startPoint = null;
    document.removeEventListener("touchmove", this.#handleTouchMove);
    this.element.style.transform = "";
  };

  #dismiss = (direction) => {
    this.#startPoint = null;
    document.removeEventListener("mouseup", this.#handleMoveUp);
    document.removeEventListener("mousemove", this.#handleMouseMove);
    document.removeEventListener("touchend", this.#handleTouchEnd);
    document.removeEventListener("touchmove", this.#handleTouchMove);
    this.element.style.transition = "transform 1s";
    this.element.style.transform = `translate(${direction * window.innerWidth}px, ${this.#offsetY}px) rotate(${90 * direction}deg)`;
    this.element.classList.add("dismissing");
    
    setTimeout(() => {
      this.element.remove();
      this.onDismiss();

      if (direction > 0) {
        this.handleLike(this.userData.id);
        console.log('Action Like déclenchée depuis le swipe droit.');
      } else {
        this.handleDislike();
        console.log('Action Dislike déclenchée depuis le swipe gauche.');
      }
    }, 1000);
  };

  handleLike = async (profileId) => {
      try {
          // Obtenir le token d'authentification
          const auth_token = accountService.getToken();

          if (!auth_token) {
              console.error('Token non trouvé.');
              return;
          }

          // Obtenir l'ID du user connecté
          const userID = await accountService.getUserId();
          console.log('userID:', userID);
          console.log('profileId:', profileId);

          // Effectuer la requête POST avec les IDs en utilisant accountService
          const response = await accountService.swipeToLike(userID, profileId, true);

          console.log('Réponse de l\'API pour like:', response.data);
          console.log('Like enregistré avec succès pour le user avec ID:', profileId);
      } catch (error) {
          console.error('Erreur lors de l\'enregistrement du like:', error);
      }
  };

  handleDislike = async () => {
    try {
      const auth_token = accountService.getToken();

      if (!auth_token) {
        console.error('Token non trouvé.');
        return;
      }
      
      const response = await axios.post(`http://127.0.0.1:8000/api/swipes`, {
        headers: {
          'Authorization': `Bearer ${auth_token}`
        }
      });

      console.log('Réponse de l\'API pour dislike:', response.data);
      console.log('Dislike enregistré avec succès pour:', this.userData.full_name);
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du dislike:', error);
    }
  };

  }

  async function initializeCards() {
    const usersData = await fetchUserPhotos();
    usersData.forEach(userData => appendNewCard(userData));
  }

  // Fonction pour extraire l'ID de l'utilisateur à partir de l'URL de l'image
  function getUserIdFromUrl(photoUrl) {
    // Implémentez votre logique pour extraire l'ID de l'utilisateur
    // par exemple, en analysant l'URL de l'image
    // Retournez l'ID de l'utilisateur
  }

  async function appendNewCard(userData) {
  const card = new Card({
    userData,
    onDismiss: () => appendNewCard(userData),
    onLike: () => {
      like.style.animationPlayState = 'running';
      like.classList.toggle('trigger');
    },
    onDislike: () => {
      dislike.style.animationPlayState = 'running';
      dislike.classList.toggle('trigger');
    }
  });
  swiper.append(card.element);

  const cards = swiper.querySelectorAll('.card:not(.dismissing)');
  cards.forEach((card, index) => {
    card.style.setProperty('--i', index);
  });
}

initializeCards();

  const scriptESM = document.createElement('script');
  scriptESM.type = 'module';
  scriptESM.src = 'https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js';
  document.body.appendChild(scriptESM);

  const scriptNomodule = document.createElement('script');
  scriptNomodule.nomodule = true;
  scriptNomodule.src = 'https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js';
  document.body.appendChild(scriptNomodule);

});

</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.component-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: linear-gradient(0deg, rgb(255, 96, 54), rgb(253, 38, 122)) no-repeat; */

    background-image: url('@/assets/chat-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#container {
  width: 54%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  /* padding: 0 20px; */
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px 0px 10px 0px;
}

.swipe {
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    /* left: 50%; */
    /* right: 0; */
    /* transform: translateX(-50%); */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

#logo img{
    height: 40px;
  }

#logo {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  left: 10px;
}

#menu {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 20px;
}

#swiper {
  height: 70vh;
  aspect-ratio: 2 / 3;
  perspective: 1000px;
  perspective-origin: center 50%;
  transform-style: preserve-3d;
  position: relative;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  overflow: hidden;
  transform: translateZ(calc(-30px * var(--i))) translateY(calc(-20px * var(--i))) rotate(calc(-4deg * var(--i)));
  filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.5));
  cursor: pointer;
  user-select: none;
  transition: transform 0.5s;
  padding: 0px;
  background-color: #fff;
}

.card-content {
  flex: 1;
  overflow-y: auto;
  padding: 0px;
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.card-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Hide scrollbar for other browsers */
.card-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 0px;
}

.main-image {
  width: 100%;
  height: auto;
  /* border-radius: 10px; */
}

.additional-images {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.additional-images img {
  width: 48%;
  height: auto;
  border-radius: 10px;
}

.card p {
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
  color: #333;
}

#dislike {
  font-size: 16vh;
  border-radius: 50%;
  padding: 20px;
  position: relative;
  right: -50px;
  z-index: 1;
  animation-name: animation1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-play-state: paused;
}

#like {
  font-size: 16vh;
  border-radius: 50%;
  padding: 20px;
  position: relative;
  left: -50px;
  z-index: 1;
  animation-name: animation1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-play-state: paused;
}

#like.trigger,
#dislike.trigger {
  animation-name: animation2;
}

#like {
  color: red;
  background-color: rgba(0, 0, 0, 0.5);
}

#dislike {
  color: #ccc;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes animation1 {
  0%,
  100% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}

@keyframes animation2 {
  0%,
  100% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }
}

#bottom-menu {
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  /* left: 50%;
  transform: translateX(-50%); */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}


#bottom-menu .menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

#bottom-menu .menu-item ion-icon {
  font-size: 24px;
}

.trigger {
  opacity: 0.5;
}

.user-info {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 1.1em;
}

.description-title {
  text-align: left;
  font-size: 1em;
  /* margin-top: 10px; */
  padding: 10px 0px 0px 5px;
}

.description-about {
  font-size: 1em;
  padding: 0px 0px 0px 10px;
}

@keyframes trigger {
  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    z-index: 0;
  }
}

@media (max-width: 767px) {
  #container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0;
    padding: 65px 0px 10px 0px;
  }

  .swipe {
    width: 100%;
    height: auto;
  }

  #logo img {
    height: 30px;
  }

  #logo {
    font-size: 16px;
  }

  #menu {
    font-size: 16px;
  }

  
  .card {
    border-radius: 10px;
  }

  .additional-images {
    flex-direction: column;
  }

  .additional-images img {
    width: 100%;
    margin-bottom: 10px;
  }

  .card p {
    font-size: 14px;
  }

#dislike,
#like {
  font-size: 14vh;
  padding: 10px;
  /* position: absolute; */
  top: 50%; /* Centrer verticalement */
  transform: translateY(-50%); /* Ajuster le centrage vertical */
}

#dislike {
  left: 0px; /* Placer le bouton dislike à gauche */
  color: #ccc;
  background-color: rgba(0, 0, 0, 0.5);
}

#like {
  right: -10px; /* Placer le bouton like à droite */
  color: red;
  background-color: rgba(0, 0, 0, 0.5);
}


.user-info {
  font-size: 0.9em;
}

.description-title,
.description-about {
  font-size: 0.9em;
}
}

@media (max-width: 767px) {
  .swipe,
  #dislike,
  #like,
  #bottom-menu {
    display: none;
  }
}

@media (max-width: 767px) {
  .card {
    position: relative; /* Assure que chaque carte a son propre contexte de positionnement */
    z-index: 1; /* Met la carte active au-dessus */
  }
  .card-content {
    overflow-y: auto; /* Permet le scroll vertical */
    height: 100%; /* S'assure que le contenu prend toute la hauteur de la carte */
  }
}



</style>
