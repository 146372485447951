<template>
    <NotifBar/>
    <router-view />
</template>

<script>
import NotifBar from '@/components/notification/NotificationComponent.vue'
export default {
    name: 'App',
    components: {
        NotifBar
    }
}
</script>

<style>
#app {
font-family: "Avenir", Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
</style>