<template>
  <div class="component-container">
    <div class="container">
      <div class="proximity-view">
        <p class="text-dark">À proximité</p>
        <div class="profile-grid">
          <div class="profile-card" v-for="profile in profiles" :key="profile.user.id">
            <img :src="getImageUrl(profile.images[0].file)" class="profile-image" />
            <div class="profile-info">
              <p class="profile-name">{{ profile.user.full_name }}, {{ calculateAge(profile.user.born_on) }}</p>
              <!-- Utiliser flexbox pour aligner les informations en bas -->
              <div class="profile-bottom-info">
                <p class="profile-distance">{{ profile.distance.toFixed(2) }} km</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { accountService } from '@/_services'

const baseURL = process.env.VUE_APP_API_BASE_URL.replace('/api', '');

// Référence pour stocker les profils
const profiles = ref([])

// Fonction pour construire l'URL de l'image
const getImageUrl = (filePath) => {
  if (!filePath) return '';
  return `${baseURL}${filePath}`;
}

// Fonction pour récupérer les profils
const fetchProfiles = async () => {
  try {
    const auth_token = accountService.getToken()
    if (!auth_token) {
      console.error('Token non trouvé.')
      return
    }

    const response = await accountService.getDates()
    profiles.value = response.data.items

  } catch (error) {
    console.error('Erreur lors de la récupération des profils:', error)
  }
}

// Méthode pour calculer l'âge
const calculateAge = (born_on) => {
  const birthDate = new Date(born_on)
  const today = new Date()
  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDifference = today.getMonth() - birthDate.getMonth()
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

// Chargement initial des profils au montage du composant
onMounted(() => {
  fetchProfiles()
})
</script>

<style scoped>
/* Réinitialisation des marges et paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container principal */
.component-container {
  display: flex;
  flex-direction: column; /* Disposition verticale pour les petits écrans */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('@/assets/chat-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Container pour la vue de proximité */
.container {
  width: 54%; /* Largeur fixe pour les grands écrans */
  height: 90vh;
  box-shadow: 0 30px 70px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

/* Vue de proximité */
.proximity-view {
  padding: 50px 30px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: center; /* Centre le texte */
}

/* Masquer la scrollbar pour les navigateurs basés sur WebKit */
.proximity-view::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Masquer la scrollbar pour les autres navigateurs */
.proximity-view {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Grille de profils */
.profile-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Trois colonnes */
  row-gap: 30px; /* Espace entre les lignes */
  column-gap: 30px; /* Espace entre les colonnes */
  justify-content: center; /* Centre les éléments du grid */
}

/* Carte de profil */
.profile-card {
  border: 1px solid hsl(0, 1%, 50%);
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0px;
  width: 200px;
  height: 250px;
  cursor: pointer;
  padding-bottom: 10px;
}

/* Image de profil */
.profile-image {
  width: 100%; /* Adapter la largeur de l'image */
  height: 220px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

/* Informations de profil */
.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}

/* Informations en bas de la carte de profil */
.profile-bottom-info {
  margin-top: auto; /* Pousse le contenu précédent vers le haut */
}

/* Nom et distance du profil */
.profile-name {
  font-weight: bold;
  word-wrap: break-word;
  margin: 0;
  padding: 2px 0;
}

.profile-distance {
  margin: 0;
  padding: 2px 0;
}

/* Texte sombre */
.text-dark {
  font-size: 1.5em; /* Ajuster la taille du texte si nécessaire */
  margin-bottom: 20px;
}

/* Media Query pour les petits écrans */
@media (max-width: 767px) {
  .container {
    width: 100%; /* Prendre toute la largeur de l'écran sur mobile */
    left: 0;
    transform: none; /* Supprimer la transformation pour les petits écrans */
  }

  .profile-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adapter les colonnes à la largeur de l'écran */
    row-gap: 15px; /* Réduire l'espace entre les lignes */
    column-gap: 15px; /* Réduire l'espace entre les colonnes */
  }

  .profile-card {
    width: 100%; /* Prendre toute la largeur de la carte de profil */
    height: auto; /* Adapter la hauteur pour le contenu */
  }

  .profile-image {
    height: 150px; /* Ajuster la hauteur de l'image pour les petits écrans */
  }
}

</style>

