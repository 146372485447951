<template>
    <!-- ================> Story section start here <================== -->
	<!-- <div class="story bg_img padding-top padding-bottom" style="background-image: url(@/assets/images/bg-img/02.jpg);"> -->
	<div class="story padding-top padding-bottom">
		<img src="@/assets/images/bg-img/02.jpg" class="bg_img" alt="Background Image">
		<div class="container">
			<div class="section__header style-2 text-center wow fadeInUp" data-wow-duration="1.5s">
				<h2>Hodalo Histoires de nos amants</h2>
				<p>Écoutez et apprenez des membres de notre communauté et découvrez des trucs et astuces pour rencontrer votre amour. Rejoignez-nous et faites partie d'une plus grande famille.</p>
			</div>
			<div class="section__wrapper">
				<div class="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
					<div class="col wow fadeInUp" data-wow-duration="1.5s">
						<div class="story__item">
							<div class="story__inner">
								<div class="story__thumb">
									<a href="blog-single.html"><img src="../../assets/images/story/01.jpg" alt="dating thumb"></a>
									<span class="member__activity member__activity--ofline">Divertissement</span>
								</div>
								<div class="story__content">
									<a href="blog-single.html"><h4>Des lieux de rêve et des lieux à visiter en 2024</h4></a>
									<div class="story__content--author">
										<div class="story__content--thumb">
											<img src="../../assets/images/story/author/01.jpg" alt="dating thumb">
										</div>
										<div class="story__content--content">
											<h6>Hester Reeves</h6>
											<p>16 avril 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col wow fadeInUp" data-wow-duration="1.6s">
						<div class="story__item">
							<div class="story__inner">
								<div class="story__thumb">
									<a href="blog-single.html"><img src="../../assets/images/story/02.jpg" alt="dating thumb"></a>
									<span class="member__activity member__activity--ofline">Histoires d'amour</span>
								</div>
								<div class="story__content">
									<a href="blog-single.html"><h4>Réalisez vos rêves et monétisez rapidement</h4></a>
									<div class="story__content--author">
										<div class="story__content--thumb">
											<img src="../../assets/images/story/author/02.jpg" alt="dating thumb">
										</div>
										<div class="story__content--content">
											<h6>Arika Q Smith</h6>
											<p>14 mars 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col wow fadeInUp" data-wow-duration="1.7s">
						<div class="story__item">
							<div class="story__inner">
								<div class="story__thumb">
									<a href="blog-single.html"><img src="../../assets/images/story/03.jpg" alt="dating thumb"></a>
									<span class="member__activity member__activity--ofline">Attirance</span>
								</div>
								<div class="story__content">
									<a href="blog-single.html"><h4>L'amour ne regarde pas avec les yeux, mais avec l'esprit.</h4></a>
									<div class="story__content--author">
										<div class="story__content--thumb">
											<img src="../../assets/images/story/author/03.jpg" alt="dating thumb">
										</div>
										<div class="story__content--content">
											<h6>William Show</h6>
											<p>18 juin 2024</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <!-- ================> Story section end here <================== -->
</template>

<style>
	.story {
    	position: relative; /* Assurez-vous que le conteneur a une position relative */
	}

	.story .bg_img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1; /* Positionner l'image en arrière-plan */
		object-fit: cover; /* Assurez-vous que l'image couvre tout le conteneur */
		opacity: 0.5; /* Réglez l'opacité de l'image au besoin */
	}
</style>