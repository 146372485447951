<template>
    <div v-if="show" class="modal-overlay">
        <div class="modal-container">
            <div class="modal-header">
                <h5>Mettre à jour la motivation</h5>
                <button @click="closeModal">X</button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="updateProfile">
                    <div class="mb-3">
                        <label for="profile" class="form-label">Motivation</label>
                        <select v-model="form.profile" class="form-control" required id="profile">
                            <option value="" disabled>--Sélectionner--</option>
                            <option value="DISCUSS">Discution</option>
                            <option value="MEET">Rencontre</option>
                            <option value="SERIOUS_STORY">Histoire sérieuse</option>
                        </select>
                    </div>
                    <button type="submit" class="btn btn-primary">Mettre à jour</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import swal from 'sweetalert';
import { accountService } from '@/_services';

const props = defineProps({
    show: Boolean
});

const emit = defineEmits(['close']);

const form = ref({
    profile: '',
});

const store = useStore();

const loadUserData = () => {
    const user = store.getters.getAppUser;
    form.value.profile = user.profile || '';
};

const updateProfile = async () => {
    try {
        const response = await accountService.updateUser(form.value);
        if (response.data.user) {
            store.commit('setAppUser', { ...form.value });
            await fetchUser();
            emit('close');
            swal({
                title: 'Succès',
                text: 'Motivation mis à jour',
                icon: 'success'
            });
        }
    } catch (error) {
        console.error('Erreur lors de la mise à jour de la Motivation:', error);
        swal({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la mise à jour du Motivation.',
            icon: 'error'
        });
    }
};

const fetchUser = async () => {
    try {
        const response = await accountService.getUserProfile();
        if (response.data.user) {
            store.commit('setAppUser', response.data.user);
        }
    } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
    }
};

const closeModal = () => {
    emit('close');
};

watch(props.show, (newValue) => {
    if (newValue) {
        loadUserData();
    }
});
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-width: 100%;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.modal-body {
    max-height: 70vh;
    overflow-y: auto;
}
</style>
