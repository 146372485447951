<script setup>
import { accountService } from '@/_services';
import { reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { db } from '@/_services/firebase.js';
import { doc, setDoc } from 'firebase/firestore';

const userData = reactive({
    id: '', // ID généré pour l'utilisateur
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    first_name: '',
    last_name: '',
    born_on: '',
    gender: '',
    gender_preference: ''
});

const router = useRouter();
const passwordError = ref(false);

const password_regex = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&? "]).*$/;

const showPassword = ref(false);
const showPasswordPC = ref(false);

const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};

const togglePasswordVisibilityPC = () => {
    showPasswordPC.value = !showPasswordPC.value;
};

async function register() {
    if (!checkPassword()) {
        passwordError.value = true;
        return;
    }

    const formData = {
        username: userData.username,
        email: userData.email,
        password: userData.password,
        password_confirmation: userData.password_confirmation,
        first_name: userData.first_name,
        last_name: userData.last_name,
        born_on: userData.born_on,
        gender: userData.gender,
        gender_preference: userData.gender_preference
    };

    console.log('FormData:', formData);

    try {
        const response = await accountService.register(formData);
        const userId = response.data.user.id;

        if (userId) {
            // Utilisez l'ID utilisateur renvoyé pour enregistrer l'utilisateur dans Firebase
            await saveUserToFirestore(userId);
            router.push({ name: 'login' });
            swal({
                title: 'Succès',
                text: 'Utilisateur enrégistré',
                icon: 'success'
            });
        } else {
            console.error('ID utilisateur manquant dans la réponse');
            swal({
                title: 'Erreur',
                text: 'ID utilisateur manquant dans la réponse',
                icon: 'error'
            });
        }
    } catch (err) {
        console.error('Erreur lors de l\'inscription:', err);
        swal({
            title: 'Erreur',
            text: 'Une erreur s\'est produite lors de l\'inscription',
            icon: 'error'
        });
    }
}

async function saveUserToFirestore(userId) {
    try {
        await setDoc(doc(db, 'users', userId), {
            username: userData.username,
            email: userData.email,
            first_name: userData.first_name,
            last_name: userData.last_name,
            born_on: userData.born_on,
            gender: userData.gender,
            gender_preference: userData.gender_preference
        });
        console.log('User saved to Firestore');
    } catch (error) {
        console.error('Error saving user to Firestore:', error);
    }
}


function checkPassword() {
    if (!password_regex.test(userData.password)) {
        console.log("Le mot de passe doit contenir au moins 8 caractères, dont au moins une majuscule, une minuscule, un caractère spécial (@$!%*#?&) et au moins huit digits.");
        return false;
    }
    return true;
}

const defaultDateFormat = 'YYYY-MM-DD';

const formattedDate = ref(defaultDateFormat);

const updateDate = (event) => {
    userData.born_on = event.target.value;
};

watch(() => userData.born_on, (newValue) => {
    if (newValue) {
        formattedDate.value = newValue;
    } else {
        formattedDate.value = defaultDateFormat;
    }
});

const genderOptions = [
    { value: 'MALE', label: 'Homme' },
    { value: 'FEMALE', label: 'Femme' },
    { value: 'OTHER', label: 'Autres' },
    { value: 'All', label: 'Tout' }
];
</script>

<template>
    <section class="log-reg">
        <div class="top-menu-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-7">
                        <div class="logo">
                            <router-link :to="{name:'home'}" class="backto-home"><img src="../../assets/images/logo/logo.png" alt="logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-5">
                        <router-link :to="{name:'home'}" class="backto-home"><i class="fas fa-chevron-left"></i> Accueil</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="image">
                </div>
                <div class="col-lg-7">
                    <div class="log-reg-inner pt-0">
                        <div class="section-header">
                            <h2 class="title">Bienvenue sur Hodalo </h2>
                            <p>Créons votre profil ! Remplissez simplement les champs ci-dessous pour rejoindre notre belle communauté. </p>
                        </div>
                        <div class="main-content">
                            <form @submit.prevent="register">
                                <h4 class="content-title">Détails du compte</h4>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label>Nom d'utilisateur*</label>
                                        <input v-model="userData.username" type="text" id="username" class="my-form-control" required="" placeholder="Nom d'utilisateur">
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label>Email</label>
                                        <input v-model="userData.email" type="email" id="email" class="my-form-control" placeholder="Email">
                                    </div>
                                </div>  
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label >Mot de passe*</label>
                                        <input v-model="userData.password" v-bind:type="showPassword ? 'text' : 'password'" id="password" class="my-form-control" required="" placeholder="Mot de passe">
                                        <span class="toggle-password" @click="togglePasswordVisibility">
                                            <i class="fas" v-bind:class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                                        </span>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label >Confirmer mot de passe*</label>
                                        <input v-model="userData.password_confirmation" v-bind:type="showPasswordPC ? 'text' : 'password'" id="password_confirmation" class="my-form-control" required="" placeholder="Confirmer mot de passe">
                                        <span class="toggle-password" @click="togglePasswordVisibilityPC">
                                            <i class="fas" v-bind:class="{ 'fa-eye': showPasswordPC, 'fa-eye-slash': !showPasswordPC }"></i>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <small v-if="passwordError" class="fs-6 text-danger">Le mot de passe doit contenir au moins 8 caractères, dont au moins une majuscule, une minuscule, un caractère spécial (@$!%*#?&) et au moins huit digits.</small>
                                </div>
                                <h4 class="content-title mt-5">Détails du profil</h4>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label>Prénom</label>
                                        <input v-model="userData.first_name" type="text" id="first_name" class="my-form-control" placeholder="Prénom">
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label>Nom</label>
                                        <input v-model="userData.last_name" type="text" id="last_name" class="my-form-control" placeholder="Nom">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-lg-6">
                                        <label>Date de naissance</label>
                                        <input v-model="formattedDate" type="date" id="born_on" class="my-form-control" @input="updateDate">
                                    </div>

                                    <div class="form-group col-lg-6">
                                        <label>Je suis un(e)</label>
                                            <div class="banner__inputlist">
                                            <select v-model="userData.gender">
                                                <option value="" disabled>-- Sélectionner --</option>
                                                <option value="MALE">Homme</option>
                                                <option value="FEMALE">Femme</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-6">
                                        <label>A la recherche de</label>
                                        <div class="banner__inputlist">
                                            <select v-model="userData.gender_preference">
                                                <option value="" disabled>-- Sélectionner --</option>
                                                <option value="MALE">Homme</option>
                                                <option value="FEMALE">Femme</option>
                                                <option value="All">Homme / Femme</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <!-- <div class="form-group">
                                    <label>Je suis un</label>
                                    <div class="banner__inputlist">
                                        <div class="s-input me-3">
                                            <input v-model="userData.gender" type="radio" id="gender" name="gender"><label for="male">Man</label>
                                        </div>
                                        <div class="s-input">
                                            <input v-model="userData.gender"  type="radio" id="gender" name="gender"><label for="female">Woman</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>A la recherche de</label>
                                    <div class="banner__inputlist">
                                        <div class="s-input me-3">
                                            <input v-model="userData.gender_preference" type="radio" name="gender_preference" id="gender_preference"><label for="male">Man</label>
                                        </div>
                                        <div class="s-input">
                                            <input v-model="userData.gender_preference" type="radio" name="gender_preference" id="gender_preference"><label for="female">Woman</label>
                                        </div>
                                    </div>
                                </div>-->
                                <!-- <div class="form-group">
                                    <label>Pays</label>
                                    <input v-model="userData.country" type="text" id="country" class="my-form-control" placeholder="Enter Your Country">
                                </div> -->

                                <button type="submit" class="default-btn reverse" data-toggle="modal" data-target="#email-confirm"><span>Créez votre profile</span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
    .form-group {
    position: relative;
    }

    .toggle-password {
        position: absolute;
        right: 0;
        top: 71%;
        transform: translateY(-50%);
    }

    .fas {
        padding-right: 10px;
    }

    .my-form-control {
        padding-right: 30px; /* Ajouter un espace à droite pour le bouton de l'oeil */
    }
</style>
