<template>
    <HeaderSection/>
    <router-view />
    <FooterSection/>
</template>

<script>
    import HeaderSection from '@/components/public/HeaderSection.vue'
    import FooterSection from '@/components/public/FooterSection.vue'
    export default {
        name: 'PublicLayout',
        components: {
            HeaderSection,
            FooterSection
        }
    }
</script>

<style>

</style>