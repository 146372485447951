<template>
  <div id="bottom-menu">
    <router-link :to="{ name: 'swipe' }" class="menu-item">
      <ion-icon name="swap-horizontal-outline"></ion-icon>
      <span>Rencontres</span>
    </router-link>
    <router-link :to="{ name: 'proximity' }" class="menu-item">
      <ion-icon name="locate-outline"></ion-icon>
      <span>Proximité</span>
    </router-link>
    <router-link :to="{ name: 'likes' }" class="menu-item">
      <ion-icon name="heart-outline"></ion-icon>
      <span>Likes</span>
    </router-link>
    <router-link :to="{ name: 'vip' }" class="menu-item">
      <ion-icon name="star-outline"></ion-icon>
      <span>VIP</span>
    </router-link>
    <router-link :to="{ name: 'chat' }" class="menu-item">
      <ion-icon name="chatbubble-outline"></ion-icon>
      <span>Chat</span>
    </router-link>
    <router-link :to="{ name: 'dashboard' }" class="menu-item">
      <ion-icon name="person-outline"></ion-icon>
      <span>Profil</span>
    </router-link>
  </div>
</template>

<style scoped>
#bottom-menu {
  width: 54%;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

/* Styles pour les écrans de smartphones */
@media (max-width: 767px) {
  #bottom-menu {
    width: 100%;
    left: 0;
    transform: none;
  }

  .menu-item {
    font-size: 20px; /* Ajustez la taille des icônes si nécessaire */
    padding: 0 10px;
  }

  .menu-item span {
    display: none; /* Masquer le texte sur les smartphones */
  }
}
</style>
