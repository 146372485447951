<template>
    <div id="body">
        <Header />
        <router-view />
        <Menu />
    </div>
</template>

<script>
    import Header from '@/components/dating/Header.vue'
    import Menu from '@/components/dating/Menu.vue'
    export default {
        name: 'DatingLayout',
        components: {
            Header,
            Menu
        }
    }
</script>

<style scoped>
    .component-container {
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: 300%; */
        height: 100vh;
        /* background: linear-gradient(0deg, rgb(255, 96, 54), rgb(253, 38, 122)) no-repeat; */
    }
</style>